import { IconArrowsDiagonal } from '@tabler/icons-react';
import first from 'lodash/first';
import get from 'lodash/get';
import { useEffect, useRef, useState } from 'react';
import { OPERATOR_VIEW_AS_FULL_TEXT_ID } from '@/main/components/workflows/apps/util';
import { useGetWorkflowDAGQuery } from '@/main/queries/workflows/dag';
import {
  Box,
  Horizontal,
  Modal,
  Overlay,
  Text,
  UnstyledButton,
  Vertical,
  useDisclosure,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { RowPreview } from './RowPreview';

interface LongTextBlockProps {
  data: any;
  schema: {
    name: string;
    type: string;
    placeholder?: boolean;
  }[];
  isRunsPreview?: boolean;
  placeholderText?: string;
  workflowId?: string;
  workflowRunId?: string;
  copyCompassColumn?: string;
}

export const LongTextBlock = ({
  data,
  schema,
  isRunsPreview,
  placeholderText,
  workflowId,
  workflowRunId,
  copyCompassColumn,
}: LongTextBlockProps) => {
  const theme = useMarkovTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 236);
    }
  }, [data]);

  return (
    <Box
      p="lg"
      bg="gray.0"
      mah={296}
      sx={theme => ({
        borderRadius: theme.radius.sm,
        border: `1px solid ${theme.colors.gray[2]}`,
        overflow: 'hidden',
      })}
    >
      <Vertical spacing="sm">
        <Box ref={contentRef} mah={236} pos="relative" sx={{ overflow: 'hidden' }}>
          <RowPreview
            data={data}
            schema={schema}
            isRunsPreview={isRunsPreview}
            workflowId={workflowId}
            workflowRunId={workflowRunId}
            copyCompassColumn={copyCompassColumn}
          />
          {isOverflowing && (
            <Overlay gradient="linear-gradient(180deg, rgba(248, 249, 250, 0.00) 0%, rgba(248, 249, 250, 0.00) 60%, rgba(248, 249, 250, 1.00) 100%)" />
          )}
        </Box>
        {isOverflowing && (
          <Horizontal position="right">
            <UnstyledButton onClick={open}>
              <Horizontal noWrap spacing="sm">
                <IconArrowsDiagonal size={16} color={theme.colors.gray[6]} />
                <Text variant="subTitle05" color="gray.6">
                  Open Full Output
                </Text>
              </Horizontal>
            </UnstyledButton>
          </Horizontal>
        )}
      </Vertical>

      <Modal
        opened={opened}
        size="1480px"
        onClose={close}
        title={
          <Text variant="subTitle01" color="gray.8">
            Full preview
          </Text>
        }
      >
        <RowPreview
          data={data}
          schema={schema}
          isRunsPreview={isRunsPreview}
          placeholderText={placeholderText}
          workflowId={workflowId}
          workflowRunId={workflowRunId}
          copyCompassColumn={copyCompassColumn}
        />
      </Modal>
    </Box>
  );
};

interface LongTextPreviewProps {
  rowData: object[];
  schema: {
    name: string;
    type: string;
  }[];
  isRunsPreview?: boolean;
  placeholderText?: string;
  workflowId?: string;
  workflowRunId?: string;
}

export const LongTextPreview = ({
  rowData,
  schema,
  isRunsPreview,
  placeholderText,
  workflowId,
  workflowRunId,
}: LongTextPreviewProps) => {
  const { data: dag } = useGetWorkflowDAGQuery(workflowId ?? '');
  const fullTextNodeConfig = dag?.nodes.find(node => node.data.id === OPERATOR_VIEW_AS_FULL_TEXT_ID)
    ?.data.configuration;
  const copyCompassColumn = get(fullTextNodeConfig, 'column', '');

  if (rowData.length === 1) {
    return (
      <Box
        p="lg"
        bg="gray.0"
        sx={theme => ({
          borderRadius: theme.radius.sm,
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <RowPreview
          data={first(rowData)}
          schema={schema}
          isRunsPreview={isRunsPreview}
          placeholderText={placeholderText}
          workflowId={workflowId}
          workflowRunId={workflowRunId}
          copyCompassColumn={copyCompassColumn}
        />
      </Box>
    );
  }

  return (
    <Vertical>
      {rowData.map((datum, idx) => (
        <LongTextBlock
          key={idx}
          data={datum}
          schema={schema}
          isRunsPreview={isRunsPreview}
          workflowId={workflowId}
          workflowRunId={workflowRunId}
          copyCompassColumn={copyCompassColumn}
        />
      ))}
    </Vertical>
  );
};
