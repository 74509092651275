import { useSSEQuery } from './sse-query';

/**
 * Custom hook that provides a function to suggest a document title based on content.
 */
export function useTitleSuggester() {
  const { mutate } = useSSEQuery();

  /**
   * Suggests a title for the provided content.
   *
   * @param {string} content - The content from which to generate a title.
   * @returns {Promise<string>} A promise that resolves with the suggested title.
   */
  const suggestTitle = async (content: string): Promise<string> =>
    new Promise((resolve, reject) => {
      const PROMPT = `Generate a creative and concise document name for the following content. 
        Return only the name with STRICTLY less than 50 characters in a single line without any commentary or extra information:\n\n`;
      const query = `${PROMPT}${content}`;

      mutate(query, {
        onComplete: (data: string) => {
          if (typeof data === 'string') {
            // Replace all double quotes(") and trim spaces in the response.
            resolve(data.replaceAll('"', '').trim());
          } else {
            reject(new Error('No title was generated.'));
          }
        },
        onError: (error: unknown) => {
          reject(error);
        },
      });
    });

  return { suggestTitle };
}
