import {
  ControlProps,
  RankedTester,
  and,
  hasType,
  optionIs,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import {
  FileWithPath,
  Text,
  TextArea,
  Vertical,
  notifications,
  useInputState,
} from '@/shared/design-system/v2';
import { MEMORY_UNIT_LIMITS } from '@/shared/lib/resource-usage';
import { FileInputSelector } from '../../../uploader/file-uploader/FileInputSelector';

const TextAndFileUploadControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  config,
  errors,
}: ControlProps) => {
  const [val, setVal] = useInputState(data);
  const [inputFile, setInputFile] = useState<FileWithPath>();

  const allowedFileSize = 128;

  const minRows = uischema.options?.minRows ?? 3;
  const maxRows = uischema.options?.maxRows ?? 9;

  if (!visible) {
    return null;
  }

  const onChange = (val: string) => {
    setVal(val);
    handleChange(path, val);
  };

  const readFileContent = (file: File) => {
    const reader = new FileReader();

    reader.onload = event => {
      if (event.target?.result) {
        const content = event.target.result as string;
        const formattedContent = content.split('\n').join('\n');
        setVal(formattedContent);
      }
    };

    reader.readAsText(file);
  };

  const handleDropFile = (file: FileWithPath) => {
    const fileSize = Number(((file.size ?? allowedFileSize) / MEMORY_UNIT_LIMITS.KB).toFixed(4));

    if (fileSize > allowedFileSize) {
      notifications.error(`The file size exceeds ${allowedFileSize}MB.`);
      return;
    }

    setInputFile(file);
    readFileContent(file);
  };

  const handleFileDelete = () => {
    setInputFile(undefined);
    onChange('');
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <Vertical>
      <TextArea
        autosize
        ariaLabel={label ?? ' '}
        value={val}
        onChange={onChange}
        label={
          label.length > 0 ? (
            <Text span variant="subTitle02">
              {label}
            </Text>
          ) : null
        }
        description={
          <Text span variant="small02" color="gray.7" pb="sm">
            {description}
          </Text>
        }
        minRows={minRows}
        maxRows={maxRows}
        required={required}
        placeholder={uischema.options?.placeholder}
        disabled={!isViewOnlyForm && !enabled}
        readOnly={isViewOnlyForm}
        error={config.isFormDirty ? errors : undefined}
      />
      {!inputFile && isEmpty(val) && (
        <Vertical>
          <Text variant="subTitle04" color="gray.7">
            Or upload a txt file
          </Text>
          <FileInputSelector
            file={inputFile}
            supportedFilesTypes={['txt']}
            onFileSelect={handleDropFile}
            onFileDelete={handleFileDelete}
          />
        </Vertical>
      )}
    </Vertical>
  );
};

export const textAndFileUploadControlTester: RankedTester = rankWith(
  3,
  and(
    uiTypeIs('Control'),
    optionIs('multipleLines', true),
    optionIs('fileUpload', true),
    schemaMatches(schema => hasType(schema, 'string')),
  ),
);

export const TextAndFileUploadControl = withJsonFormsControlProps(
  TextAndFileUploadControlComponent,
);
