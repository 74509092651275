declare global {
  interface Window {
    heap: any;
  }
}

export const identifyHeapAnalyticsUser = (userId: string, name: string, email: string) => {
  if (window.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties({ email, name });
  }
};
