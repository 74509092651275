import { useElementSize } from '@mantine/hooks';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import { ChartType, DataSourceConversationModel, WidgetType } from '@/main/generated/api';
import { Box, Container, Flex, ScrollArea, Vertical } from '@/shared/design-system/v2';
import { ChatResourceTypes } from '../../../../../queries/chat-with-data/chat';
import { ThreadProps } from '../../menu/panels/threads-panel/ThreadsItem';
import { ConversationViewContainer } from './ConversationView.container';

const CHAT_INTERFACE_WIDTH_BREAKPOINT = 800;

interface ConversationViewWrapperProps {
  resourceId: string;
  resourceType: ChatResourceTypes;
  handleSqlExecution?: (query: string) => void;
  onAddVizToDashboard?: (
    answer: DataSourceConversationModel,
    vizType?: WidgetType,
    chartType?: ChartType,
  ) => void;
  activeThread?: ThreadProps;
  titleText?: string;
  subHeadingText?: string;
  descriptionText?: string;
  emptyChatContentInnerContent?: ReactNode;
  loadingVizInsert?: boolean;
  onAddToWidget?: (val: string) => void;
}

export const ConversationViewWrapper = ({
  resourceId,
  resourceType,
  activeThread,
  handleSqlExecution,
  onAddVizToDashboard,
  children,
  titleText,
  subHeadingText,
  descriptionText,
  emptyChatContentInnerContent,
  loadingVizInsert,
  onAddToWidget,
}: PropsWithChildren<ConversationViewWrapperProps>) => {
  const { ref: chatDetailsRef, width: chatDetailsWidth } = useElementSize();

  // |-----------------------------------|   -   |-------------------------|
  // | |--------| |----------------------|   |   | |--------| |------------|
  // | |  Menu  | |     |----------|     |   |   | |  Menu  | ||----------||
  // | |  Panel | |     | Convers- |     |   |   | |  Panel | || Convers- ||
  // | |        | |     |  ations  |     |   |   | |        | ||  ations  ||
  // | |        | |     |----------|     |   |   | |        | ||----------||
  // | |--------| |----------------------|   |   | |--------| |------------|
  // |-----------------------------------|   -   |-------------------------|
  // The box outside of `Conversations` is `Chat Interface`.
  //
  // The idea is that Conversations View in the chat interface should have some
  // padding if the overall chat interface's width is large and if that width is
  // small then Conversations View should occupy the whole width.
  //
  // Following logic handles that case. Our breakpoint for large Chat interface
  // is `CHAT_INTERFACE_WIDTH_BREAKPOINT` in this case. If width is smaller than
  // that then that the whole width (90% is used for padding purposes otherwise
  // whole layout will overflow the screen) and if width is larger then Conversations
  // View should occupy 80% width of the chat interface not exceeding 1000px.
  const maw =
    chatDetailsWidth <= CHAT_INTERFACE_WIDTH_BREAKPOINT
      ? chatDetailsWidth * 0.9
      : Math.min(chatDetailsWidth * 0.8, 1000);

  const viewport = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    viewport.current?.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Vertical
      ref={chatDetailsRef}
      w="auto"
      h="100%"
      p="lg"
      bg="white.0"
      justify="space-between"
      sx={{ flex: 1 }}
    >
      <ScrollArea h="100%" pr="lg" viewportRef={viewport} offsetScrollbars>
        <Container h="100%" maw={maw} px={0}>
          <Flex rowGap={24} sx={{ flexDirection: 'column-reverse' }}>
            <ConversationViewContainer
              resourceId={resourceId}
              resourceType={resourceType}
              scrollToBottom={scrollToBottom}
              activeThread={activeThread}
              handleSqlExecution={handleSqlExecution}
              onAddVizToDashboard={onAddVizToDashboard}
              titleText={titleText}
              subHeadingText={subHeadingText}
              descriptionText={descriptionText}
              emptyChatContentInnerContent={emptyChatContentInnerContent}
              loadingVizInsert={loadingVizInsert}
              onAddToWidget={onAddToWidget}
            />
          </Flex>
        </Container>
      </ScrollArea>
      <Box mx="auto" w="100%" maw={maw} mb="sm">
        {children}
      </Box>
    </Vertical>
  );
};
