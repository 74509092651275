import { createQueryParams } from '@/shared/lib/router';

export enum FeatureFamily {
  GLOBAL_HOME = 'GLOBAL_HOME',
  DATA_STUDIO = 'DATA_STUDIO',
  MODEL_STUDIO = 'MODEL_STUDIO',
  APP_STORE = 'APP_STORE',
  WORKFLOWS = 'WORKFLOWS',
  SNIPPETS = 'SNIPPETS',
  APP_BUILDER = 'APP_BUILDER',
  SETTINGS = 'SETTINGS',
  DATA_ANALYTICS = 'DATA_ANALYTICS',
  DATA_SOURCES = 'DATA_SOURCES',
  NON_ENTERPRISE = 'NON_ENTERPRISE',
  INTEGRATIONS = 'INTEGRATIONS',
}

export enum AppRoutes {
  BASE_ROUTE = 'BASE_ROUTE',
  SIGNUP = 'SIGNUP',
  LOGOUT = 'LOGOUT',
  INVITE = 'INVITE',
  ONBOARDING = 'ONBOARDING',
  GLOBAL_HOME = 'GLOBAL_HOME',
  DATA_STUDIO_HOME = 'DATA_STUDIO_HOME',
  MODEL_STUDIO_HOME = 'MODEL_STUDIO_HOME',
  PROJECTS = 'PROJECTS',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  EVALUATIONS = 'EVALUATIONS',
  EVALUATION_DETAILS = 'EVALUATION_DETAILS',
  RECORDINGS = 'RECORDINGS',
  DELETED_RECORDINGS = 'DELETED_RECORDINGS',
  RECORDING_DETAIL = 'RECORDING_DETAIL',
  COMPARE_RECORDINGS_MULTIMODEL = 'COMPARE_RECORDINGS_MULTIMODEL',
  COMPARE_RECORDINGS_MULTIDATASET = 'COMPARE_RECORDINGS_MULTIDATASET',
  COMPARE_EVALUATIONS_MULTIMODEL = 'COMPARE_EVALUATIONS_MULTIMODEL',
  EXPERIMENTS = 'EXPERIMENTS',
  EXPERIMENT_DETAILS = 'EXPERIMENT_DETAILS',
  COMPARE_EXPERIMENTS = 'COMPARE_EXPERIMENTS',
  MODELS = 'MODELS',
  MODEL_DETAILS = 'MODEL_DETAILS',
  MODEL_APPS = 'MODEL_APPS',
  MODEL_APPS_MIZZEN = 'MODEL_APPS_MIZZEN',
  MODEL_APP_DETAILS = 'MODEL_APP_DETAILS',
  MODEL_APP_DRIFT = 'MODEL_APP_DRIFT',
  MODEL_APP_RELABELLING = 'MODEL_APP_RELABELLING',
  MODEL_REGISTRY = 'MODEL_REGISTRY',
  MODEL_REGISTRY_DETAILS = 'MODEL_REGISTRY_DETAILS',
  INSIGHTS = 'INSIGHTS',
  INSIGHT_DETAILS = 'INSIGHT_DETAILS',
  INTEGRATIONS = 'INTEGRATIONS',
  DATA_VIEWS = 'DATA_VIEWS',
  DATA_VIEW_DETAILS = 'DATA_VIEW_DETAILS',
  DATA_SOURCES = 'DATA_SOURCES',
  CONNECTORS = 'CONNECTORS',
  CONNECTOR_DETAILS = 'CONNECTOR_DETAILS',
  DATASETS = 'DATASETS',
  COMPARE_DATASETS = 'COMPARE_DATASETS',
  DATASETS_DETAILS = 'DATASETS_DETAILS',
  DATA_LABELING = 'DATA_LABELING',
  MANUAL_RELABELING = 'MANUAL_RELABELING',
  RULE_BASED_RELABELING = 'RULE_BASED_RELABELING',
  SUBSET_RELABELING = 'SUBSET_RELABELING',
  CHAT_WITH_DATASET = 'CHAT_WITH_DATASET',
  DATASETS_REGISTRATION = 'DATASETS_REGISTRATION',
  SNIPPETS = 'SNIPPETS',
  SNIPPET_DETAIL = 'SNIPPET_DETAIL',
  RUNS = 'RUNS',
  SETTINGS = 'SETTINGS',
  SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS',
  USER_PROFILE = 'USER_PROFILE',
  ERROR_ROUTE = 'ERROR_ROUTE',
  ACCESS_DENIED = 'ACCESS_DENIED',
  PUBLIC_SNIPPET = 'PUBLIC_SNIPPET',
  PUBLIC_SEARCH_APP = 'PUBLIC_SEARCH_APP',
  WORKFLOWS_LIST = 'WORKFLOWS_LIST',
  WORKFLOWS_HOME = 'WORKFLOWS_HOME',
  WORKFLOWS_PUBLIC_TEMPLATES = 'WORKFLOWS_PUBLIC_TEMPLATES',
  WORKFLOWS_TEMPLATES = 'WORKFLOWS_TEMPLATES',
  WORKFLOWS_ARCHIVED = 'WORKFLOWS_ARCHIVED',
  WORKFLOW_ADD = 'WORKFLOW_ADD',
  WORKFLOW_DETAIL = 'WORKFLOW_DETAIL',
  WORKFLOW_BUILDER = 'WORKFLOW_BUILDER',
  WORKFLOW_RUNS = 'WORKFLOW_RUNS',
  WORKFLOW_RUN_DETAIL = 'WORKFLOW_RUN_DETAIL',
  WORKFLOW_APPS_LIST = 'WORKFLOW_APPS_LIST',
  WORKFLOW_APPS_BUILDER = 'WORKFLOW_APPS_BUILDER',
  WORKFLOW_APPS_DETAILS = 'WORKFLOW_APPS_DETAILS',
  WORKFLOW_APPS_RUNS = 'WORKFLOW_APPS_RUNS',
  WORKFLOW_APPS_RUN_DETAIL = 'WORKFLOW_APPS_RUN_DETAIL',

  COPY_EDIT = 'COPY_EDIT',
  COPY_EDIT_DETAIL = 'COPY_EDIT_DETAIL',
  COPY_EDIT_SETTINGS = 'COPY_EDIT_SETTINGS',
  COPY_EDIT_ANALYTICS = 'COPY_EDIT_ANALYTICS',
  COPY_EDIT_VERSION_HISTORY = 'COPY_EDIT_VERSION_HISTORY',
  COPY_EDIT_REVIEW_MODE = 'COPY_EDIT_REVIEW_MODE',

  SUMMARIZATION = 'SUMMARIZATION',
  SUMMARIZATION_DETAIL = 'SUMMARIZATION_DETAIL',
  CHAT_WITH_MARKO = 'CHAT_WITH_MARKO',
  DOC_QNA = 'DOC_QNA',
  DOC_QNA_DETAILS = 'DOC_QNA_DETAILS',
  DOCUMENT_SEARCH = 'DOCUMENT_SEARCH',
  DATA_ANALYTICS = 'DATA_ANALYTICS',
  DATA_ANALYTICS_CHATS = 'DATA_ANALYTICS_CHATS',
  DATA_ANALYTICS_DETAILS = 'DATA_ANALYTICS_DETAILS',
  DASHBOARDS = 'DASHBOARDS',
  DASHBOARD_DETAILS = 'DASHBOARD_DETAILS',
  PUBLIC_DATA_ANALYTICS = 'PUBLIC_DATA_ANALYTICS',
  RETAIL_AI = 'RETAIL_AI',
  RETAIL_AI_PROJECT = 'RETAIL_AI_PROJECT',
  RETAIL_AI_CATEGORY = 'RETAIL_AI_CATEGORY',
  RETAIL_AI_DETAILS = 'RETAIL_AI_DETAILS',
  APP_STORE = 'APP_STORE',
  APP_STORE_DETAILS = 'APP_STORE_DETAILS',
  APP_STORE_PROJECT = 'APP_STORE_PROJECT',
  APP_STORE_SETTINGS = 'APP_STORE_SETTINGS',
  APP_STORE_DOCUMENT_DETAILS = 'APP_STORE_DOCUMENT_DETAILS',
  AI_APPS = 'AI_APPS',
  APP_BUILDER = 'APP_BUILDER',
  APP_BUILDER_DETAILS = 'APP_BUILDER_DETAILS',
  OAUTH_ROUTE = 'OAUTH_ROUTE',
  IFRAME_HOME_PAGE = 'IFRAME_HOME_PAGE',
}

export interface RouteConfig {
  path: string;
  family?: FeatureFamily;
}

export const routeDetails: { [key in AppRoutes]: RouteConfig } = {
  [AppRoutes.BASE_ROUTE]: {
    path: '/',
  },
  [AppRoutes.LOGOUT]: {
    path: '/logout',
  },
  [AppRoutes.SIGNUP]: {
    path: '/signup',
  },
  [AppRoutes.ONBOARDING]: {
    path: 'onboarding',
  },
  [AppRoutes.ERROR_ROUTE]: {
    path: `/404`,
  },
  [AppRoutes.ACCESS_DENIED]: {
    path: `/access-denied`,
  },
  [AppRoutes.INVITE]: {
    path: '/invite/:inviteId',
  },
  [AppRoutes.SETTINGS]: {
    path: 'settings',
  },
  [AppRoutes.SUBSCRIPTION_PLANS]: {
    path: 'plans',
  },
  [AppRoutes.GLOBAL_HOME]: {
    path: '/:workspaceId',
    family: FeatureFamily.GLOBAL_HOME,
  },
  [AppRoutes.DATA_STUDIO_HOME]: {
    path: 'data-studio',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.RUNS]: {
    path: 'runs',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.USER_PROFILE]: {
    path: '/user/:userId',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.PUBLIC_SNIPPET]: {
    path: `/snippets/:snippetId`,
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.PUBLIC_SEARCH_APP]: {
    path: 'document-search',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.MODEL_APPS_MIZZEN]: {
    path: 'mizzen/:modelAppId',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.MODEL_APPS]: {
    path: 'model-apps',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.MODEL_APP_DETAILS]: {
    path: ':modelId',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.MODEL_APP_DRIFT]: {
    path: 'drift/:driftResultId',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.MODEL_APP_RELABELLING]: {
    path: 'relabel/:inferenceId',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.INSIGHTS]: {
    path: 'insights',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.INSIGHT_DETAILS]: {
    path: ':dataViewId',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.DATA_LABELING]: {
    path: 'data-labeling',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.MANUAL_RELABELING]: {
    path: 'manual-relabeling',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.RULE_BASED_RELABELING]: {
    path: 'rule-relabeling',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.SUBSET_RELABELING]: {
    path: 'auto-labeling',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.CHAT_WITH_DATASET]: {
    path: 'chat-with-dataset',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.COMPARE_DATASETS]: {
    path: 'compare-datasets',
    family: FeatureFamily.DATA_STUDIO,
  },
  //Data Sources Routes
  [AppRoutes.DATA_VIEWS]: {
    path: 'data-views',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.DATA_VIEW_DETAILS]: {
    path: ':dataViewId',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.DATA_SOURCES]: {
    path: 'data-sources',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.CONNECTORS]: {
    path: 'connectors',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.INTEGRATIONS]: {
    path: 'integrations',
    family: FeatureFamily.INTEGRATIONS,
  },
  [AppRoutes.CONNECTOR_DETAILS]: {
    path: ':connectorId',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.DATASETS]: {
    path: 'data-intelligence',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.DATASETS_DETAILS]: {
    path: ':datasetId',
    family: FeatureFamily.DATA_SOURCES,
  },
  [AppRoutes.DATASETS_REGISTRATION]: {
    path: 'add',
    family: FeatureFamily.DATA_SOURCES,
  },
  //Data Analytics Routes
  [AppRoutes.DATA_ANALYTICS]: {
    path: 'data-insights',
    family: FeatureFamily.DATA_ANALYTICS,
  },
  [AppRoutes.PUBLIC_DATA_ANALYTICS]: {
    path: 'public-data-insights',
    family: FeatureFamily.DATA_ANALYTICS,
  },
  [AppRoutes.DATA_ANALYTICS_CHATS]: {
    path: 'chats',
    family: FeatureFamily.DATA_ANALYTICS,
  },
  [AppRoutes.DATA_ANALYTICS_DETAILS]: {
    path: ':resourceId',
    family: FeatureFamily.DATA_ANALYTICS,
  },
  [AppRoutes.DASHBOARDS]: {
    path: 'dashboards',
    family: FeatureFamily.DATA_ANALYTICS,
  },
  [AppRoutes.DASHBOARD_DETAILS]: {
    path: ':dashboardId',
    family: FeatureFamily.DATA_ANALYTICS,
  },
  // Model Studio routes
  [AppRoutes.MODEL_STUDIO_HOME]: {
    path: 'model-studio',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.PROJECTS]: {
    path: 'proj',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.PROJECT_DETAILS]: {
    path: ':projectId',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.MODELS]: {
    path: 'models',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.MODEL_DETAILS]: {
    path: ':modelId',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.MODEL_REGISTRY]: {
    path: 'registry',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.MODEL_REGISTRY_DETAILS]: {
    path: 'registry/:registryId',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.EXPERIMENTS]: {
    path: 'experiments',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.EXPERIMENT_DETAILS]: {
    path: ':experimentId',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.RECORDINGS]: {
    path: 'recordings',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.DELETED_RECORDINGS]: {
    path: 'deleted',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.EVALUATIONS]: {
    path: 'evaluations',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.RECORDING_DETAIL]: {
    path: ':recId',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.EVALUATION_DETAILS]: {
    path: ':evaluationId',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.COMPARE_EXPERIMENTS]: {
    path: 'compare-experiments',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.COMPARE_RECORDINGS_MULTIMODEL]: {
    path: 'compare-recordings-multimodel',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.COMPARE_RECORDINGS_MULTIDATASET]: {
    path: 'compare-recordings-multidataset',
    family: FeatureFamily.MODEL_STUDIO,
  },
  [AppRoutes.COMPARE_EVALUATIONS_MULTIMODEL]: {
    path: 'compare-evaluation-models',
    family: FeatureFamily.MODEL_STUDIO,
  },
  // Workflows routes
  [AppRoutes.WORKFLOWS_HOME]: {
    path: 'workflows',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOWS_LIST]: {
    path: 'list',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOWS_PUBLIC_TEMPLATES]: {
    path: `/templates/:templateId`,
  },
  [AppRoutes.WORKFLOWS_TEMPLATES]: {
    path: 'templates',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOWS_ARCHIVED]: {
    path: 'archived',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_DETAIL]: {
    path: ':workflowId',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_BUILDER]: {
    path: 'builder',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_ADD]: {
    path: ':workflowId/add',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_RUNS]: {
    path: 'runs',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_RUN_DETAIL]: {
    path: ':runId',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_APPS_BUILDER]: {
    path: 'builder',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_APPS_LIST]: {
    path: 'apps',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_APPS_DETAILS]: {
    path: ':appId',
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.WORKFLOW_APPS_RUNS]: {
    path: 'runs',
  },
  [AppRoutes.WORKFLOW_APPS_RUN_DETAIL]: {
    path: ':runId',
  },
  // Snippets routes
  [AppRoutes.SNIPPETS]: {
    path: 'snippets',
    family: FeatureFamily.SNIPPETS,
  },
  [AppRoutes.SNIPPET_DETAIL]: {
    path: ':snippetId',
    family: FeatureFamily.SNIPPETS,
  },
  // App Store routes
  [AppRoutes.COPY_EDIT]: {
    path: 'copy-edit',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.COPY_EDIT_DETAIL]: {
    path: ':documentId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.COPY_EDIT_SETTINGS]: {
    path: 'settings',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.COPY_EDIT_ANALYTICS]: {
    path: 'analytics',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.COPY_EDIT_VERSION_HISTORY]: {
    path: 'history',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.COPY_EDIT_REVIEW_MODE]: {
    path: 'review',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.SUMMARIZATION]: {
    path: 'summarization',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.SUMMARIZATION_DETAIL]: {
    path: ':documentId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.CHAT_WITH_MARKO]: {
    path: 'chat-with-marko',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.DOC_QNA]: {
    path: 'doc-qna',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.DOC_QNA_DETAILS]: {
    path: ':documentId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.DOCUMENT_SEARCH]: {
    path: 'search/:appId',
    // Initial entrypoint is from workflows (may change)
    family: FeatureFamily.WORKFLOWS,
  },
  [AppRoutes.APP_STORE]: {
    path: 'store',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.APP_STORE_DETAILS]: {
    path: ':appId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.APP_STORE_DOCUMENT_DETAILS]: {
    path: ':documentId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.APP_STORE_PROJECT]: {
    path: ':projectId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.APP_STORE_SETTINGS]: {
    path: 'settings',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.RETAIL_AI]: {
    path: 'retail-ai',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.RETAIL_AI_PROJECT]: {
    path: ':projectId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.RETAIL_AI_CATEGORY]: {
    path: ':categoryId',
    family: FeatureFamily.APP_STORE,
  },
  [AppRoutes.RETAIL_AI_DETAILS]: {
    path: ':productId',
    family: FeatureFamily.APP_STORE,
  },
  // App Builder routes
  [AppRoutes.AI_APPS]: {
    path: 'ai-apps',
    family: FeatureFamily.APP_BUILDER,
  },
  [AppRoutes.APP_BUILDER]: {
    path: 'builder',
    family: FeatureFamily.APP_BUILDER,
  },
  [AppRoutes.APP_BUILDER_DETAILS]: {
    path: ':appId',
    family: FeatureFamily.APP_BUILDER,
  },
  [AppRoutes.OAUTH_ROUTE]: {
    path: 'oauth',
    family: FeatureFamily.DATA_STUDIO,
  },
  [AppRoutes.IFRAME_HOME_PAGE]: {
    path: 'iframe-home',
  },
};

export const getConfigFromPath = (path?: string) =>
  path === undefined ? undefined : Object.values(routeDetails).find(config => config.path === path);

export const getBasePathFromPathname = (pathname: string) => {
  const isWorkspacePath = /\/?wsp-/.test(pathname);
  const basePathIndex = isWorkspacePath ? 2 : 1;
  return pathname.split('/')[basePathIndex] ?? '';
};

export const goToParentRoute = () => '..';

export const getDescendantRoute = (route: AppRoutes) => {
  const { path } = routeDetails[route];
  return `${path}/*`;
};

export const getRoute = (route: AppRoutes, queryParams = {}) => {
  const { path } = routeDetails[route];
  return `${path}${createQueryParams(queryParams)}`;
};

export const getUserProfilePath = (userId: string) => {
  const { path } = routeDetails[AppRoutes.USER_PROFILE];
  return `${path.replace(':userId', userId)}`;
};

export const getIsUserProfile = (pathname: string) => pathname.split('/')[1] === 'user';
