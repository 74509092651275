import { useCallback, useEffect, useRef } from 'react';
import { ulid } from 'ulid';
import { notifications } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { snippetsApi } from '@/shared/lib/api';
import { axiosS3 } from '@/shared/lib/api/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';

interface MediaUploadProps {
  resourcePath: string;
  onSuccess: ({ src, file }: { src: string; file: File | null }) => void;
  file: File | null;
}

interface GetPathArgs {
  resourcePath: string;
  fileName: string;
}

export const S3_URL = import.meta.env.VITE_APP_S3_ASSETS_URL;
export const CDN_URL = import.meta.env.VITE_APP_CDN_URL;
const MAX_RETRIES = 0;

const getUniqueFileName = () => ulid();

export const getPathParams = ({ resourcePath, fileName }: GetPathArgs) => {
  const path = `${resourcePath}/${fileName}`;
  return path;
};

export const getReadUrl = (path: string) => `${CDN_URL}/${path}`;

const uploadFileToS3 = async (url: string, file: File) => {
  try {
    const response = await axiosS3.put(url, file, {
      headers: { 'Content-Type': file.type },
    });
    if (response.status !== 200) {
      throw new Error('Failed to upload asset on S3');
    }
  } catch (err) {
    logger.error(`Error uploading asset on CDN: ${JSON.stringify(err)}`);
  }
};

export const useMediaUpload = ({ resourcePath, onSuccess, file }: MediaUploadProps) => {
  const retryOnError = useRef(MAX_RETRIES);
  const { workspaceId } = useAppMetadata();

  const uploadFile = useCallback(
    async (fileName: string) => {
      if (!file) {
        return;
      }

      try {
        // Currently, different resources are stored in separate folders in S3.
        const objectPath = getPathParams({ resourcePath, fileName });
        const {
          data: { uploadUrl = '' },
          // TODO: Make this API generic to handle different resources dynamically
        } = await snippetsApi.createSnippetsAssetsUploadUrlV1(workspaceId, {
          objectPath,
        });

        if (!uploadUrl) {
          throw new Error(`Failed to fetch presigned URL: Resource Path: ${resourcePath}`);
        }

        await uploadFileToS3(uploadUrl, file);

        if (file?.type?.includes('image')) {
          const img = new Image();
          const cdnUrl = getReadUrl(objectPath);
          img.src = cdnUrl;

          img.onload = () => {
            onSuccess({ src: fileName, file: null });
          };

          img.onerror = () => {
            if (retryOnError.current > 0) {
              retryOnError.current--;
              setTimeout(() => uploadFile(fileName), 1000);
            } else {
              onSuccess({ src: fileName, file: null });
              notifications.show({
                variant: 'error',
                message: 'Error loading asset',
              });
            }
          };
        } else {
          onSuccess({ src: fileName, file: null });
        }
      } catch (err) {
        logger.error(`Error uploading asset: ${JSON.stringify(err)}`);
      }
    },
    [file, onSuccess, resourcePath, workspaceId],
  );

  useEffect(() => {
    const fileName = getUniqueFileName();
    uploadFile(fileName);
  }, [resourcePath, uploadFile]);
};
