import {
  ControlProps,
  RankedTester,
  and,
  hasType,
  optionIs,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { useJsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import { Text, TextArea, useInputState } from '@/shared/design-system/v2';
import { TagFormFieldSwitchWrapper } from '../TagFormFieldSwitch.wrapper';

const TextAreaControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  config,
  errors,
}: ControlProps) => {
  const [val, setVal] = useInputState(data);
  const minRows = uischema.options?.minRows ?? 3;
  const maxRows = uischema.options?.maxRows ?? 9;

  const { showFieldSelector } = config;

  const ctx = useJsonForms();
  const formData = ctx.core?.data;

  if (!visible) {
    return null;
  }

  const onChange = (val: string) => {
    setVal(val);
    handleChange(path, val);
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <TagFormFieldSwitchWrapper
      path={path}
      onChange={handleChange}
      formData={formData}
      showFieldSelector={showFieldSelector}
    >
      <TextArea
        autosize
        ariaLabel={label ?? ' '}
        value={val}
        onChange={onChange}
        label={
          label.length > 0 ? (
            <Text span variant="subTitle02">
              {label}
            </Text>
          ) : null
        }
        description={
          <Text span variant="small02" color="gray.7" pb="sm">
            {description}
          </Text>
        }
        minRows={minRows}
        maxRows={maxRows}
        required={required}
        placeholder={uischema.options?.placeholder}
        disabled={!isViewOnlyForm && !enabled}
        readOnly={isViewOnlyForm}
        error={config.isFormDirty ? errors : undefined}
      />
    </TagFormFieldSwitchWrapper>
  );
};

export const textAreaControlTester: RankedTester = rankWith(
  2,
  and(
    uiTypeIs('Control'),
    optionIs('multipleLines', true),
    schemaMatches(schema => hasType(schema, 'string')),
  ),
);

export const TextAreaControl = withJsonFormsControlProps(TextAreaControlComponent);
