import {
  ControlProps,
  OwnPropsOfEnum,
  RankedTester,
  and,
  isOneOfControl,
  optionIs,
  rankWith,
} from '@jsonforms/core';
import { useJsonForms, withJsonFormsOneOfEnumProps } from '@jsonforms/react';
import { Horizontal, Radio, Text, Vertical } from '@/shared/design-system/v2';
import { TagFormFieldSwitchWrapper } from '../TagFormFieldSwitch.wrapper';

const RadioControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  schema,
  uischema,
  data,
  enabled,
  options = [],
  config,
  errors,
  visible,
}: ControlProps & OwnPropsOfEnum) => {
  const { showFieldSelector } = config;

  const ctx = useJsonForms();
  const formData = ctx.core?.data;

  if (!visible) {
    return null;
  }

  const isViewOnlyForm = config.viewOnly ?? false;

  const onChange = (selectedValue: string) => {
    if (isViewOnlyForm) {
      return;
    }

    handleChange(path, selectedValue);
  };

  const descriptions = schema.oneOf?.map(item => item.description) ?? [];

  const layout = uischema.options?.layout; // 'horizontal' or 'vertical'

  const LayoutComponent = layout === 'horizontal' ? Horizontal : Vertical;

  return (
    <TagFormFieldSwitchWrapper
      path={path}
      onChange={handleChange}
      formData={formData}
      showFieldSelector={showFieldSelector}
    >
      <Radio.Group
        aria-label={label}
        label={
          <Text span variant="subTitle02">
            {label}
          </Text>
        }
        description={
          <Text span variant="small02" color="gray.7">
            {description}
          </Text>
        }
        value={data}
        onChange={onChange}
        required={required}
        error={config.isFormDirty ? errors : undefined}
        aria-disabled="true"
      >
        <LayoutComponent spacing="lg" pt="sm">
          {options.map((item, idx) => (
            <Radio
              key={idx}
              value={item.value}
              label={
                <Text span variant="subTitle04" color="gray.7">
                  {item.label}
                </Text>
              }
              description={
                <Text span variant="small02" color="gray.7" pb="sm">
                  {descriptions[idx]}
                </Text>
              }
              disabled={isViewOnlyForm || !enabled}
              readOnly={isViewOnlyForm}
            />
          ))}
        </LayoutComponent>
      </Radio.Group>
    </TagFormFieldSwitchWrapper>
  );
};

export const radioControlTester: RankedTester = rankWith(
  9,
  and(isOneOfControl, optionIs('format', 'radio')),
);

export const RadioControl = withJsonFormsOneOfEnumProps(RadioControlComponent);
