// Copied from: https://gist.github.com/antoniel/1f8d59f7e3f73487e5ba02a293fb39ec
import {
  UseMutationOptions,
  UseMutationResult,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

/**
 * This hook provides a global cache for the mutation state.
 * It serves as a drop-in replacement for useMutation.
 *
 * Note: This is a temporary solution until we upgrade to v5.
 * Once upgraded, this hook should be removed and useMutationState
 * should be used to track the state of a mutation.
 *
 * https://tanstack.com/query/latest/docs/framework/react/reference/useMutationState
 */
export const useMutationWithCache = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>({
  mutationFn,
  mutationKey,
  ...options
}: NonNullableProperties<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationKey' | 'mutationFn'
>): UseMutationResult<TData, TError, TVariables, TContext> => {
  const queryClient = useQueryClient();
  const query = useQuery<TData, TError>(
    ['CustomMutation', mutationKey],
    async () => await Promise.resolve(false as unknown as TData),
    { retry: false, cacheTime: Infinity, staleTime: Infinity },
  );
  const queryVariables = useQuery<TVariables, TError>(
    ['CustomMutationVariables', mutationKey],
    async () => await Promise.resolve(false as unknown as TVariables),
    { retry: false, cacheTime: Infinity, staleTime: Infinity },
  );
  const queryError = useQuery<TError, TData>(
    ['CustomMutationError', mutationKey],
    async () => await Promise.resolve(false as unknown as TError),
    { retry: false, cacheTime: Infinity, staleTime: Infinity },
  );
  const querySuccess = useQuery<boolean, TError>(
    ['CustomMutationSuccess', mutationKey],
    async () => await Promise.resolve(false),
    { retry: false, cacheTime: Infinity, staleTime: Infinity },
  );
  const mutation = useMutation<TData, TError, TVariables, TContext>(
    mutationKey,
    async (...params) => {
      queryClient.setQueryData(['CustomMutationError', mutationKey], null);
      queryClient.setQueryData(['CustomMutationVariables', mutationKey], ...params);
      queryClient.setQueryData(['CustomMutationSuccess', mutationKey], false);
      return await mutationFn(...params);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(['CustomMutation', mutationKey], data);
        queryClient.setQueryData(['CustomMutationSuccess', mutationKey], true);
        if (options?.onSuccess) options.onSuccess(data, variables, context);
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(['CustomMutationError', mutationKey], err);
        queryClient.setQueryData(['CustomMutationSuccess', mutationKey], false);
        if (options?.onError) options.onError(err, variables, context);
      },
    },
  );
  const isLoading = useIsMutating(mutationKey);

  const reset = () => {
    queryClient.setQueryData(['CustomMutation', mutationKey], null);
    queryClient.setQueryData(['CustomMutationVariables', mutationKey], null);
    queryClient.setQueryData(['CustomMutationError', mutationKey], null);
    mutation.reset();
  };

  // We need typecasting here due the ADT about the mutation result, and as we're using a data not related to the mutation result
  // The typescript can't infer the type correctly.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    ...mutation,
    data: query.data,
    isLoading: !!isLoading,
    error: queryError.data,
    isError: !!queryError.data,
    isSuccess: querySuccess.data,
    variables: queryVariables.data,
    reset,
  } as UseMutationResult<TData, TError, TVariables, TContext>;
};
