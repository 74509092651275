import { PropsWithChildren, ReactNode } from 'react';
import { ChatBubble } from '@/shared/design-system';
import { Text, Vertical } from '@/shared/design-system/v2';

export interface EmptyChatContentProps {
  titleText?: ReactNode;
  subHeadingText?: ReactNode;
  descriptionText?: ReactNode;
}

export const EmptyChatContent = ({
  titleText = '',
  subHeadingText = '',
  descriptionText = '',
  children,
}: PropsWithChildren<EmptyChatContentProps>) => (
  <Vertical mih={480} w="100%" align="center" justify="center" spacing="sm">
    <ChatBubble width={96} />
    <Text variant="subTitle01" ta="center" maw="75%" color="gray.9">
      {titleText}
    </Text>
    <Text variant="bodyShort01" fw={400} ta="center" color="gray.7" maw="75%">
      {subHeadingText}
    </Text>
    <Text variant="bodyShort03" ta="center" color="gray.7" maw="75%">
      {descriptionText}
    </Text>
    {children}
  </Vertical>
);
