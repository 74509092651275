import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useEffect, useRef, useState } from 'react';
import { apiConfiguration } from '@/shared/lib/api/api';
import {
  appStoreApiAxiosParamCreator,
  publicAppStoreApiAxiosParamCreator,
} from '@/shared/lib/api/reno';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useAuth } from '../../contexts/auth-provider/Auth';

interface SSEApiDetailsProp {
  path: string;
  headers?: Record<string, string>;
}

const getSSEApiDetails = async (
  query: string,
  userId: string,
  isAuthenticated?: boolean,
  getAccessTokenSilently?: any,
): Promise<SSEApiDetailsProp> => {
  if (isAuthenticated) {
    const accessToken = await getAccessTokenSilently();
    const { url: path } = await appStoreApiAxiosParamCreator.streamLLMResponse(query);
    return {
      path,
      headers: { Authorization: `Bearer ${accessToken}`, 'member-id': userId },
    };
  }

  const { url: path } = await publicAppStoreApiAxiosParamCreator.publicStreamLLMResponse(query);
  return {
    path,
    headers: undefined,
  };
};

interface MutateOptions {
  onMessage?: (message: string) => void;
  onComplete?: (data: string) => void;
  onError?: (err: unknown) => void;
}

export const useSSEQuery = () => {
  const [data, setData] = useState('');
  const [state, setState] = useState<
    EventSource['CLOSED'] | EventSource['OPEN'] | EventSource['CONNECTING']
  >(EventSource.CONNECTING);

  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const { userId } = useAppMetadata();

  const abortCtrl = useRef<AbortController | null>(null);

  const closeEventSource = () => {
    if (abortCtrl.current) {
      abortCtrl.current.abort();
    }
  };

  const mutate = async (query: string, options?: MutateOptions) => {
    setState(EventSource.CONNECTING);
    setData('');
    closeEventSource();

    abortCtrl.current = new AbortController();

    const { path, headers } = await getSSEApiDetails(
      query,
      userId,
      isAuthenticated,
      getAccessTokenSilently,
    );

    const url = `${apiConfiguration.basePath}${path}`;

    // TODO: Add error handling
    fetchEventSource(url, {
      method: 'POST',
      body: JSON.stringify({ query }),
      signal: abortCtrl.current.signal,
      // SSE will continue even if the document is hidden
      openWhenHidden: true,
      headers,
      onmessage: msg => {
        // Replace escaped newline characters (\n) in the
        // incoming message data with actual newline characters (\n).
        const formattedData = msg.data.replace(/\\n/g, '\n');
        setData(prevText => prevText + formattedData);
        options?.onMessage?.(formattedData);
      },
      async onopen() {
        setState(EventSource.OPEN);
      },
      onclose: () => {
        setState(EventSource.CLOSED);
        setData(finalData => {
          options?.onComplete?.(finalData);
          return finalData;
        });
      },
      onerror(err) {
        options?.onError?.(err);
      },
    });
  };

  useEffect(
    () => () => {
      closeEventSource();
    },
    [],
  );

  return {
    data,
    mutate,
    closeEventSource,
    isConnected: state === EventSource.OPEN,
    isConnecting: state === EventSource.CONNECTING,
    isConnectionClosed: state === EventSource.CLOSED,
  };
};
