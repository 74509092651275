import { Fragment } from '@tiptap/pm/model';
import { TextSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { v4 as uuid } from 'uuid';
import { DIFF_TYPE } from '@/main/components/common/constants';
import { Citation, CitationPositionType, CopyEditCitation } from '@/main/generated/api';
import { MarkovReference } from '@/shared/design-system/v2/rich-text-editor/extensions/mkv-reference/extension';

export interface CitationInformationFormProps {
  data: any;
  isNew: boolean;
  setIsValidCitation: (isValid: boolean) => void;
  handleSave: (citation: Citation) => void;
  isLoadingSave: boolean;
  citationData?: any;
  setCitationData: (citation: Citation) => void;
}

/**
 * Insert citation content into the editor.
 * When inserting list of citations, also adding documentId
 * so that it can be used to fetch the document metadata and fetch
 * latest citation-style
 */
export const insertInlineCitationToEditor = (
  editor: Editor,
  documentId: string,
  citations: CopyEditCitation[],
  removeSelectedText = false,
) => {
  const citationIds: string[] = citations
    .map(citation => citation.citationId)
    .filter((id): id is string => id !== undefined);

  const { state, schema } = editor;
  const { tr } = state;
  const { $from, $to } = state.selection;

  if ($to === null || $to === undefined || $from === null || $from === undefined) {
    return '';
  }

  tr.setSelection(new TextSelection($from, $to));

  const citationJSON = {
    type: MarkovReference.name,
    attrs: {
      documentId: documentId,
      referenceIds: JSON.stringify(citationIds),
      format: CitationPositionType.InText,
      highlightType: DIFF_TYPE.EQUAL,
    },
  };
  const citationNode = schema.nodeFromJSON(citationJSON);

  // This will include spaces in existing marks (textStyle) if any
  const marksAtCursor = $to.marks().map(mark => ({
    ...mark,
    type: mark.type.name,
  }));
  const spaceJSON = {
    type: 'text',
    text: ' ',
    marks: marksAtCursor,
  };
  const spaceNode = schema.nodeFromJSON(spaceJSON);
  tr.insert($to.pos, spaceNode);
  tr.insert($to.pos + spaceNode.nodeSize, citationNode);
  tr.insert($to.pos + spaceNode.nodeSize + citationNode.nodeSize, spaceNode);
  tr.scrollIntoView();

  if (removeSelectedText) {
    tr.deleteRange($from.pos, $to.pos);
  }

  editor.view.dispatch(tr);
};

export const insertBibliographyCitationsToEditor = (
  editor: Editor,
  documentId: string,
  citations: CopyEditCitation[],
) => {
  const { state, schema } = editor;
  const { tr } = state;
  const { $from, $to } = state.selection;

  if ($to === null || $to === undefined || $from === null || $from === undefined) {
    return '';
  }

  const referencesNodes = citations.map(({ citationId }) =>
    schema.nodeFromJSON({
      type: 'paragraph',
      attrs: {
        'data-id': uuid(),
      },
      content: [
        {
          type: MarkovReference.name,
          attrs: {
            documentId,
            referenceIds: JSON.stringify([citationId]),
            format: CitationPositionType.Bibliography,
            highlightType: DIFF_TYPE.EQUAL,
          },
        },
      ],
    }),
  );

  tr.insert($to.pos, Fragment.fromArray(referencesNodes));
  tr.scrollIntoView();

  editor.view.dispatch(tr);
};

export const HEIGHT_ABOVE_SCROLL = 72;
export const HEIGHT_BELOW_SCROLL = 52;

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthOptions = [
  { group: 'No Date', label: 'No Date', value: 'n.d' },
  ...monthNames.map((month, index) => ({
    group: 'Month',
    label: month,
    value: (index + 1).toString().padStart(2, '0'),
  })),
];

export const dayOptions = [
  { group: 'No Date', label: 'No Date', value: 'n.d' },
  ...Array.from({ length: 31 }, (_, i) => ({
    group: 'Day',
    label: (i + 1).toString(),
    value: (i + 1).toString().padStart(2, '0'),
  })),
];

export function isValidHttpUrl(url: string): boolean {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (error) {
    return false;
  }
}

export const getDOILinkFromCitation = (doi: string): string | null => {
  if (!doi) return null;

  // Ensure DOI has the proper prefix
  if (!doi.startsWith('http://') && !doi.startsWith('https://')) {
    return `https://doi.org/${doi.replace(/^doi.org\//, '')}`;
  }

  // Ensure DOI has a valid protocol
  if (doi.startsWith('doi.org/')) {
    return `https://${doi}`;
  }

  return doi;
};

export function searchRecordsBasedOnKeyword<T extends Record<string, any>>(
  data: T[],
  searchTerm: string,
): T[] {
  /**
   * Search for a term in an array of objects, including nested objects.
   *
   * @param {T[]} data - List of objects
   * @param {string} searchTerm - Term to search for
   * @return {T[]} - List of objects matching the search term
   */
  const results: T[] = [];

  function searchInItem(item: unknown, term: string): boolean {
    /**
     * Recursively search in an object or an array for the term.
     */
    if (item && typeof item === 'object') {
      if (Array.isArray(item)) {
        return item.some(element => searchInItem(element, term));
      }
      return Object.values(item).some(value => searchInItem(value, term));
    }
    return typeof item === 'string' && item.toLowerCase().includes(term.toLowerCase());
  }

  for (const obj of data) {
    if (searchInItem(obj, searchTerm)) {
      results.push(obj);
    }
  }

  return results;
}
