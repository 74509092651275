import { IconChartBar, IconChartHistogram, IconTable, IconTableDown } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { Box, Button, Horizontal, Tabs, Text, Vertical } from '@/shared/design-system/v2';
import { ChartTypes } from '../../../../../../charts';
import { useCsvDownload } from '../../../../../../hooks/useDownloadData';
import {
  TABLE_HEADER_HEIGHT,
  TABLE_MAX_VIEW_HEIGHT,
  TABLE_ROW_HEIGHT,
  TableView,
} from '../TableView';
import { useChartSelectionModal } from './ChartSelectionModal';
import { ChartView } from './ChartView';
import { ViewModeTabs } from './ViewModeTabs';

const MENU_ICON_SIZE = 20;
const DEFAULT_CHART_TYPE = ChartTypes.COLUMN;
const MENU_LEFT_ICON_SIZE = 20;

// Prevents showing chart when data is not valid, even if BE suggests a chart
const isDataChartable = (data: Record<string, unknown>[], yColName: string) => {
  if (data.length < 2 || data.length > 1500) return false;

  const datum = data[0];
  if (!datum || Object.keys(datum).length !== 2) return false;

  // At least 1 numeric column should be there
  return data.some(d => !isNaN(parseFloat(d[yColName] as string)));
};

export enum MultiChartViewMode {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

interface MultiChartVizProps {
  title: string;
  rawData: Record<string, unknown>[];
  xColName: string;
  yColName: string;
  xAxisTitle: string;
  yAxisTitle: string;
  viewMode?: MultiChartViewMode;
  chartType?: ChartTypes;
  setChartType?: (chartType: ChartTypes) => void;
  setViewMode?: (viewMode: MultiChartViewMode) => void;
}

export const MultiChartViz = ({
  title,
  rawData,
  xColName,
  yColName,
  xAxisTitle,
  yAxisTitle,
  chartType = DEFAULT_CHART_TYPE,
  viewMode = MultiChartViewMode.TABLE,
  setChartType = noop,
  setViewMode = noop,
}: MultiChartVizProps) => {
  const canShowChart = isDataChartable(rawData, yColName);
  const { handleDownload, isDownloading } = useCsvDownload();

  // For more data points (>50), only column charts perform well.
  // TODO: Improve chart performance for a large number of points.
  const LARGE_DATA_THRESHOLD = 50;
  const effectiveChartType = rawData.length > LARGE_DATA_THRESHOLD ? ChartTypes.COLUMN : chartType;
  const { open: openChartSelectionModal } = useChartSelectionModal({
    onSelect: setChartType,
    activeValue: effectiveChartType,
    allowedCharts: rawData.length > LARGE_DATA_THRESHOLD ? [ChartTypes.COLUMN] : undefined,
  });

  const viewModeTabs = [
    {
      value: MultiChartViewMode.TABLE,
      label: 'Table view',
      icon: <IconTable size={MENU_ICON_SIZE} />,
      content: (
        <Box
          mah={TABLE_MAX_VIEW_HEIGHT}
          h={rawData.length * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT}
        >
          <TableView content={rawData} />
        </Box>
      ),
    },
    {
      value: MultiChartViewMode.CHART,
      label: 'Chart view',
      icon: <IconChartBar size={MENU_ICON_SIZE} />,
      content: (
        <ChartView
          title={title}
          chartType={effectiveChartType}
          rawData={rawData}
          xColName={xColName}
          yColName={yColName}
          xAxisTitle={xAxisTitle}
          yAxisTitle={yAxisTitle}
        />
      ),
    },
  ];

  const handleTabChange = (val: MultiChartViewMode) => {
    setViewMode(val);
  };

  const dataToCsvFormat = (data: Record<string, unknown>[]): string[][] => {
    if (data.length === 0) return [];

    const headers = Object.keys(data[0]);
    const csvData = data.map(row => headers.map(header => String(row[header] ?? '')));

    return [headers, ...csvData];
  };

  const handleCSVDownload = () => {
    const csvData = dataToCsvFormat(rawData);
    handleDownload({
      data: csvData,
      fileName: title,
    });
  };

  return (
    <ViewModeTabs keepMounted value={viewMode} onTabChange={handleTabChange}>
      <Vertical spacing="sm">
        <Horizontal position="apart">
          {canShowChart ? (
            <Tabs.List>
              {viewModeTabs.map(({ value, label, icon }) => (
                <Tabs.Tab key={value} value={value} icon={icon}>
                  <Text span variant="subTitle03">
                    {label}
                  </Text>
                </Tabs.Tab>
              ))}
            </Tabs.List>
          ) : (
            // Box needed since parent uses position: apart
            <Box />
          )}
          {viewMode === MultiChartViewMode.CHART && (
            <Button
              leftIcon={<IconChartHistogram size={MENU_LEFT_ICON_SIZE} />}
              onClick={openChartSelectionModal}
            >
              Change chart
            </Button>
          )}
          {viewMode === MultiChartViewMode.TABLE && (
            <Button
              loading={isDownloading}
              leftIcon={<IconTableDown size={MENU_LEFT_ICON_SIZE} />}
              onClick={handleCSVDownload}
            >
              Download
            </Button>
          )}
        </Horizontal>
        {viewModeTabs.map(({ value, content }) => (
          <Tabs.Panel key={value} value={value}>
            {content}
          </Tabs.Panel>
        ))}
      </Vertical>
    </ViewModeTabs>
  );
};
