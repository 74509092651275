import { HocuspocusProvider } from '@hocuspocus/provider';
import { useEditor } from '@tiptap/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import noop from 'lodash/noop';
import { useEffect } from 'react';
import { SnippetAddSlashCommandProperties } from '@/main/amplitude';
import { sendAnalytics } from '@/main/initializers/analytics';
import { snippetEvents } from '../../../../../apps/main/analytics';
import {
  SnippetDetailContext,
  useSnippetDetail,
} from '../../../../../apps/main/components/snippets/detail/context/SnippetContext';
import { useAppMetadata } from '../../../../../apps/main/contexts/app-metadata/AppMetadata';
import {
  useGetUserInfoQuery,
  useGetWorkspaceMembersQuery,
} from '../../../../../apps/main/queries/account/workspace';
import { getMentionSuggestionOptions } from '../extensions/mentions/mentionSuggestion';
import { getCommandsSuggestionOptions } from '../extensions/slash-command/commandsSuggestion';
import { getEditorExtensions } from '../extensions/util';

interface TextEditorOptions {
  mediaResourcePath?: string;
  onClickComment?: (
    conversationId: string,
    pos: number,
    selection: { from: number; to: number },
  ) => void;
  commandOptions?: { openAddCharts: SnippetDetailContext['openAddCharts'] };
  collaborationOptions?: {
    provider?: HocuspocusProvider;
  };
}

export const useGetEditor = (
  initialContent?: string,
  editable = false,
  opts: TextEditorOptions = {},
) => {
  const { featureModelStudio } = useFlags();
  const { data } = useGetWorkspaceMembersQuery();
  const { data: user } = useGetUserInfoQuery();
  const { workspaceId } = useAppMetadata();
  const { snippetId } = useSnippetDetail();

  const sendEvent = (command: SnippetAddSlashCommandProperties['snippetCommand']) => {
    sendAnalytics(
      snippetEvents.snippet.slashCommand({
        snippetCommand: command,
        snippetId,
        workspaceId,
      }),
    );
  };

  const editor = useEditor(
    {
      extensions: getEditorExtensions({
        mentionOptions: {
          suggestion: getMentionSuggestionOptions(data?.workspaceMembers ?? []),
        },
        commandOptions: {
          suggestion: getCommandsSuggestionOptions({
            openAddCharts: opts.commandOptions?.openAddCharts ?? noop,
            sendEvent,
            showModelOptions: !!featureModelStudio,
          }),
        },
        commentOptions: {
          onClickComment: opts.onClickComment,
        },
        currentUser: user ? user : undefined,
        provider: opts.collaborationOptions?.provider,
        resizableMediaOptions: {
          resourcePath: opts?.mediaResourcePath ?? '',
          allowVideo: true,
        },
      }),
      content: initialContent ? JSON.parse(initialContent) : undefined,
      editable,
      editorProps: {
        attributes: {
          class: 'main-editor',
        },
      },
      autofocus: true,
    },
    [opts?.mediaResourcePath, data?.workspaceMembers],
  );

  useEffect(() => {
    editor?.setEditable(editable);
  }, [editor, editable]);

  return editor;
};
