import { useGetS3PresignedUrlForFileQuery } from '@/main/queries/workflows/s3-preview';
import { Alert, Box, Center, Loader } from '@/shared/design-system/v2';
import { StreamingMediaPreview } from '../uploader/file-preview/StreamingMediaPreview';

interface VideoPreviewProps {
  url: string;
  workflowId: string;
}

export const VideoPreview = ({ url, workflowId }: VideoPreviewProps) => {
  const { isLoading, isError, data } = useGetS3PresignedUrlForFileQuery(workflowId, url);

  if (isLoading) {
    return (
      <Center w="100%" h={400}>
        <Loader />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center w="100%" h={400}>
        <Alert color="red">Not able to load file</Alert>
      </Center>
    );
  }

  return (
    <Box w="100%" h={600} py="xl">
      <StreamingMediaPreview file={data} type="video" width="100%" />
    </Box>
  );
};
