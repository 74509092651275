import { ICellRendererParams } from '@ag-grid-community/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { ActionIcon, CopyButton, Horizontal, Text } from '@/shared/design-system/v2';
import {
  ResourceModel,
  S3StorageResourceModel,
  SnowflakeStorageResourceModel,
} from '../../../generated/api';
import { storageConfigMap } from './config';

export const NameRenderer = ({
  value: metadata,
  data,
}: ICellRendererParams<ResourceModel, SnowflakeStorageResourceModel | S3StorageResourceModel>) => {
  const connectorType = data?.connectorType;
  const config =
    connectorType !== undefined && connectorType in storageConfigMap
      ? storageConfigMap[connectorType]
      : undefined;

  const resourceName = config?.getResourceName(metadata);

  if (!resourceName) {
    return '--';
  }

  return (
    <Horizontal noWrap w="100%" h="100%" position="apart">
      <Text truncate>{resourceName}</Text>
      <CopyButton value={resourceName}>
        {({ copied, copy }) => (
          <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
            {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
          </ActionIcon>
        )}
      </CopyButton>
    </Horizontal>
  );
};
