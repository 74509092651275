import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import {
  APIMessage,
  ConnectionPreviewDataResponse,
  ConnectionResponse,
  ConnectorTypeResponse,
  ConnectorV2Type,
  CreateConnectionRequest,
  CreateConnectionResponse,
  ListAllConnectionsResponse,
  ListConnectionsResponse,
  UpdateConnectionRequest,
} from '@/main/generated/api';
import { connectorsV2Api } from '@/shared/lib/api';

export const connectorsV2Keys = {
  all: ['connectorsV2'] as const,
  listAll: (workspaceId: string) => [...connectorsV2Keys.all, 'list', workspaceId] as const,
  listByType: (workspaceId: string, connectorType: ConnectorV2Type) =>
    [...connectorsV2Keys.all, 'list', workspaceId, connectorType] as const,
  detail: (workspaceId: string, connectorType: ConnectorV2Type, connectionId: string) =>
    [...connectorsV2Keys.all, 'detail', workspaceId, connectorType, connectionId] as const,
  previewData: (workspaceId: string, connectorType: ConnectorV2Type, connectionId: string) =>
    [...connectorsV2Keys.all, 'preview', workspaceId, connectorType, connectionId] as const,
  consentUrl: (workspaceId: string, connectorType: ConnectorV2Type) =>
    [...connectorsV2Keys.all, 'consent-url', workspaceId, connectorType] as const,
  types: (workspaceId: string) => [...connectorsV2Keys.all, 'types', workspaceId] as const,
};

export const useListAllConnectionsQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<ListAllConnectionsResponse>,
    AxiosError,
    ListAllConnectionsResponse
  >({
    queryKey: connectorsV2Keys.listAll(workspaceId),
    queryFn: () => connectorsV2Api.listAllConnectionsV2(workspaceId),
    enabled: Boolean(workspaceId),
    select: res => res.data,
  });
};

export const useInvalidateAllConnectionsList = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    if (workspaceId) {
      queryClient.invalidateQueries({
        queryKey: connectorsV2Keys.listAll(workspaceId),
      });
    }
  };
};

export const useListConnectionsByTypeQuery = (connectorType: ConnectorV2Type) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery<AxiosResponse<ListConnectionsResponse>, AxiosError, ConnectionResponse[]>(
    connectorsV2Keys.listByType(workspaceId, connectorType),
    () => connectorsV2Api.listConnectionsV2(workspaceId, connectorType),
    {
      enabled: Boolean(workspaceId && connectorType),
      select: res => res.data.connections,
      onSuccess: connections => {
        // Seed query cache for connection details using list results
        for (const connection of connections) {
          queryClient.setQueryData(
            connectorsV2Keys.detail(workspaceId, connectorType, connection.connectionId),
            {
              data: connection,
            },
          );
        }
      },
    },
  );
};

export const useGetConnectionDetailsQuery = (
  connectorType: ConnectorV2Type,
  connectionId: string,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery<AxiosResponse<ConnectionResponse>, AxiosError, ConnectionResponse>(
    connectorsV2Keys.detail(workspaceId, connectorType, connectionId),
    () => connectorsV2Api.getConnectionV2(workspaceId, connectorType, connectionId),
    {
      enabled: Boolean(workspaceId && connectorType && connectionId),
      select: res => res.data,
      initialData: () => {
        const cachedData = queryClient.getQueryData<AxiosResponse<ConnectionResponse>>(
          connectorsV2Keys.detail(workspaceId, connectorType, connectionId),
        );

        return cachedData || undefined;
      },
      staleTime: 60000,
    },
  );
};

export const useGetConnectionPreviewDataQuery = (
  connectorType: ConnectorV2Type,
  connectionId: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<ConnectionPreviewDataResponse>,
    AxiosError,
    ConnectionPreviewDataResponse
  >(
    connectorsV2Keys.previewData(workspaceId, connectorType, connectionId),
    () => connectorsV2Api.getConnectionPreviewDataV2(workspaceId, connectorType, connectionId),
    {
      enabled: Boolean(workspaceId && connectorType && connectionId),
      select: res => res.data,
    },
  );
};

export const useCreateConnectionMutation = (connectorType: ConnectorV2Type) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<CreateConnectionResponse>,
    AxiosError<APIMessage>,
    CreateConnectionRequest
  >(
    (request: CreateConnectionRequest) =>
      connectorsV2Api.createConnectionV2(workspaceId, connectorType, request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(connectorsV2Keys.listAll(workspaceId));
        queryClient.invalidateQueries(connectorsV2Keys.listByType(workspaceId, connectorType));
      },
    },
  );
};

export const useUpdateConnectionMutation = (
  connectorType: ConnectorV2Type,
  connectionId: string,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<ConnectionResponse>,
    AxiosError<APIMessage>,
    UpdateConnectionRequest
  >(
    (request: UpdateConnectionRequest) =>
      connectorsV2Api.updateConnectionV2(workspaceId, connectorType, connectionId, request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          connectorsV2Keys.detail(workspaceId, connectorType, connectionId),
        );
        queryClient.invalidateQueries(connectorsV2Keys.listByType(workspaceId, connectorType));
        queryClient.invalidateQueries(connectorsV2Keys.listAll(workspaceId));
      },
    },
  );
};

export const useRevokeConnectionMutation = (
  connectorType: ConnectorV2Type,
  connectionId: string,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError<APIMessage>, void>(
    () => connectorsV2Api.revokeConnectionV2(workspaceId, connectorType, connectionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(connectorsV2Keys.listAll(workspaceId));
        queryClient.invalidateQueries(connectorsV2Keys.listByType(workspaceId, connectorType));
      },
    },
  );
};

export const useListConnectorTypesQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<ConnectorTypeResponse>, AxiosError, ConnectorTypeResponse>(
    connectorsV2Keys.types(workspaceId),
    () => connectorsV2Api.listConnectorTypesV2(workspaceId),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
    },
  );
};

export const useInvalidateConnectionsList = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(connectorsV2Keys.listAll(workspaceId));
  };
};

export const useInvalidateConnectionsByType = (connectorType: ConnectorV2Type) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(connectorsV2Keys.listByType(workspaceId, connectorType));
  };
};

export const useInvalidateConnectionDetails = (
  connectorType: ConnectorV2Type,
  connectionId: string,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(
      connectorsV2Keys.detail(workspaceId, connectorType, connectionId),
    );
  };
};
