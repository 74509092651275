import {
  useInfiniteQuery,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import merge from 'lodash/merge';
import { useMemo } from 'react';
import { snippetsApi, snippetsShareApi } from '@/shared/lib/api';
import { publicApis } from '@/shared/lib/api/vienna';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AddSnippetRequest,
  ArtifactFilterState,
  ArtifactStateType,
  ListSnippetVersionResponse,
  ModelAppInputRequest,
  ModelAppStatus,
  ModelWithAppMetadata,
  ParsedInferenceResponse,
  PublicSnippetDataRequest,
  ShareSnippetRequest,
  SnippetInfoResponse,
  SnippetVersionModel,
  UpdateSnippetArtifactState,
  UpdateSnippetRequest,
} from '../../generated/api';
import { useGetWorkspaceMembersQuery } from '../account/workspace';
import { API_PAGE_SIZE } from '../constants';
import { SNIPPET } from '../queryConstants';
import { getNextPageParamHandler, responseSelector } from '../util';

const snippetKeys = {
  list: (workspaceId: string, artifactState?: ArtifactStateType) =>
    [SNIPPET.LIST_SNIPPETS, workspaceId, ...(artifactState ? [artifactState] : [])] as const,
  create: (workspaceId: string) => [SNIPPET.CREATE_SNIPPET, workspaceId],
  get: (workspaceId: string, snippetId: string) => [SNIPPET.GET_SNIPPET, workspaceId, snippetId],
  save: (workspaceId: string, snippetId: string) => [SNIPPET.SAVE_SNIPPET, workspaceId, snippetId],
  updateSharedUsers: (workspaceId: string, userId: string) => [
    SNIPPET.ADD_SHARED_USERS,
    workspaceId,
    userId,
  ],
  removeSharedUsers: (workspaceId: string) => [SNIPPET.REMOVE_SHARED_USERS, workspaceId],
  publishSnippet: (workspaceId: string) => [SNIPPET.PUBLISH_SNIPPET, workspaceId],
  getVersions: (workspaceId: string, snippetId: string) => [
    SNIPPET.GET_SHARED_USERS,
    workspaceId,
    snippetId,
  ],
  getPublicSnippet: (snippetId: string) => [SNIPPET.GET_PUBLIC_SNIPPET, snippetId],
  getAppMetadataPublicSnippet: (snippetId: string, appId: string) => [
    SNIPPET.GET_APP_METADATA_PUBLIC,
    snippetId,
    appId,
  ],
};

export const useGetSnippetsQuery = (state: ArtifactFilterState) => {
  const { workspaceId } = useAppMetadata();

  const artifactState =
    state === ArtifactFilterState.Active ? ArtifactStateType.Active : ArtifactStateType.Archived;
  return useQuery(
    snippetKeys.list(workspaceId, artifactState),
    () =>
      snippetsShareApi.getSnippetListV1(
        workspaceId,
        undefined,
        undefined,
        undefined,
        undefined,
        artifactState,
      ),
    {
      enabled: Boolean(workspaceId),
      select: responseSelector,
    },
  );
};

export const useCreateSnippetMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    snippetKeys.create(workspaceId),
    (req: AddSnippetRequest) => snippetsApi.createSnippetV1(workspaceId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
      },
    },
  );
};

export const useGetSnippetDetailQuery = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    snippetKeys.get(workspaceId, snippetId),
    () => snippetsApi.getSnippetV1(workspaceId, snippetId),
    {
      enabled: !!workspaceId && !!snippetId,
      select: data => data.data.response,
    },
  );
};

export const useInvalidateGetSnippetsDetails = (snippetId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  return (onSettled?: () => void) =>
    queryClient.invalidateQueries(snippetKeys.get(workspaceId, snippetId)).finally(onSettled);
};

export const useSaveSnippetMutation = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    snippetKeys.save(workspaceId, snippetId),
    (req: UpdateSnippetRequest & { updateDate?: string }) => {
      const { updateDate, ...restReq } = req;
      return snippetsApi.saveSnippetV1(workspaceId, snippetId, restReq);
    },
    {
      onMutate: (req: UpdateSnippetRequest) => {
        queryClient.setQueryData(
          snippetKeys.get(workspaceId, snippetId),
          (oldData?: AxiosResponse<SnippetInfoResponse>) =>
            merge(oldData, {
              data: {
                response: req,
              },
            } as AxiosResponse<SnippetInfoResponse>),
        );
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
      },
    },
  );
};

export const useIsSaveSnippetMutating = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useIsMutating({
    mutationKey: snippetKeys.save(workspaceId, snippetId),
  });
};

export const useAddSharedSnippetsUsers = (snippetId: string) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    snippetKeys.updateSharedUsers(workspaceId, userId),
    (req: ShareSnippetRequest) =>
      snippetsShareApi.addOrUpdateSharedSnippetsUsersV1(workspaceId, userId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
        queryClient.invalidateQueries(snippetKeys.get(workspaceId, snippetId));
      },
    },
  );
};

export const useRemoveSharedSnippetsUsers = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    snippetKeys.removeSharedUsers(workspaceId),
    (req: ShareSnippetRequest) => snippetsShareApi.removeUsersFromSnippetV1(workspaceId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
        queryClient.invalidateQueries(snippetKeys.get(workspaceId, snippetId));
      },
    },
  );
};

export const usePublishSnippet = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    snippetKeys.publishSnippet(workspaceId),
    () => snippetsApi.publishSnippetV1(workspaceId, { snippetId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
        queryClient.invalidateQueries(snippetKeys.get(workspaceId, snippetId));
      },
    },
  );
};

export const useDeleteSnippet = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    snippetKeys.publishSnippet(workspaceId),
    () => snippetsApi.deleteSnippetV1(workspaceId, snippetId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
      },
    },
  );
};

export const useGetSnippetVersionsQuery = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();
  const {
    isLoading: membersLoading,
    isError: errorInMembersQuery,
    data: workspaceMembers,
  } = useGetWorkspaceMembersQuery();

  const { data, isLoading, isError, ...rest } = useInfiniteQuery<
    AxiosResponse<ListSnippetVersionResponse>,
    AxiosError,
    SnippetVersionModel[]
  >(
    snippetKeys.getVersions(workspaceId, snippetId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return snippetsApi.getSnippetVersionListV1(workspaceId, snippetId, start, end, lastTimestamp);
    },
    {
      enabled: Boolean(workspaceId && snippetId),
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );

  const versions = useMemo(
    () => data?.pages.flatMap(page => page) || [],
    [data],
  ) as SnippetVersionModel[];

  return {
    data: { versions, workspaceMembers: workspaceMembers?.workspaceMembers ?? [] },
    isLoading: isLoading || membersLoading,
    isError: isError || errorInMembersQuery,
    ...rest,
  };
};

export const useInvalidateVersionsList = (snippetId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  return () => queryClient.invalidateQueries(snippetKeys.getVersions(workspaceId, snippetId));
};

export const useGetPublicSnippetQuery = (snippetId: string) =>
  useQuery(
    snippetKeys.getPublicSnippet(snippetId),
    () => publicApis.getContentPublicSnippetV1(snippetId),
    {
      enabled: Boolean(snippetId),
      select: data => data.data,
    },
  );

export const useSavePublicSnippetContent = (snippetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (snippetChartMetadataRequest: PublicSnippetDataRequest) =>
      snippetsShareApi.addDataPublicSnippetV1(workspaceId, snippetId, snippetChartMetadataRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(snippetKeys.list(workspaceId));
        queryClient.invalidateQueries(snippetKeys.get(workspaceId, snippetId));
      },
    },
  );
};

export const useSnippetStateMutation = (snippetsArtifactState: UpdateSnippetArtifactState) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => snippetsApi.updateSnippetStateV1(workspaceId, snippetsArtifactState), {
    onSuccess: () => {
      queryClient.invalidateQueries(snippetKeys.list(workspaceId));
    },
  });
};

export const useGetModelAppMetadata = (snippetId: string, appId: string) =>
  useQuery(
    snippetKeys.getAppMetadataPublicSnippet(snippetId, appId),
    () => publicApis.getAppMetadataPublicSnippetV1(snippetId, appId),
    {
      enabled: Boolean(snippetId && appId),
      select: data => data.data.data as ModelWithAppMetadata,
      refetchInterval: (data?: ModelWithAppMetadata) => {
        const isLoading =
          data?.modelAppStatus === ModelAppStatus.Pending ||
          data?.modelAppStatus === ModelAppStatus.Initialized;
        return isLoading ? 5 * 1000 : false;
      },
    },
  );

export const useInvalidatePublicModelApps = (snippetId: string, appId: string) => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries(snippetKeys.getAppMetadataPublicSnippet(snippetId, appId));
};

export const useSubmitPublicModelInput = (snippetId: string, appId: string) => {
  const invalidatePublicModelApps = useInvalidatePublicModelApps(snippetId, appId);

  return useMutation(
    (req: ModelAppInputRequest) =>
      publicApis
        .getAppPublicSnippetV1(snippetId, appId, req)
        .then(data => data.data as unknown as AxiosPromise<ParsedInferenceResponse>),
    {
      onSuccess: response => {
        if (response.data.status !== ModelAppStatus.Running) {
          invalidatePublicModelApps();
        }
      },
    },
  );
};
