import { IconWindowMaximize } from '@tabler/icons-react';
import { useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Horizontal,
  MODAL_LEVEL_2_Z_INDEX,
  Modal,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { RowPreview } from './RowPreview';

interface PreviewCellProps {
  data: any;
  schema: {
    name: string;
    type: string;
    placeholder?: boolean;
  }[];
  isRunsPreview?: boolean;
  placeholderText?: string;
}

export const PreviewCell = ({
  data,
  schema,
  isRunsPreview = false,
  placeholderText,
}: PreviewCellProps): JSX.Element => {
  const theme = useMarkovTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <Center h="100%" w="100%">
      <ActionIcon aria-label="Open Row Preview" onClick={handleOpen}>
        <IconWindowMaximize size={16} color={theme.colors.blue[6]} />
      </ActionIcon>
      <Modal
        withinPortal
        opened={isOpen}
        onClose={handleClose}
        size="1480px"
        padding={0}
        zIndex={MODAL_LEVEL_2_Z_INDEX}
        title={
          <Text px="xxl" pt="lg" pb="sm" variant="heading04">
            Row preview
          </Text>
        }
        closeButtonProps={{
          ml: 0,
          mr: '24px',
        }}
      >
        <Box mah="76vh" pos="relative">
          <Vertical spacing="sm" h="100%" px="48px" py="md">
            <RowPreview
              data={data}
              schema={schema}
              isRunsPreview={isRunsPreview}
              placeholderText={placeholderText}
            />
            <Horizontal position="right">
              <Button variant="subtle" color="gray.7" onClick={handleClose}>
                Close
              </Button>
            </Horizontal>
          </Vertical>
        </Box>
      </Modal>
    </Center>
  );
};
