import { useState } from 'react';
import { ConnectorV2Type } from '@/main/generated/api';
import { useInvalidateConnectorsList } from '@/main/queries/connectors';
import { useCreateConnectionMutation } from '@/main/queries/connectors/v2/connectors';
import {
  Alert,
  Button,
  Horizontal,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
} from '@/shared/design-system/v2';
import { connectorTypeToResourceTypeMap, getConnectorTypeDisplayName } from './utils';

interface ReadmeAPIModalProps {
  connectorType: ConnectorV2Type;
  error?: string;
  onChange: (value: string) => void;
  setError: (value: string) => void;
  isOpenInForm?: boolean;
}

export const ReadmeAPIModal = ({
  connectorType,
  error,
  onChange,
  setError,
  isOpenInForm,
}: ReadmeAPIModalProps) => {
  const [inputApiKey, setInputApiKey] = useState('');

  const { mutateAsync: createConnection, isLoading: isTestingConnection } =
    useCreateConnectionMutation(connectorType);
  const invalidateConnectorsList = useInvalidateConnectorsList();

  const handleTestReadmeConnection = async (apiKey: string, onChange: (value: string) => void) => {
    try {
      const response = await createConnection({
        connectorName: '', // For readme connector, we don't need connector name. Name is inferred directly from Readme API.
        credentials: {
          api_key: apiKey,
        },
      });
      const responseConnectorId = response.data?.connectionId;
      if (responseConnectorId) {
        onChange(responseConnectorId);
        invalidateConnectorsList();
        notifications.success('Readme connector created successfully');
        closeAllModals();
      }
    } catch (e) {
      setError('Unable create connector with the provided API Key');
      notifications.error('Unable create connector with the provided API Key');
    }
  };

  return (
    <Vertical>
      <Horizontal spacing="xs" display={isOpenInForm ? 'none' : 'flex'}>
        <Text variant="bodyShort02">Enter your Readme API Key to connect your Readme account</Text>
        <Text span variant="subTitle02" color="red.7">
          *
        </Text>
      </Horizontal>
      <TextInput
        ariaLabel="Readme API Key"
        value={inputApiKey}
        onChange={e => setInputApiKey(e.target.value)}
        placeholder="Enter your Readme API Key"
        pt="xs"
      />
      {error && (
        <Alert color="red">
          Seems like there was an issue authorizing your credentials. Please try again!
        </Alert>
      )}
      <Text variant="bodyShort02" my="sm">
        Connect your {getConnectorTypeDisplayName(connectorType)}{' '}
        {connectorTypeToResourceTypeMap[connectorType]} to get started
      </Text>
      <Button
        onClick={() => handleTestReadmeConnection(inputApiKey, onChange)}
        variant="primary"
        w="240px"
        disabled={!inputApiKey}
        loading={isTestingConnection}
      >
        Test and connect
      </Button>
    </Vertical>
  );
};
