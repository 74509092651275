import { createStyles } from '@/shared/design-system/v2';

export const useLoaderStyles = createStyles(theme => ({
  root: {
    height: 10,
    borderRadius: theme.radius.sm,

    '@keyframes expand': {
      '0%': {
        width: '0%',
      },
    },

    '@keyframes shimmer': {
      '0%': {
        transform: 'translateX(-100%)',
      },
      '100%': {
        transform: 'translateX(100%)',
      },
    },

    backgroundColor: '#F22797', // Base darker color
    position: 'relative',
    overflow: 'hidden',
    animation: 'expand 0.4s ease-out forwards',

    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: '#FEE9F5',
      opacity: 0.7,
    },

    '::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-50%', // Start from outside the visible area
      right: '-50%', // Extend beyond the visible area
      bottom: 0,
      background:
        'linear-gradient(90deg, rgba(254, 233, 245, 0) 0%, rgba(254, 233, 245, 1) 50%, rgba(254, 233, 245, 0) 100%)',
      animation: 'shimmer 1.8s infinite ease-in-out',
      animationDelay: '0.5s ',
    },

    // Different expansion speeds
    '&:nth-of-type(1)': {
      animationDuration: '0.4s',
      width: '95%',
    },

    '&:nth-of-type(2)': {
      animationDuration: '0.6s',
      width: '95%',
    },

    '&:nth-of-type(3)': {
      animationDuration: '0.8s',
      width: '60%',
    },

    // Different shimmer delays
    '&:nth-of-type(1)::after': {
      animationDelay: '0.4s',
    },

    '&:nth-of-type(2)::after': {
      animationDelay: '0.8s',
    },

    '&:nth-of-type(3)::after': {
      animationDelay: '1.2s',
    },
  },
}));

export const usePrismStyles = createStyles({
  prismCode: {
    '& .mantine-Prism-code': {
      backgroundColor: 'transparent !important',
    },
  },
});
