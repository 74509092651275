import { useEffect, useState } from 'react';
import { Alert, Center, Loader } from '@/shared/design-system/v2';
import { parseCSVString } from '@/shared/lib/fileparse';
import { OperatorCategory } from '../../../../../../../generated/api';
import { useNodeDataPreviewQuery } from '../../../../../../../queries/workflows/operators';
import { DataPreview } from './DataPreview';

interface DataPreviewContainerProps {
  workflowId: string;
  operatorId: string;
  runId: string;
  nodeId: string;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  schema: {
    name: string;
    type: string;
  }[];
  operatorCategory?: OperatorCategory;
  modifiedColumns?: string[];
  shadingColor?: string;
  placeholderText?: string;
}

export const DataPreviewContainer = ({
  workflowId,
  operatorId,
  runId,
  nodeId,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  schema,
  operatorCategory,
  modifiedColumns,
  shadingColor,
  placeholderText,
}: DataPreviewContainerProps) => {
  // TODO: Remove operator category from data preview,HACKY FIX TO MAKE SOURCE OPERATORS WORK, MERGE PREVIEW+DAGIOSCHEMA into one api.
  const {
    isLoading,
    isFetching,
    error,
    data: nodeDataPreview,
    refetch: refetchNodeData,
  } = useNodeDataPreviewQuery(workflowId, runId, nodeId, operatorCategory ?? '');

  const [isDataParsing, setIsDataParsing] = useState(true);
  const [isParsingError, setIsParsingError] = useState(false);
  const [parsedData, setParsedData] = useState<string[][] | undefined>();

  useEffect(() => {
    setIsDataParsing(true);
    setIsParsingError(false);

    if (nodeDataPreview) {
      parseCSVString(nodeDataPreview.data ?? [''], ',')
        .then(results => {
          setParsedData(results);
          setIsDataParsing(false);
          setIsParsingError(false);
        })
        .catch(() => {
          setIsParsingError(true);
        });
    }
  }, [nodeDataPreview?.data]);

  if ((isLoading || isFetching || isDataParsing) && !error) {
    return (
      <Center mih={200} w="100%">
        <Loader />
      </Center>
    );
  }

  if (error || isParsingError) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">
          {error?.response?.data.detail.msg ??
            'Error fetching data preview. Try running debug again'}
        </Alert>
      </Center>
    );
  }

  if (!parsedData) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">Data preview not found. Must be an error</Alert>
      </Center>
    );
  }

  return (
    <DataPreview
      parsedData={parsedData}
      schema={schema}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      allowColumnSelection={allowColumnSelection}
      modifiedColumns={modifiedColumns}
      shadingColor={shadingColor}
      workflowId={workflowId}
      operatorId={operatorId}
      placeholderText={placeholderText}
      workflowRunId={runId}
    />
  );
};
