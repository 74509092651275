import debounce from 'lodash/debounce';
import each from 'lodash/each';
import noop from 'lodash/noop';
import { ReactElement } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Box } from '@/shared/design-system/v2';
import { useElementSize } from '@/shared/design-system/v2/hooks';
import { GridItem } from './GridItem';
import { BREAKPOINTS, MARGINS, RESPONSIVE_GRID_COLS, ROW_HEIGHT, layoutStaticProps } from './util';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
  layouts: Layouts;
  children: ReactElement[];
  className?: string;
  readOnlyMode?: boolean;
  onLayoutChange?: (currentLayout: Layout[], allLayouts: Layouts) => void;
  onBreakpointChange: (newBreakpoint: string, newCols: number) => void;
  onClick?: () => void;
}

export const GridLayout = ({
  children,
  layouts,
  className,
  readOnlyMode,
  onLayoutChange = noop,
  onBreakpointChange = noop,
  onClick,
}: GridLayoutProps) => {
  const { ref, width } = useElementSize();

  const handleLayoutChange = debounce((currentLayout: Layout[], allLayouts: Layouts) => {
    onLayoutChange(currentLayout, allLayouts);
  }, 200);

  let defaultLayoutProps = {
    ...layoutStaticProps,
  };
  if (readOnlyMode) {
    defaultLayoutProps = {
      ...layoutStaticProps,

      isDraggable: false,
      isResizable: false,
    };
  }

  each(layouts, (sizeLayouts, sizeKey) => {
    layouts[sizeKey] = sizeLayouts.map(layout => ({ ...layout, ...defaultLayoutProps }));
  });

  return (
    <Box ref={ref} className={className} onClick={onClick}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        autoSize
        breakpoints={BREAKPOINTS}
        cols={RESPONSIVE_GRID_COLS}
        rowHeight={ROW_HEIGHT}
        margin={MARGINS}
        compactType="vertical"
        width={width}
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={onBreakpointChange}
      >
        {children}
      </ResponsiveGridLayout>
    </Box>
  );
};

GridLayout.Item = GridItem;
