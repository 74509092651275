import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import {
  BaseAPIFilter,
  CreateWorkflowAppModelRequest,
  ListWorkflowAppsResponse,
  SortOrder,
  UpdateWorkflowAppDAGRequest,
  UpdateWorkflowAppModelRequest,
} from '@/main/generated/api';
import { workflowApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';

const NAMESPACE_KEY = 'WORKFLOW_APPS';
const workflowAppsKeys = {
  all: NAMESPACE_KEY,
  list: (
    workspaceId: string,
    filters?: object[],
    start?: number,
    end?: number,
    sortKey?: string,
    sortOrder?: SortOrder,
  ) =>
    [NAMESPACE_KEY, 'list', workspaceId, start, end, sortKey, sortOrder, filters].filter(x =>
      Boolean(x),
    ),
  appDetailsFromWorkflowId: (workspaceId: string, workflowId: string) => [
    NAMESPACE_KEY,
    'wf-id-app-details',
    workspaceId,
    workflowId,
  ],
  appDetails: (workspaceId: string, appId: string) => [
    NAMESPACE_KEY,
    'wf-app-details',
    workspaceId,
    appId,
  ],
};

export const useListWorkflowAppsQuery = ({
  filters = [],
  sortKey = 'createDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
}: {
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<ListWorkflowAppsResponse>,
    AxiosError<HTTPError>,
    ListWorkflowAppsResponse
  >({
    queryKey: workflowAppsKeys.list(workspaceId, filters, start, limit, sortKey, sortOrder),
    queryFn: () =>
      workflowApi.listWorkflowAppsV1(workspaceId, {
        start,
        limit,
        sortKey,
        sortOrder,
        filters,
      }),
    select: res => res.data,
    staleTime: 30 * 1000,
    keepPreviousData: true,
  });
};

export const useCreateWorkflowAppMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: CreateWorkflowAppModelRequest) =>
      workflowApi.createWorkflowAppV1(workspaceId, workflowId, req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workflowAppsKeys.list(workspaceId) });
      queryClient.invalidateQueries({
        queryKey: workflowAppsKeys.appDetailsFromWorkflowId(workspaceId, workflowId),
      });
    },
  });
};

export const useGetWorkflowAppQuery = (appId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: workflowAppsKeys.appDetails(workspaceId, appId),
    queryFn: () => workflowApi.getWorkflowAppDetailsV1(workspaceId, appId),
    select: data => data.data,
  });
};

export const useUpdateWorkflowAppDagMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation({
    mutationFn: (req: UpdateWorkflowAppDAGRequest) =>
      workflowApi.updateWorkflowAppDAGV1(workspaceId, workflowId, req),
  });
};

export const useGetWorkflowAppDetailsFromWorkflowIdQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: workflowAppsKeys.appDetailsFromWorkflowId(workspaceId, workflowId),
    queryFn: () => workflowApi.getWorkflowAppDetailsByWorkflowIdV1(workspaceId, workflowId),
    select: data => data.data,
  });
};

export const useUpdateWorkflowAppMutation = (appId: string, workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: UpdateWorkflowAppModelRequest) =>
      workflowApi.updateWorkflowAppV1(workspaceId, appId, req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workflowAppsKeys.appDetails(workspaceId, appId) });
      queryClient.invalidateQueries({ queryKey: workflowAppsKeys.list(workspaceId) }),
        queryClient.invalidateQueries({
          queryKey: workflowAppsKeys.appDetailsFromWorkflowId(workspaceId, workflowId),
        });
    },
  });
};
