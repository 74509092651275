import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConnectorV2Type } from '@/main/generated/api';
import { connectorsV2Api } from '@/shared/lib/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { connectorsKeys } from '../connectors';

const queryKeys = {
  idpExchange: (workspaceId: string, connectorType: ConnectorV2Type, code: string) => [
    workspaceId,
    connectorType,
    code,
  ],
};

export const useGetConsentUrl = (returnTo = '') => {
  const { workspaceId } = useAppMetadata();

  return useMutation(async (connectorType: ConnectorV2Type) => {
    const response = await connectorsV2Api.getConsentUrlV2(workspaceId, connectorType, returnTo);
    return response.data.consentUrl;
  });
};

export const useExchangeAuthCodeAndCreateConnection = (
  workspaceId: string,
  code: string,
  provider: string,
  connectorType: ConnectorV2Type,
) => {
  const queryClient = useQueryClient();
  const redirectUri = `${window.origin}/oauth/${provider}/${connectorType.toLowerCase()}`;

  return useQuery(
    queryKeys.idpExchange(workspaceId, connectorType, code),
    () =>
      connectorsV2Api.exchangeAuthCodeV2(workspaceId, connectorType, {
        authCode: code,
        connectorType: connectorType,
        connectorName: '',
        redirectUri: redirectUri,
      }),
    {
      select: data => data.data,
      onSuccess: () => {
        queryClient.invalidateQueries(connectorsKeys.listAll(workspaceId));
      },
    },
  );
};
