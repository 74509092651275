import { IconAlertCircle } from '@tabler/icons-react';
import { ReactNode, useMemo, useRef } from 'react';
import { ChartsProvider } from '@/main/charts/context/Context';
import { Alert, CardProps, Horizontal, TextProps } from '@/shared/design-system/v2';
import { PolymorphicComponentProps } from '@/shared/design-system/v2/utils/types';
import { logger } from '@/shared/initializers/logging';
import { ChartTypes } from '../../../../../../charts';
import { ChartData, ChartSeries } from '../../../../../../charts/config/build';
import { getColors } from '../../../../../../charts/utils/colors';
import { VisualizationComponent } from '../../../../../analyser/visualization/Visualization';
import { isStringNullish } from '../util';

const getChartData = (
  rawData: any[],
  xColName: string,
  yColName: string,
): { data?: ChartData; error?: Error } => {
  const seriesData: ChartSeries['data'] = [];
  const categories: string[] = [];

  try {
    rawData.forEach(datum => {
      const rawValue = datum[yColName] ?? '';
      const yValue = isStringNullish(rawValue) ? 0 : parseFloat(rawValue);

      // Ignore non-numeric values
      if (Number.isNaN(yValue)) return;

      const category = datum[xColName] ?? '';
      seriesData.push([category, yValue]);
      categories.push(category);
    });

    return {
      data: {
        categories,
        series: [
          {
            boostThreshold: 500,
            data: seriesData,
          },
        ],
      },
    };
  } catch {
    return {
      error: new Error('Unable to parse'),
    };
  }
};

interface ChartViewProps {
  title: string;
  chartType: ChartTypes;
  rawData: Record<string, unknown>[];
  xColName: string;
  yColName: string;
  xAxisTitle: string;
  yAxisTitle: string;
  cardProps?: Omit<PolymorphicComponentProps<'div', CardProps>, 'children'>;
  titleProps?: TextProps;
  actions?: ReactNode;
  withBorder?: boolean;
}

export const ChartView = ({
  title,
  chartType,
  rawData,
  xColName,
  yColName,
  xAxisTitle,
  yAxisTitle,
  cardProps,
  titleProps,
  actions,
  withBorder = true,
}: ChartViewProps): JSX.Element => {
  const hcRef = useRef(null);
  const xAxisOptions = useMemo(
    () => ({
      title: xAxisTitle,
      labels: { enabled: chartType !== ChartTypes.BAR },
      // Reverse for bar chart to preserve order
      reversed: chartType === ChartTypes.BAR,
    }),
    [xAxisTitle, chartType],
  );

  const yAxisOptions = useMemo(() => ({ title: yAxisTitle }), [yAxisTitle]);

  // getChartData throws an error if unable to parse; in this case, display a message
  const chartData = useMemo(
    () => getChartData(rawData, xColName, yColName),
    [rawData, xColName, yColName],
  );

  if (chartData.error || !chartData.data) {
    logger.error(chartData.error);
    return (
      <Alert color="red" icon={<IconAlertCircle />}>
        Unable to parse chart data. Try using another query.
      </Alert>
    );
  }

  return (
    <ChartsProvider hcRef={hcRef}>
      <VisualizationComponent
        cardProps={{ shadow: '', withBorder, sx: { height: '100%' }, ...cardProps }}
      >
        <VisualizationComponent.Header
          actions={actions}
          containerProps={{ onMouseOver: cardProps?.onMouseOver }}
        >
          <Horizontal position="left">
            <VisualizationComponent.Header.Title title={title} {...titleProps} />
          </Horizontal>
        </VisualizationComponent.Header>
        <VisualizationComponent.Chart
          type={chartType}
          data={chartData.data}
          colors={getColors(rawData.length)}
          xAxisOptions={xAxisOptions}
          yAxisOptions={yAxisOptions}
        />
      </VisualizationComponent>
    </ChartsProvider>
  );
};
