import AppDocViewer, {
  DocViewerRenderers,
  HTMLRenderer,
  IDocument,
  PDFRenderer,
} from '@cyntler/react-doc-viewer';
import { useMemo } from 'react';
import { Alert, Center, Loader } from '@/shared/design-system/v2';
import { useGetDataSourceUrlQuery } from '../../../queries/data-sources';
import { StreamingMediaPreview } from '../../common/uploader/file-preview/StreamingMediaPreview';

const docTheme = { disableThemeScrollbar: true };
const docConfig = {
  header: {
    disableFileName: true,
    disableHeader: true,
  },
};

interface ResourceUrlPreviewProps {
  resourceId: string;
  fileKey?: string;
  fileType?: string;
}
export const getFileTypeFromPresignedUrl = (url: string) => {
  const parts = url.split('.');
  if (parts.length > 1) {
    const extension = parts[parts.length - 1];
    const queryIndex = extension.indexOf('?');
    if (queryIndex > -1) return extension.substring(0, queryIndex);
    return extension;
  }
  return null;
};

const renderers = [PDFRenderer, HTMLRenderer, ...DocViewerRenderers];

export const ResourceUrlPreview = ({ resourceId, fileKey, fileType }: ResourceUrlPreviewProps) => {
  const { isLoading, isError, data: url } = useGetDataSourceUrlQuery(resourceId, fileKey);

  const urlFileType = useMemo(() => {
    if (!url) return null;
    const type = getFileTypeFromPresignedUrl(url);
    return type?.toLowerCase() ?? null;
  }, [url]);

  const docs: IDocument[] = useMemo((): IDocument[] => {
    if (!url) {
      return [];
    }

    return [
      {
        uri: url,
        fileType: urlFileType ?? 'unknown',
      },
    ];
  }, [url, urlFileType]);

  if (isError) {
    return (
      <Center h={600} w="100%">
        <Alert color="red" title="Error">
          Error loading file preview
        </Alert>
      </Center>
    );
  }

  if (isLoading || !url) {
    return (
      <Center h={600} w="100%">
        <Loader text="Loading file preview..." size="md" />
      </Center>
    );
  }

  // Use the file type from URL or fall back to the prop fileType
  const effectiveFileType = urlFileType || (fileType?.toLowerCase() ?? null);

  if (effectiveFileType === 'mp4') {
    return (
      <Center w="100%" h={600} py="xl">
        <StreamingMediaPreview url={url} type="video" />
      </Center>
    );
  }

  if (effectiveFileType === 'mp3' || effectiveFileType === 'wav') {
    return (
      <Center w="100%" h={600} py="xl">
        <StreamingMediaPreview url={url} type="audio" />
      </Center>
    );
  }

  return (
    <AppDocViewer
      style={{ minHeight: '70vh', overflow: 'auto' }}
      documents={docs}
      pluginRenderers={renderers}
      theme={docTheme}
      config={docConfig}
    />
  );
};
