import { useMutation, useQuery } from '@tanstack/react-query';
import { connectorsApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { StorageType } from '../../generated/api';

const queryKeys = {
  idpExchange: (workspaceId: string, code: string) => [workspaceId, code],
};

// Note: Static value, not required in env
const FULL_DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive';
const USER_INFO_SCOPE = 'https://www.googleapis.com/auth/userinfo.email';

export const useGetConsentUrl = () => {
  const { workspaceId } = useAppMetadata();
  return useMutation(async () => {
    const resp = await connectorsApi.consentUrlV1(
      workspaceId,
      'google',
      `${FULL_DRIVE_SCOPE} ${USER_INFO_SCOPE}`,
      '',
      StorageType.GoogleDrive,
    );

    return resp.data.url;
  });
};

export const useIDPExchangeForGoogleOAuth = (workspaceId: string, code: string) =>
  useQuery(
    queryKeys.idpExchange(workspaceId, code),
    () => {
      const redirectUri = `${window.origin}/google/oauth`;
      return connectorsApi.iDPExchangeAuthCodeV2(workspaceId, {
        authCode: code,
        connectorType: StorageType.GoogleDrive,
        connectorName: '',
        redirectUri: redirectUri,
      });
    },
    {
      select: data => data.data,
    },
  );
