import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { audience, clientId, domain, scope } from '@/shared/lib/auth';
import { cleanErrorsInSearchParams } from '@/shared/lib/router';

const SIGN_UP_PATH = '/signup';

export const useAuth = useAuth0;

/**
 * Exposing a hook for login
 */
export const useAuthLogin = () => {
  const { loginWithRedirect } = useAuth0();

  const searchParams = cleanErrorsInSearchParams(window.location.search);

  const appState = {
    // In case of signup, user should get redirected to BASE_ROUTE and not to signup page again
    returnTo: window.location.pathname.includes(SIGN_UP_PATH) ? '/' : window.location.pathname,
    queryParams: searchParams || '',
  };

  const handleLoginRedirect = () => loginWithRedirect({ appState });

  const handleSignupRedirect = (_appState?: RedirectLoginOptions['appState']) =>
    /** Support Sign-up when using Universal Login screens from Auth0 */
    // https://auth0.com/docs/authenticate/login/auth0-universal-login/new-experience#signup
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        prompt: 'login',
      },
      appState: { ...appState, ...(_appState ?? {}) },
    });

  return { handleLoginRedirect, handleSignupRedirect };
};

export const AuthProvider = ({
  children,
  ...rest
}: PropsWithChildren<Partial<Auth0ProviderOptions>>) => {
  const navigate = useNavigate();

  const searchParams = cleanErrorsInSearchParams(window.location.search);

  const onRedirectCallback = (appState?: AppState) => {
    // If we have a redirectPath (e.g. from public app signup), we need to let the Home component
    // handle the routing because it needs to prepend the workspace ID to the path.
    // The final path structure will be: /<workspaceId>/<redirectPath>
    if (appState?.redirectPath) {
      // Replace leading slashes with empty string to avoid double slashes in the path
      const normalizedPath = appState.redirectPath.replace(/^\/+/, '/');
      navigate('/', { state: { redirectPath: normalizedPath } });
      return;
    }

    navigate(
      appState?.returnTo
        ? `${appState?.returnTo}${appState?.queryParams || ''}`
        : `${window.location.pathname}${searchParams || ''}`,
    );
  };

  const providerProps: Auth0ProviderOptions = {
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: `${
        window.location.origin.includes(SIGN_UP_PATH)
          ? window.location.origin.replace(SIGN_UP_PATH, '')
          : window.location.origin
      }${searchParams}`,
      audience,
      scope,
    },
    onRedirectCallback,
    useRefreshTokens: true,
    // When auth0 is not able to fetch the access token using the refresh token,
    // it uses the hidden iframe technique to fetch the access token
    // This is useful when you want to check if the user is logged in or not
    // without having to redirect the user to auth0's page.
    // This will also speed up page load because static assets are fetched only once.
    useRefreshTokensFallback: true,
    // This allows token to be saved in localStorage and speeds up load time when page is refreshed
    // cacheLocation: 'localstorage',
    ...rest,
  };

  return <Auth0Provider {...providerProps}>{children}</Auth0Provider>;
};
