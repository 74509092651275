import { useState } from 'react';
import { useOAuthFlow } from '@/main/components/integrations/oauthUtils';
import { ConnectorV2Type } from '@/main/generated/api';
import { useInvalidateConnectorsList } from '@/main/queries/connectors';
import { Alert, Button, Text, Vertical } from '@/shared/design-system/v2';
import { GongOauthModal } from './GongOauthModal';
import { NotionOauthModal } from './NotionOauthModal';
import { getConnectorTypeDisplayName } from './utils';

interface ConnectorOauthModalProps {
  connectorType: ConnectorV2Type;
}

export const ConnectorOauthModal = ({ connectorType }: ConnectorOauthModalProps) => {
  const [error, setError] = useState('');
  const invalidateConnectorsList = useInvalidateConnectorsList();
  const { handleProviderConsent, isLoading } = useOAuthFlow(
    window.location.pathname + window.location.search,
  );
  if (connectorType === ConnectorV2Type.Notion) {
    return (
      <NotionOauthModal
        handleConnect={() => handleProviderConsent(connectorType)}
        connectorType={connectorType}
        error={error}
      />
    );
  }
  if (connectorType === ConnectorV2Type.Gong) {
    return (
      <GongOauthModal
        handleConnect={() => handleProviderConsent(connectorType)}
        connectorType={connectorType}
        error={error}
      />
    );
  }
  // add upcoming oauth connector modals here

  return (
    <Vertical>
      <Alert color="yellow">
        When you connect your Google account with Markov, everyone your workspace will be able to
        add any file or folder within your account as a source.
      </Alert>
      {error ? (
        <Alert color="red">
          Seems like there was an issue authorizing your account. Please try again!
        </Alert>
      ) : null}
      <Text variant="bodyShort01">
        Connect your {getConnectorTypeDisplayName(connectorType)} account to get started
      </Text>
      <Button onClick={() => handleProviderConsent(connectorType)} variant="primary" w="240px">
        Connect to {getConnectorTypeDisplayName(connectorType)}
      </Button>
    </Vertical>
  );
};
