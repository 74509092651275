import chunk from 'lodash/chunk';
import noop from 'lodash/noop';
import { useState } from 'react';
import { FileWithPath } from '@/shared/design-system/v2/dropzone';
import { PostUrl, Urls } from '../../generated/api';
import { useUploadToS3 } from '../../queries/data-upload';
import { BATCH_SIZE, DataUploadDetails, FileUploadHook, UploadOptions, UploadState } from './types';

/**
 * Hook for managing file uploads with efficient batching and queuing
 */
export const useFileUpload = (): FileUploadHook => {
  const [uploads, setUploads] = useState<DataUploadDetails[]>([]);

  const uploadToS3 = useUploadToS3();

  const updateFileState = (id: string, fileIndex: number, state: UploadState, error?: string) => {
    setUploads(uploads =>
      uploads.map(upload => {
        if (upload.id === id) {
          const updatedFileData = upload.fileData.map((data, index) => {
            if (index === fileIndex) {
              return {
                ...data,
                state,
                error,
              };
            }
            return data;
          });
          return {
            ...upload,
            fileData: updatedFileData,
            // Update overall state based on all files
            state:
              state === UploadState.UPLOAD_ERRORED
                ? UploadState.UPLOAD_ERRORED
                : updatedFileData.every(file => file.state === UploadState.UPLOAD_SUCCESS)
                ? UploadState.UPLOAD_SUCCESS
                : UploadState.UPLOAD_STARTED,
          };
        }
        return upload;
      }),
    );
  };

  const handleRemoveUpload = (id: string) => {
    setUploads(uploads => uploads.filter(upload => upload.id !== id));
  };

  const handleAddUpload = async (id: string, files: File[], uploadOptions: UploadOptions) => {
    const fileBatches: FileWithPath[][] = chunk(files, BATCH_SIZE);

    try {
      // Initialize all files in UPLOAD_STARTED state
      const initialFileData = files.map(file => ({
        file,
        uploadParams: {} as Urls | PostUrl, // Will be populated later
        state: UploadState.UPLOAD_QUEUED,
      }));

      setUploads(uploads => [
        ...uploads,
        {
          id,
          fileData: initialFileData,
          state: UploadState.UPLOAD_STARTED,
          useUploadWindow: uploadOptions.useUploadWindow,
          additionalData: uploadOptions.additionalData,
        },
      ]);

      // Process all batches in parallel
      await Promise.all(
        fileBatches.map(async (batch, batchIndex) => {
          const processedFiles = batchIndex * BATCH_SIZE;

          // Get presigned URLs for this batch
          const presignedUrls = await uploadOptions.fetchPresignedUrls(batch);

          // Update upload data with presigned URLs for this batch
          setUploads(uploads =>
            uploads.map(upload => {
              if (upload.id === id) {
                return {
                  ...upload,
                  fileData: upload.fileData.map((data, index) => {
                    if (index >= processedFiles && index < processedFiles + batch.length) {
                      return {
                        ...data,
                        uploadParams: presignedUrls[index - processedFiles].uploadParams,
                        state: UploadState.UPLOAD_STARTED,
                      };
                    }
                    return data;
                  }),
                };
              }
              return upload;
            }),
          );

          // Upload files in parallel within the batch
          await Promise.all(
            batch.map(async (file, fileIndex) => {
              const globalFileIndex = processedFiles + fileIndex;
              try {
                await uploadToS3(
                  {
                    file,
                    urlResponse: presignedUrls[fileIndex].uploadParams,
                  },
                  uploadOptions.onFileUploadComplete,
                );
                updateFileState(id, globalFileIndex, UploadState.UPLOAD_SUCCESS);
              } catch (error) {
                updateFileState(id, globalFileIndex, UploadState.UPLOAD_ERRORED, 'Upload failed');
              }
            }),
          );
        }),
      );

      // Check if any files failed
      setUploads(uploads => {
        const upload = uploads.find(u => u.id === id);
        if (upload) {
          const hasErrors = upload.fileData.some(file => file.state === UploadState.UPLOAD_ERRORED);
          if (hasErrors) {
            uploadOptions.onError('');
          } else {
            uploadOptions.onSuccess();
          }
        }
        return uploads;
      });
    } catch (error) {
      // If we get here, something failed at the batch level
      setUploads(uploads =>
        uploads.map(upload => {
          if (upload.id === id) {
            return {
              ...upload,
              state: UploadState.UPLOAD_ERRORED,
              fileData: upload.fileData.map(data => ({
                ...data,
                state:
                  data.state === UploadState.UPLOAD_STARTED
                    ? UploadState.UPLOAD_ERRORED
                    : data.state,
                error: error instanceof Error ? error.message : 'Upload failed',
              })),
            };
          }
          return upload;
        }),
      );
      uploadOptions.onError(error instanceof Error ? error.message : 'Upload failed');
    }
  };

  return {
    uploads,
    addUpload: handleAddUpload,
    abortUpload: noop,
    removeUpload: handleRemoveUpload,
  };
};
