import { forwardRef, useEffect, useRef } from 'react';

interface StreamingMediaPreviewProps {
  file?: File;
  url?: string;
  type: 'audio' | 'video';
  width?: string | number;
}

// Constants for file size threshold and chunk size
const FILE_SIZE_THRESHOLD = 100 * 1024 * 1024; // 100MB
const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB

/**
 * Determines the proper MIME codec for the given file and media type.
 */
const getMimeCodec = (file: File, type: 'audio' | 'video'): string => {
  if (type === 'video' && file.type === 'video/mp4') {
    return 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
  }
  if (type === 'audio') {
    switch (file.type) {
      case 'audio/mpeg':
        return 'audio/mpeg';
      case 'audio/wav':
        return 'audio/wav; codecs="1, opus"';
      default:
        throw new Error('Unsupported audio format');
    }
  }
  throw new Error('Unsupported media format');
};

type MediaBaseProps = {
  type: 'video' | 'audio';
};

type VideoProps = MediaBaseProps &
  React.VideoHTMLAttributes<HTMLVideoElement> & {
    type: 'video';
  };

type AudioProps = MediaBaseProps &
  React.AudioHTMLAttributes<HTMLAudioElement> & {
    type: 'audio';
  };

type MediaProps = VideoProps | AudioProps;

const Media = forwardRef<HTMLVideoElement | HTMLAudioElement, MediaProps>(
  ({ type, ...props }, ref) =>
    type === 'video' ? (
      <video ref={ref as React.Ref<HTMLVideoElement>} {...props} />
    ) : (
      <audio ref={ref as React.Ref<HTMLAudioElement>} {...props} />
    ),
);
Media.displayName = 'Media';

export default Media;

export const StreamingMediaPreview = ({
  file,
  url,
  type,
  width = 600,
}: StreamingMediaPreviewProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const mediaSourceRef = useRef<MediaSource | null>(null);

  useEffect(() => {
    // Choose the correct media element based on type
    const mediaElement = type === 'video' ? videoRef.current : audioRef.current;
    if (!mediaElement) return;

    if (url) {
      mediaElement.src = url;
      return;
    }

    if (!file) return;

    /**
     * Utility to set the media element source directly using a Blob URL.
     * Used for small files or as a fallback.
     */
    const handleSourceError = () => {
      mediaElement.src = URL.createObjectURL(file);
    };
    const fallbackDirectUrl = () => {
      handleSourceError();
      return () => {
        URL.revokeObjectURL(mediaElement.src);
      };
    };

    // For small files, simply create an object URL and return early
    if (file.size <= FILE_SIZE_THRESHOLD) {
      return fallbackDirectUrl();
    }

    let mimeCodec: string;
    try {
      mimeCodec = getMimeCodec(file, type);
    } catch (err) {
      return fallbackDirectUrl();
    }

    // If the codec isn't supported, fallback to direct URL
    if (!MediaSource.isTypeSupported(mimeCodec)) {
      return fallbackDirectUrl();
    }

    // Set up MediaSource for streaming large files
    const mediaSource = new MediaSource();
    mediaSourceRef.current = mediaSource;
    mediaElement.src = URL.createObjectURL(mediaSource);

    mediaSource.addEventListener('sourceopen', function handleSourceOpen() {
      try {
        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
        sourceBuffer.addEventListener('error', handleSourceError);

        let offset = 0;

        /**
         * Recursively appends file chunks to the source buffer.
         * Once all chunks are appended, finalizes the stream and plays the media.
         */
        const appendNextChunk = async () => {
          if (offset < file.size) {
            const chunk = file.slice(offset, offset + CHUNK_SIZE);
            const buffer = await chunk.arrayBuffer();

            // Wait if the sourceBuffer is busy
            if (sourceBuffer.updating) {
              await new Promise(resolve =>
                sourceBuffer.addEventListener('updateend', resolve, { once: true }),
              );
            }
            if (mediaSource.readyState === 'open') {
              sourceBuffer.appendBuffer(buffer);
            }
            offset += buffer.byteLength;

            // Schedule the next chunk immediately
            setTimeout(appendNextChunk, 0);
          } else {
            // Ensure the final update is complete before ending the stream
            if (sourceBuffer.updating) {
              await new Promise(resolve =>
                sourceBuffer.addEventListener('updateend', resolve, { once: true }),
              );
            }
            if (mediaSource.readyState === 'open') {
              mediaSource.endOfStream();
            }
          }
        };

        appendNextChunk();
      } catch (err) {
        handleSourceError();
      }
    });

    // Cleanup on unmount: end stream and revoke the object URL
    return () => {
      if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
        mediaSourceRef.current.endOfStream();
      }
      URL.revokeObjectURL(mediaElement.src);
      mediaElement.src = '';
    };
  }, [file, type, url]);

  const mediaRef = type === 'video' ? videoRef : audioRef;
  return (
    <div>
      <Media type={type} ref={mediaRef} controls width={width}>
        Your browser does not support the element.
      </Media>
    </div>
  );
};
