import { ICellRendererParams } from '@ag-grid-community/core';
import { IconEye, IconLoader } from '@tabler/icons-react';
import get from 'lodash/get';
import {
  Button,
  Center,
  Horizontal,
  MODAL_LEVEL_1_Z_INDEX,
  Modal,
  Text,
  useDisclosure,
} from '@/shared/design-system/v2';
import {
  AirbyteJobStatus,
  DataResourceType,
  ResourceModel,
  StorageType,
} from '../../../generated/api';
import { useGetAirbyteJobStatusQuery } from '../../../queries/connectors';
import { isAirbyteStorageType } from '../../connectors/util';
import { FolderPreview } from '../modals/FolderPreview';
import { useFolderFilePreview } from '../modals/use-folder-file-preview';

export const PreviewRenderer = ({
  value: resourceId,
  data: resource,
}: ICellRendererParams<ResourceModel, string>) => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  const { data: jobStatusData } = isAirbyteStorageType(resource?.connectorType ?? StorageType.S3)
    ? useGetAirbyteJobStatusQuery(resourceId, { enabled: Boolean(resourceId) })
    : { data: null };

  const isPreviewDisabled = isAirbyteStorageType(resource?.connectorType ?? StorageType.S3)
    ? jobStatusData?.jobStatus !== AirbyteJobStatus.Succeeded
    : false;
  const isFolderResource = resource?.resourceType === DataResourceType.Folder;

  const handleClick = (event: any) => {
    event.preventDefault();
    openModal();
  };

  const openFolderFilePreview = useFolderFilePreview();

  if (isFolderResource) {
    return (
      <>
        <Horizontal h="100%" align="center">
          <Button leftIcon={<IconEye />} onClick={handleClick}>
            View files
          </Button>
        </Horizontal>
        <Modal
          centered
          opened={modalOpened}
          onClose={closeModal}
          size="90vw"
          title={<Text variant="subTitle01">Resource details</Text>}
          zIndex={MODAL_LEVEL_1_Z_INDEX}
        >
          <FolderPreview resourceId={resourceId} />
        </Modal>
      </>
    );
  }

  const isDataSink = resource?.isDataSink ?? true;

  if (isDataSink) {
    return (
      <Center h="100%">
        <Button
          leftIcon={<IconEye />}
          disabled
          // Removing the border to have same look and feel as the enabled state
          sx={{
            ':disabled': {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          Preview
        </Button>
      </Center>
    );
  }

  const handlePreviewClick = () => {
    const fileName = get(resource, 'resourceMetadata.originalName', 'Resource preview');
    openFolderFilePreview(resourceId, fileName, resource?.resourceType ?? '', '');
  };

  return (
    <Center h="100%">
      {isPreviewDisabled ? (
        <Button
          disabled
          sx={{
            color: 'gray.7',
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            ':disabled': {
              color: 'gray.7',
            },
          }}
          leftIcon={<IconLoader />}
        >
          Syncing...
        </Button>
      ) : (
        <Button leftIcon={<IconEye />} onClick={handlePreviewClick}>
          Preview
        </Button>
      )}
    </Center>
  );
};
