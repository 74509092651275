import {
  ChatterRoutesApiFactory,
  ContentAddonsRoutesApiFactory,
  DashboardAndWidgetAPIsApiFactory,
  DataAnalyticsPublicRoutesApiFactory,
  DataAnalyticsRoutesApiFactory,
  DatasetAnalyticsRoutesApiFactory,
} from '@/main/generated/api';
import { apiConfiguration } from './api';

export const chatterApi = ChatterRoutesApiFactory(apiConfiguration);

export const contentAddOnApi = ContentAddonsRoutesApiFactory(apiConfiguration);

export const dataAnalyticsApi = DataAnalyticsRoutesApiFactory(apiConfiguration);

export const datasetAnalyticsApi = DatasetAnalyticsRoutesApiFactory(apiConfiguration);

export const dataAnalyticsPublicApi = DataAnalyticsPublicRoutesApiFactory(apiConfiguration);

export const dashboardAndWidgetApi = DashboardAndWidgetAPIsApiFactory(apiConfiguration);
