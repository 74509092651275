import AppDocViewer, { IDocument, PDFRenderer } from '@cyntler/react-doc-viewer';
import first from 'lodash/first';
import last from 'lodash/last';
import { useEffect, useMemo, useState } from 'react';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { useAuth } from '@/main/contexts/auth-provider/Auth';
import { Box } from '@/shared/design-system/v2';
import { getAPIBaseUrl } from '@/shared/lib/env.util';

const pluginRenderers = [PDFRenderer];
const docTheme = { disableThemeScrollbar: true };
const docConfig = {
  header: {
    disableFileName: true,
    disableHeader: true,
  },
};

interface PDFPreviewProps {
  url: string;
  workflowId: string;
}

export const PDFPreview = ({ url, workflowId }: PDFPreviewProps) => {
  const { workspaceId, userId } = useAppMetadata();
  const { getAccessTokenSilently } = useAuth();
  const [token, setToken] = useState('');

  const docs: IDocument[] = useMemo((): IDocument[] => {
    const urlSplit = url.split('.');
    const fileType = last(urlSplit);
    const fileName = last(url.split('/'));

    return [
      {
        uri: `${getAPIBaseUrl()}/vienna/v1/workspace/${workspaceId}/file-preview?workflow_id=${workflowId}&file_path=${url}`,
        fileType,
        fileName: fileName,
      },
    ];
  }, [url]);

  useEffect(() => {
    getAccessTokenSilently().then(t => {
      setToken(t);
    });
  }, [url]);

  const firstDocs = first(docs);
  if (!firstDocs?.uri || !token) {
    return <Box>Generating URL</Box>;
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    'member-id': userId,
  };

  return (
    <AppDocViewer
      style={{ height: '70vh' }}
      documents={docs}
      pluginRenderers={pluginRenderers}
      theme={docTheme}
      config={docConfig}
      requestHeaders={headers}
    />
  );
};
