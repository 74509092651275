import { ColDef, ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import noop from 'lodash/noop';
import { Horizontal } from '@/shared/design-system/v2';
import { List, ListVersions } from '@/shared/design-system/v2/core/data-display/table';
import { formatDateTime } from '@/shared/lib/ui';
import { ResourceModel } from '../../generated/api';
import { UserInfoContainer } from '../model-apps/UserInfo.container';
import { LocationRenderer } from './cell-renderers/LocationRenderer';
import { NameRenderer } from './cell-renderers/NameRenderer';
import { PreviewRenderer } from './cell-renderers/PreviewRenderer';
import { TypeRenderer } from './cell-renderers/TypeRenderer';

export const PREVIEW_COL_ID = 'preview';

export const getDataSourceColumns = (
  isDataSink?: boolean,
  // Should make columns flexible
  initialColWidth?: Record<string, number>,
): ColDef<ResourceModel>[] => {
  const columns: ColDef<ResourceModel>[] = [
    {
      colId: 'name',
      field: 'resourceMetadata',
      headerName: 'Data Source',
      sortable: false,
      cellRenderer: NameRenderer,
      minWidth: 260,
      initialWidth: initialColWidth?.name ?? 260,
      flex: 1,
    },
    {
      colId: 'location',
      field: 'resourceMetadata',
      headerName: 'Location',
      sortable: false,
      cellRenderer: LocationRenderer,
      width: initialColWidth?.location ?? 120,
      minWidth: 120,
    },
    {
      colId: 'connectorType',
      field: 'connectorType',
      headerName: 'Connection',
      sortable: false,
      cellRenderer: TypeRenderer,
      width: initialColWidth?.connectorType ?? 300,
    },
    {
      colId: 'createdBy',
      field: 'userId',
      headerName: 'Created by',
      sortable: false,
      cellRenderer: ({ value: userId }: ICellRendererParams<ResourceModel, string>) => (
        <Horizontal h="100%" align="center">
          <UserInfoContainer userId={userId} />
        </Horizontal>
      ),
      width: 200,
    },
    {
      colId: 'createDate',
      field: 'createDate',
      headerName: 'Created on',
      sortable: false,
      cellRenderer: ({ value: createDate }: ICellRendererParams<ResourceModel, string>) =>
        formatDateTime(createDate),
      width: 200,
    },
  ];

  if (!isDataSink) {
    columns.push({
      colId: PREVIEW_COL_ID,
      field: 'resourceId',
      headerName: '',
      sortable: false,
      resizable: false,
      cellRenderer: PreviewRenderer,
      width: initialColWidth?.preview ?? 150,
      pinned: 'right',
    });
  }

  // columns.push({
  //   colId: 'actions',
  //   headerName: '',
  //   field: 'actions',
  //   sortable: false,
  //   resizable: false,
  //   cellRenderer: ActionsRenderer,
  //   cellRendererParams: (params: ICellRendererParams) => ({
  //     data: params.data,
  //   }),
  //   width: 60,
  //   pinned: 'right',
  // });

  return columns;
};

interface DataSourcesTableProps {
  gridRef?: React.RefObject<AgGridReact<ResourceModel>>;
  rows: ResourceModel[];
  onRowClicked?: (e: RowClickedEvent<ResourceModel>) => void;
  pagination?: boolean;
  paginationPageSize?: number;
  totalRows?: number;
  currentPage?: number;
  onPageNumberChange?: (page: number) => void;
  leftActionsContent?: JSX.Element;
  initialColWidth?: Record<string, number>;
}

export const DataSourcesTable = ({
  gridRef,
  rows,
  onRowClicked = noop,
  pagination = false,
  paginationPageSize,
  totalRows = 0,
  currentPage = 0,
  onPageNumberChange = noop,
  leftActionsContent,
  initialColWidth,
}: DataSourcesTableProps): JSX.Element => (
  <List<ResourceModel>
    version={ListVersions.v2}
    gridRef={gridRef}
    columns={getDataSourceColumns(false, initialColWidth)}
    rowData={rows}
    onRowClicked={onRowClicked}
    paginationProps={{
      totalRows: totalRows,
      currentPage: currentPage,
      onPageNumberChange: onPageNumberChange,
      labelRowsPerPage: 'Total data sources',
      showPaginationLabel: pagination,
    }}
    paginationPageSize={paginationPageSize}
    leftActionsContent={leftActionsContent}
  />
);
