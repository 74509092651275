import {
  ApacheSupersetAPIsApiFactory,
  ClusteringRoutesApiFactory,
  CommentsRoutesApiFactory,
  ConnectorRoutesApiFactory,
  ConnectorV2RoutesApiFactory,
  DatasetAnalysesRoutesApiFactory,
  DatasetOperationRoutesApiFactory,
  DatasetOperationRulesRoutesApiFactory,
  DatasetV2APIsApiFactory,
  DemoArtifactOperationRoutesApiFactory,
  FullDataAnalysesRoutesApiFactory,
  ManualRelabelingRoutesApiFactory,
  OperatorsAPIsApiFactory,
  PublicRoutesApiFactory,
  PublicSearchAppAPIsApiFactory,
  PublicWorkflowAppsAPIsApiFactory,
  RuleBasedRelabelingRoutesApiFactory,
  SearchAppCopilotChatAPIsApiFactory,
  SearchAppInWorkflowAPIsApiFactory,
  SnippetCommentsApiFactory,
  SnippetCommentsRouteApiFactory,
  SnippetRoutesApiFactory,
  SnippetShareRoutesApiFactory,
  SubsetAnalysesRoutesApiFactory,
  SubsetRelabelingRoutesApiFactory,
  WorkflowsAPIsApiFactory,
  WorkspaceCredentialsRoutesApiFactory,
  WorkspaceDatasetRoutesApiFactory,
} from '@/main/generated/api';
import { apiConfiguration } from './api';

export const datasetApi = WorkspaceDatasetRoutesApiFactory(apiConfiguration);

export const datasetV2Api = DatasetV2APIsApiFactory(apiConfiguration);

export const datasetOperationApi = DatasetOperationRoutesApiFactory(apiConfiguration);

export const datasetOperationRulesApi = DatasetOperationRulesRoutesApiFactory(apiConfiguration);

export const manualRelabelingApi = ManualRelabelingRoutesApiFactory(apiConfiguration);

export const ruleBasedRelabelingApi = RuleBasedRelabelingRoutesApiFactory(apiConfiguration);

export const subsetRelabelingApi = SubsetRelabelingRoutesApiFactory(apiConfiguration);

export const datasetAnalysisApi = DatasetAnalysesRoutesApiFactory(apiConfiguration);

export const fullDataAnalysisApi = FullDataAnalysesRoutesApiFactory(apiConfiguration);

export const subsetAnalysesRoutesApi = SubsetAnalysesRoutesApiFactory(apiConfiguration);

export const credentialsApi = WorkspaceCredentialsRoutesApiFactory(apiConfiguration);

export const snippetsApi = SnippetRoutesApiFactory(apiConfiguration);

export const snippetsShareApi = SnippetShareRoutesApiFactory(apiConfiguration);

export const publicApis = PublicRoutesApiFactory(apiConfiguration);

// These requests are aggregated on Mumbai
export const snippetCommentsApi = SnippetCommentsApiFactory(apiConfiguration);

// These request route through proxy server
export const commentsMutationApi = SnippetCommentsRouteApiFactory(apiConfiguration);

// Resource-agnostic routes for managing comments
export const commentsApi = CommentsRoutesApiFactory(apiConfiguration);

export const embeddingsApi = ClusteringRoutesApiFactory(apiConfiguration);

export const workspaceSetupApi = DemoArtifactOperationRoutesApiFactory(apiConfiguration);

export const workflowApi = WorkflowsAPIsApiFactory(apiConfiguration);

export const operatorsApi = OperatorsAPIsApiFactory(apiConfiguration);

export const publicWorkflowApi = PublicWorkflowAppsAPIsApiFactory(apiConfiguration);

export const connectorsApi = ConnectorRoutesApiFactory(apiConfiguration);

export const connectorsV2Api = ConnectorV2RoutesApiFactory(apiConfiguration);

export const searchAppApi = SearchAppInWorkflowAPIsApiFactory(apiConfiguration);

export const searchAppChatApi = SearchAppCopilotChatAPIsApiFactory(apiConfiguration);

export const publicSearchAppApi = PublicSearchAppAPIsApiFactory(apiConfiguration);

export const supersetApi = ApacheSupersetAPIsApiFactory(apiConfiguration);
