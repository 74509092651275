import { FlexProps } from '@mantine/core';
import { Sx } from '@mantine/styles';
import { PropsWithChildren, ReactNode } from 'react';
import { Box, Flex, Horizontal } from '@/shared/design-system/v2';
import { Title } from './Title';

interface HeaderProps {
  actions?: ReactNode;
  sx?: Sx;
  containerProps?: FlexProps & React.RefAttributes<HTMLDivElement>;
}

export const Header = ({ actions, children, containerProps }: PropsWithChildren<HeaderProps>) => (
  <Flex {...containerProps}>
    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>{children}</Box>
    <Box ml="auto" sx={{ flexShrink: 0 }} onClick={e => e.preventDefault()}>
      {actions && (
        <Horizontal noWrap px={0} spacing="xs">
          {actions}
        </Horizontal>
      )}
    </Box>
  </Flex>
);

Header.Title = Title;
