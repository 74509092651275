import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { HTTPError } from '@/shared/lib/api/api';
import { chatterApi, dataAnalyticsApi, dataAnalyticsPublicApi } from '@/shared/lib/api/oslo';
import { StorageMetadataModel } from '../components/data-sources/cell-renderers/config';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  BaseAPIFilter,
  ChatterRequestModel,
  CreateDataAnalyticsRequestModel,
  DataAnalyticsDetailsResponseModel,
  DataAnalyticsLeafResourcesResponseModel,
  DataSourceConversationModel,
  SortOrder,
  UpdateDataAnalyticsArtifactStateRequestModel,
} from '../generated/api';
import {
  ChatResourceTypes,
  onErrorChatWithData,
  onMutateChatWithData,
  onSuccessChatWithData,
} from './chat-with-data/chat';
import { chatWithDataKeys } from './queryConstants';

const NUMBER_OF_ROWS_TO_PREVIEW = 25;
const NUMBER_OF_FOLLOW_UP_QUESTIONS = 4;

export const dataAnalyticsKeys = {
  all: ['data-analytics'] as const,
  details: (workspaceId: string, resourceId: string) =>
    [...dataAnalyticsKeys.all, 'details', workspaceId, resourceId] as const,
  listFinalResources: (workspaceId: string, resourceId?: string) => [
    ...dataAnalyticsKeys.all,
    'list-final-resources',
    workspaceId,
    resourceId,
  ],
  resourcePreview: (
    workspaceId: string,
    resourceId: string,
    storageMetadataModel: StorageMetadataModel,
    numRows?: number,
  ) => [
    ...dataAnalyticsKeys.all,
    'resource-preview',
    workspaceId,
    resourceId,
    storageMetadataModel,
    numRows,
  ],
  query: (workspaceId: string, resourceId: string, threadId: string) => [
    ...dataAnalyticsKeys.all,
    'query',
    workspaceId,
    resourceId,
    threadId,
  ],
  listAll: (workspaceId: string) => [...dataAnalyticsKeys.all, 'list', workspaceId],
  list: (
    workspaceId: string,
    filters?: object[],
    start?: number,
    end?: number,
    sortKey?: string,
    sortOrder?: SortOrder,
  ) =>
    [...dataAnalyticsKeys.listAll(workspaceId), start, end, sortKey, sortOrder, filters] as const,
  followUpQuestions: (
    workspaceId: string,
    resourceId: string,
    previousQuestion: string,
    numberOfQuestions: number,
  ) => [
    ...dataAnalyticsKeys.all,
    'follow-up-questions',
    workspaceId,
    resourceId,
    previousQuestion,
    numberOfQuestions,
  ],
  followUpQuestionsV2: (
    workspaceId: string,
    resourceId: string,
    previousQuestion: string,
    numberOfQuestions: number,
  ) => [
    ...dataAnalyticsKeys.all,
    'follow-up-questions-v2',
    workspaceId,
    resourceId,
    previousQuestion,
    numberOfQuestions,
  ],
};

export const useGetDataAnalyticsDetailsQuery = (resourceId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DataAnalyticsDetailsResponseModel>,
    AxiosError,
    DataAnalyticsDetailsResponseModel
  >(
    dataAnalyticsKeys.details(workspaceId, resourceId),
    () => dataAnalyticsApi.getDataAnalyticsDetailsV1(workspaceId, resourceId),
    {
      enabled: Boolean(workspaceId && resourceId),
      select: res => res.data,
    },
  );
};

export const useAskDataSourceMutation = (resourceId: string, threadId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  const resourceType = ChatResourceTypes.DATA_ANALYTICS;

  return useMutation<
    AxiosResponse<DataSourceConversationModel, unknown>,
    AxiosError<HTTPError>,
    ChatterRequestModel
  >(
    chatWithDataKeys.query(workspaceId, resourceId, threadId, resourceType),
    (askMarkovRequestModel: ChatterRequestModel) =>
      chatterApi.askMarkovForDataSourceV1(workspaceId, resourceId, askMarkovRequestModel),
    {
      onMutate: onMutateChatWithData(queryClient, workspaceId, resourceId, threadId, resourceType),
      onSuccess: onSuccessChatWithData(
        queryClient,
        workspaceId,
        resourceId,
        threadId,
        resourceType,
      ),
      onError: onErrorChatWithData(queryClient, workspaceId, resourceId, threadId, resourceType),
    },
  );
};

export const useDataAnalyticsListLeafResourcesQuery = (resourceId?: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DataAnalyticsLeafResourcesResponseModel>,
    AxiosError,
    DataAnalyticsLeafResourcesResponseModel
  >(
    dataAnalyticsKeys.listFinalResources(workspaceId, resourceId),
    () => dataAnalyticsApi.listDataAnalyticsLeafResourcesV1(workspaceId, resourceId ?? ''),
    {
      enabled: Boolean(workspaceId && resourceId),
      select: res => res.data,
    },
  );
};

export const useDataAnalyticsGetResourcePreviewQuery = (
  resourceId: string,
  storageMetadataModel: StorageMetadataModel,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    dataAnalyticsKeys.resourcePreview(workspaceId, resourceId, storageMetadataModel),
    () =>
      dataAnalyticsApi.getDataAnalyticsResourcePreviewV1(
        workspaceId,
        resourceId,
        storageMetadataModel,
        NUMBER_OF_ROWS_TO_PREVIEW,
      ),
    {
      // storageMetadataModel may be undefined; query should be disabled this case
      enabled: Boolean(workspaceId && resourceId && storageMetadataModel),
      select: res => res.data,
    },
  );
};

export const useIsNewConversationMutating = (resourceId: string, threadId: string) => {
  const { workspaceId } = useAppMetadata();

  return useIsMutating({
    mutationKey: dataAnalyticsKeys.query(workspaceId, resourceId, threadId),
  });
};

export const useCreateDataAnalyticsMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreateDataAnalyticsRequestModel) =>
      dataAnalyticsApi.createDataAnalyticsV1(workspaceId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: dataAnalyticsKeys.listAll(workspaceId),
        });
      },
    },
  );
};

export const useListDataAnalyticsQueryV2 = ({
  filters = [],
  sortKey = 'createDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
}: {
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    dataAnalyticsKeys.list(workspaceId, filters, start, limit, sortKey, sortOrder),
    () =>
      dataAnalyticsApi.listDataAnalyticsV2(workspaceId, {
        start,
        limit,
        sortKey,
        sortOrder,
        filters,
      }),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
    },
  );
};

export const useUpdateDataAnalyticsStateMutation = () => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (req: UpdateDataAnalyticsArtifactStateRequestModel) =>
      dataAnalyticsApi.updateDataAnalyticsArtifactStateV1(workspaceId, req),
    {
      onSuccess: () => queryClient.invalidateQueries(dataAnalyticsKeys.listAll(workspaceId)),
    },
  );
};

export const useListDataAnalyticsFollowUpQuestions = (
  resourceId: string,
  previousQuestion = '',
  numberOfQuestions = NUMBER_OF_FOLLOW_UP_QUESTIONS,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    dataAnalyticsKeys.followUpQuestions(
      workspaceId,
      resourceId,
      previousQuestion,
      numberOfQuestions,
    ),
    () =>
      dataAnalyticsApi.getDataAnalyticsFollowUpQuestionsV1(
        workspaceId,
        resourceId,
        previousQuestion,
        numberOfQuestions,
      ),
    {
      enabled: Boolean(workspaceId && resourceId && numberOfQuestions),
      select: res => res.data,
    },
  );
};

export const useListDataAnalyticsFollowUpQuestionsV2 = (
  resourceId: string,
  previousQuestion = '',
  numberOfQuestions = NUMBER_OF_FOLLOW_UP_QUESTIONS,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    dataAnalyticsKeys.followUpQuestionsV2(
      workspaceId,
      resourceId,
      previousQuestion,
      numberOfQuestions,
    ),
    () =>
      dataAnalyticsApi.getDataAnalyticsFollowUpQuestionsV2(
        workspaceId,
        resourceId,
        previousQuestion,
        numberOfQuestions,
      ),
    {
      enabled: Boolean(workspaceId && resourceId && numberOfQuestions),
      select: res => res.data,
    },
  );
};

export const useDataAnalyticsGetAISuggestedFixMutation = (resourceId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((conversationId: string) =>
    dataAnalyticsApi.getDataAnalyticsAISuggestedFixV1(conversationId, workspaceId, resourceId),
  );
};

export const useEditGeneratedSqlMutation = (
  resourceId: string,
  threadId: string,
  conversationId: string,
) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation({
    mutationFn: (editedSqlQuery: string) =>
      chatterApi.editGeneratedSQLV1(workspaceId, conversationId, { editedSqlQuery }),

    onMutate: (editedSqlQuery: string) => {
      const conversationsKey = chatWithDataKeys.threadConversations(
        workspaceId,
        threadId,
        ChatResourceTypes.DATA_ANALYTICS,
      );

      const conversationsData = queryClient.getQueryData(conversationsKey) as {
        pages: Array<{
          data: {
            response: DataSourceConversationModel[];
          };
        }>;
        pageParams: any;
      };

      const lastConversation = conversationsData.pages[0].data.response[0];
      const updatedConversation: DataSourceConversationModel = {
        ...lastConversation,
        answer: editedSqlQuery,
        isAnswerEdited: true,
      };

      const updatedConversationsData = {
        ...conversationsData,
        pages: [
          {
            data: {
              response: [updatedConversation, ...conversationsData.pages[0].data.response.slice(1)],
            },
          },
          ...conversationsData.pages.slice(1),
        ],
      };

      queryClient.setQueryData(conversationsKey, updatedConversationsData);
    },

    onError: () => {
      const conversationsKey = chatWithDataKeys.threadConversations(
        workspaceId,
        threadId,
        ChatResourceTypes.DATA_ANALYTICS,
      );

      queryClient.invalidateQueries(conversationsKey);
    },

    onSuccess: () => {
      const conversationsKey = chatWithDataKeys.threadConversations(
        workspaceId,
        threadId,
        ChatResourceTypes.DATA_ANALYTICS,
      );
      queryClient.invalidateQueries(conversationsKey);
    },
  });
};

export const useChatWithDataSourcePublicMutation = () =>
  useMutation({
    mutationFn: (question: string) =>
      dataAnalyticsPublicApi.dataAnalyticsChatPublicV1({ question }),
  });

export const useChatWithVisualizationMutation = (resourceId: string, threadId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  const resourceType = ChatResourceTypes.WIDGET;

  return useMutation<
    AxiosResponse<DataSourceConversationModel, unknown>,
    AxiosError<HTTPError>,
    ChatterRequestModel
  >(
    chatWithDataKeys.query(workspaceId, resourceId, threadId, resourceType),
    (askMarkovRequestModel: ChatterRequestModel) =>
      dataAnalyticsApi.chatWithWidgetV1(workspaceId, resourceId, askMarkovRequestModel),
    {
      onMutate: onMutateChatWithData(queryClient, workspaceId, resourceId, threadId, resourceType),
      onSuccess: onSuccessChatWithData(
        queryClient,
        workspaceId,
        resourceId,
        threadId,
        resourceType,
      ),
      onError: onErrorChatWithData(queryClient, workspaceId, resourceId, threadId, resourceType),
    },
  );
};
