import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { FileUploadContextProps, UploadState } from './types';
import { useFileUpload } from './useFileUpload';

const FileUploadContext = createContext<FileUploadContextProps | undefined>(undefined);

export function FileUploadProvider({ children }: PropsWithChildren<unknown>) {
  const { uploads, addUpload, abortUpload, removeUpload } = useFileUpload();

  const windowUploads = useMemo(() => uploads.filter(upload => upload.useUploadWindow), [uploads]);

  // Handle browser tab close warning
  useEffect(() => {
    const handleBeforeUnload = (evt: BeforeUnloadEvent) => {
      if (
        uploads.some(upload =>
          upload.fileData.some(
            file =>
              file.state === UploadState.UPLOAD_STARTED || file.state === UploadState.UPLOAD_QUEUED,
          ),
        )
      ) {
        evt.preventDefault();
        return (evt.returnValue = 'Files are being uploaded. Are you sure you want to leave?');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [uploads]);

  const contextValue = {
    uploads,
    addUpload,
    abortUpload,
    removeUpload,
    windowUploads,
  };

  return <FileUploadContext.Provider value={contextValue}>{children}</FileUploadContext.Provider>;
}

/**
 * Hook to access the file upload context
 */
export function useDataUpload() {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error('useFileUploadProvider must be used within a FileUploadProvider');
  }
  return context;
}
