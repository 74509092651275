import { useOAuthFlow } from '@/main/components/integrations/oauthUtils';
import { AsyncDropDownResources, ConnectorV2Type } from '@/main/generated/api';
import { useListConnectionsByTypeQuery } from '@/main/queries/connectors/v2/connectors';
import { Alert, Center, Loader, Text } from '@/shared/design-system/v2';
import { ConnectorOauthModal } from '../../../../connectors/modals/connectors-v2/ConnectorOauthModal';
import { getConnectorTypeDisplayName } from '../../../../connectors/modals/connectors-v2/utils';
import { ResourceSelect } from '../../../resource-lookup/resource-selection/ResourceSelect';

interface OauthAccountsSelectorProps {
  value: string;
  onChange: (value: string) => void;
  connectorType: ConnectorV2Type;
}

const ADD_NEW_OPTION_VALUE = 'add_new';

interface LoadingStateProps {
  message: string;
}

const LoadingState = ({ message }: LoadingStateProps) => (
  <Center py="lg">
    <Loader size="xl" variant="dots" />
    <Text variant="subTitle03">{message}</Text>
  </Center>
);

export const OauthAccountsSelectorContainer = ({
  value,
  onChange,
  connectorType,
}: OauthAccountsSelectorProps) => {
  const { handleProviderConsent, isLoading: isGetConsentUrlLoading } = useOAuthFlow(
    window.location.pathname + window.location.search,
  );
  const {
    isInitialLoading: isListConnectionsInitialLoading,
    isError: isListConnectionError,
    data: connectorAccounts,
  } = useListConnectionsByTypeQuery(connectorType);

  if (isListConnectionsInitialLoading) {
    return <LoadingState message="Fetching accounts..." />;
  }

  if (isGetConsentUrlLoading) {
    return (
      <LoadingState message={`Redirecting to ${getConnectorTypeDisplayName(connectorType)}...`} />
    );
  }

  if (isListConnectionError) {
    return (
      <Center py="lg">
        <Alert color="red">Failed to fetch accounts</Alert>
      </Center>
    );
  }

  const handleResourceSelection = (selectedValue: string) => {
    if (selectedValue === ADD_NEW_OPTION_VALUE) {
      handleProviderConsent(connectorType);
      return;
    }
    onChange(selectedValue);
  };

  if (!connectorAccounts || connectorAccounts?.length === 0) {
    return <ConnectorOauthModal connectorType={connectorType} />;
  }

  const resources = connectorAccounts.map(account => ({
    label: account.name,
    value: account.connectionId,
  }));

  const dropdownResources = [{ label: '+ Add New', value: ADD_NEW_OPTION_VALUE }, ...resources];

  return (
    <ResourceSelect
      resourceKey={AsyncDropDownResources.ConnectedAccounts}
      resources={dropdownResources}
      value={value}
      placeholder="Select account"
      ariaLabel={' '} // FIX IT
      onChange={handleResourceSelection}
      size="lg"
      h={60}
      fz={30}
      radius="sm"
    />
  );
};
