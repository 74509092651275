import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { AppRoutes, getRoute } from './constants';

// Hook to provide absolute routes which can be used from any page in the app. This encapsulates
// retrieving the active workspace and centralizes routing logic to simplify usage in components.
export const useAbsoluteRoutes = () => {
  const { workspaceId } = useAppMetadata();
  const settings = getRoute(AppRoutes.SETTINGS);

  const copyEditDetailsRoutes = {
    versionHistory: getRoute(AppRoutes.COPY_EDIT_VERSION_HISTORY),
    reviewMode: getRoute(AppRoutes.COPY_EDIT_REVIEW_MODE),
  };

  const withWorkspace = (restPath: string) => `/${workspaceId}/${restPath}`;
  return {
    getWorkspaceRoute: (wspId: string = workspaceId) => `/${wspId}`,
    getCopyEditProjectRoute: (projectId: string) => withWorkspace(`project/${projectId}`),
    getCopyEditDetailRoute: (documentId: string) => withWorkspace(`document/${documentId}`),
    getCopyEditVersionHistoryRoute: (documentId: string) =>
      withWorkspace(`document/${documentId}/${copyEditDetailsRoutes.versionHistory}`),
    getCopyEditReviewModeRoute: (documentId: string) =>
      withWorkspace(`document/${documentId}/${copyEditDetailsRoutes.reviewMode}`),
    getSettingsRoute: <T extends string>(tabName?: T) =>
      withWorkspace(`${settings}?tab=${tabName ?? ''}`),
    getCopyEditSettingsRoute: () => withWorkspace(getRoute(AppRoutes.COPY_EDIT_SETTINGS)),
  };
};
