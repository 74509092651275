import { ChatConversationViewModel } from '@/main/components/common/chat-with-data/chat/conversation/utils';
import { ChartType, DataSourceConversationModel, WidgetType } from '@/main/generated/api';
import { Vertical } from '@/shared/design-system/v2';
import { AnswerViewContainer } from './AnswerView.container';
import { QuestionView } from './QuestionView';

interface ConversationViewProps {
  resourceId: string;
  conversation: ChatConversationViewModel;
  onExecuteSql?: (sqlQuery: string) => void;
  onAddVizToDashboard?: (
    answer: DataSourceConversationModel,
    vizType?: WidgetType,
    chartType?: ChartType,
  ) => void;
  isExecuteDisabled?: boolean;
  isLatestConversation: boolean;
  loadingVizInsert?: boolean;
  onAddToWidget?: (val: string) => void;
}

export const ConversationView = ({
  resourceId,
  conversation,
  onExecuteSql,
  onAddVizToDashboard,
  isExecuteDisabled,
  isLatestConversation,
  loadingVizInsert,
  onAddToWidget,
}: ConversationViewProps) => (
  <Vertical w="100%" spacing="xl">
    <QuestionView conversation={conversation} />
    <AnswerViewContainer
      resourceId={resourceId}
      conversation={conversation}
      onExecuteSql={onExecuteSql}
      onAddVizToDashboard={onAddVizToDashboard}
      isExecuteDisabled={isExecuteDisabled}
      isLatestConversation={isLatestConversation}
      loadingVizInsert={loadingVizInsert}
      onAddToWidget={onAddToWidget}
    />
  </Vertical>
);
