import { Schema } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MEMORY_UNIT_LIMITS } from '@/shared/lib/resource-usage';
import { notifications } from '../../../core';

export enum MediaTypeEnum {
  Image = 'img',
  Video = 'video',
  Iframe = 'iframe',
}

type BaseMediaConfig<T extends HTMLElement, K extends MediaTypeEnum> = {
  type: K;
  element: T;
  getOriginalDimensions: (element: T) => {
    width: number;
    height: number;
  };
};

type MediaConfig =
  | BaseMediaConfig<HTMLImageElement, typeof MediaTypeEnum.Image>
  | BaseMediaConfig<HTMLVideoElement, typeof MediaTypeEnum.Video>
  | BaseMediaConfig<HTMLIFrameElement, typeof MediaTypeEnum.Iframe>;

type ResizeDimensions = {
  width: number;
  height: number;
};

export const useResizeMedia = (
  mediaConfig: MediaConfig | null,
  onResize: (dimensions: ResizeDimensions) => void,
) => {
  if (mediaConfig?.type === MediaTypeEnum.Image) {
    mediaConfig.element;
  }
  const [aspectRatio, setAspectRatio] = useState(0);
  const lastClientX = useRef(-1);

  const onHorizontalResize = useCallback(
    (directionOfMouseMove: 'right' | 'left', diff: number) => {
      if (!mediaConfig?.element) {
        return;
      }

      const currentMediaDimensions = {
        width: Number(mediaConfig.element.width),
        height: Number(mediaConfig.element.height),
      };

      const newMediaDimensions = {
        width: -1,
        height: -1,
      };

      if (directionOfMouseMove === 'left') {
        newMediaDimensions.width = currentMediaDimensions.width - Math.abs(diff);
      } else {
        newMediaDimensions.width = currentMediaDimensions.width + Math.abs(diff);
      }
      newMediaDimensions.height = newMediaDimensions.width / aspectRatio;

      if (
        (newMediaDimensions.width < 50 &&
          newMediaDimensions.width < currentMediaDimensions.width) ||
        newMediaDimensions.width > window.innerWidth * 0.7
      ) {
        return;
      }

      onResize(newMediaDimensions);
    },
    [aspectRatio, mediaConfig, onResize],
  );

  const mediaSetupOnLoad = useCallback(() => {
    if (!mediaConfig) return;

    const { type, element, getOriginalDimensions } = mediaConfig;

    if (type === MediaTypeEnum.Video) {
      element.addEventListener('loadeddata', () => {
        const { width, height } = getOriginalDimensions(element);
        setAspectRatio(width / height);
        onHorizontalResize('left', 0);
      });
    } else if (type === MediaTypeEnum.Image) {
      element.onload = () => {
        const { width, height } = getOriginalDimensions(element);
        setAspectRatio(width / height);
      };
    }
    if (type === MediaTypeEnum.Iframe) {
      element.onload = () => {
        const { width, height } = getOriginalDimensions(element);
        setAspectRatio(width / height);
      };
    }
  }, [mediaConfig, onHorizontalResize]);

  const setLastClientX = (x: number) => {
    lastClientX.current = x;
  };

  const onHorizontalMouseMove = (e: MouseEvent) => {
    if (lastClientX.current === -1) return;

    const { clientX } = e;
    const diff = lastClientX.current - clientX;

    if (diff === 0) return;

    const directionOfMouseMove: 'left' | 'right' = diff > 0 ? 'left' : 'right';

    setTimeout(() => {
      onHorizontalResize(directionOfMouseMove, Math.abs(diff));
      lastClientX.current = clientX;
    });
  };

  const debouncedDocumentHorizontalMouseMove = debounce(onHorizontalMouseMove);

  const stopHorizontalResize = () => {
    lastClientX.current = -1;
    if (mediaConfig?.element) {
      mediaConfig.element.setAttribute('style', 'pointer-events: none');
    }

    document.removeEventListener('mousemove', debouncedDocumentHorizontalMouseMove);
    document.removeEventListener('mouseup', stopHorizontalResize);
  };

  const startHorizontalResize = (e: { clientX: number }) => {
    lastClientX.current = e.clientX;
    if (mediaConfig?.element) {
      mediaConfig.element.setAttribute('style', 'pointer-events: none');
    }

    setTimeout(() => {
      document.addEventListener('mousemove', debouncedDocumentHorizontalMouseMove);
      document.addEventListener('mouseup', stopHorizontalResize);
    });
  };

  useEffect(() => {
    mediaSetupOnLoad();
  }, [mediaSetupOnLoad]);

  return {
    onClick: ({ clientX }: { clientX: number }) => setLastClientX(clientX),
    onMouseDown: startHorizontalResize,
    onMouseUp: stopHorizontalResize,
  };
};

interface MediaDimensions {
  width: number;
  height?: number;
}

interface SerializedFile {
  name: string;
  type: string;
  data: string;
}

const EDITOR_WIDTH_RATIO = 0.75;
const FILE_SIZE_ALLOWED = 100; // In MB
const FILE_TYPES_ALLOWED = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/webp',
  'video/quicktime',
  'video/mp4',
];

// File validation
const validateFile = (file: File): Error | null => {
  const fileSize = Number((file.size / MEMORY_UNIT_LIMITS.MB).toFixed(4));

  if (!FILE_TYPES_ALLOWED.includes(file.type)) {
    return new Error('The file format is not permitted.');
  }

  if (fileSize > FILE_SIZE_ALLOWED) {
    return new Error(`The file size exceeds ${FILE_SIZE_ALLOWED}mb.`);
  }

  return null;
};

// Dimension calculations
const calculateDimensions = (
  view: EditorView,
  originalWidth?: number,
  originalHeight?: number,
): MediaDimensions => {
  const editorDom = view.dom;
  const firstChildDom = editorDom.firstElementChild;
  // Get the width of the content area:
  // 1. Try the first child element (ProseMirror content container)
  // 2. Fallback to editor root element if first child isn't available
  const editorWidth = firstChildDom
    ? firstChildDom.getBoundingClientRect().width
    : editorDom.getBoundingClientRect().width;

  const widthLimit = editorWidth * EDITOR_WIDTH_RATIO;

  const finalWidth = Math.min(originalWidth ?? widthLimit, widthLimit);
  const finalHeight = originalHeight
    ? (finalWidth / (originalWidth ?? widthLimit)) * originalHeight
    : undefined;

  return { width: finalWidth, height: finalHeight };
};

// Media node creation
const createMediaNode = (
  schema: Schema,
  file: File,
  serializedFile: string,
  dimensions: MediaDimensions,
) =>
  schema.nodes.resizableMedia.create({
    'media-type': file.type.includes('image') ? 'img' : 'video',
    file: serializedFile,
    width: dimensions.width,
    height: dimensions.height,
  });

// File reading
const readFileAsDataUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

// Image dimensions loading
const getImageDimensions = (dataUrl: string): Promise<MediaDimensions> =>
  new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = dataUrl;
  });

const insertMediaNode = async (file: File, view: EditorView, pos: number, dataUrl: string) => {
  const serializedFile = JSON.stringify({
    name: file.name,
    type: file.type,
    data: dataUrl,
  } as SerializedFile);

  let dimensions: MediaDimensions;

  if (file.type.includes('image')) {
    const imageDimensions = await getImageDimensions(dataUrl);
    dimensions = calculateDimensions(view, imageDimensions.width, imageDimensions.height);
  } else {
    dimensions = calculateDimensions(view);
  }

  const node = createMediaNode(view.state.schema, file, serializedFile, dimensions);
  const transaction = view.state.tr.insert(pos, node);
  view.dispatch(transaction);
};

export const insertFileInEditor = async (
  file: File,
  view: EditorView,
  pos?: number,
): Promise<void> => {
  try {
    const validationError = validateFile(file);
    if (validationError) {
      notifications.show({
        variant: 'error',
        message: validationError.message,
      });
      return;
    }

    const dataUrl = await readFileAsDataUrl(file);
    const position = pos ?? view.state.selection.head;
    await insertMediaNode(file, view, position, dataUrl);
  } catch (error) {
    notifications.show({
      variant: 'error',
      message: 'Failed to insert media file.',
    });
  }
};

export const handleInsertFile = (view: EditorView, fileType?: 'image' | 'video', pos?: number) => {
  const fileUploadContainer = document.createElement('input');
  fileUploadContainer.setAttribute('type', 'file');
  fileUploadContainer.setAttribute(
    'accept',
    FILE_TYPES_ALLOWED.filter(type => !fileType || type.includes(fileType)).join(','),
  );
  fileUploadContainer.addEventListener('change', function () {
    const files = this.files ?? [];
    if (files[0]) {
      insertFileInEditor(files[0], view, pos);
    }
  });
  fileUploadContainer.click();
};
