import {
  ConnectorAPIModal,
  useConnectorAPIModal,
} from '@/main/components/connectors/modals/connectors-v2/ConnectorAPIModal';
import { connectorTypeToResourceTypeMap } from '@/main/components/connectors/modals/connectors-v2/utils';
import { AsyncDropDownResources, ConnectorV2Type } from '@/main/generated/api';
import { useListConnectionsByTypeQuery } from '@/main/queries/connectors/v2/connectors';
import { Alert, Center, Loader, Text } from '@/shared/design-system/v2';
import { ResourceSelect } from '../../../resource-lookup/resource-selection/ResourceSelect';

interface APIKeySelectControlWrapperProps {
  value: string;
  onChange: (value: string) => void;
  connectorType: ConnectorV2Type;
  isViewOnlyForm?: boolean;
}

export const APIKeySelectControlWrapper = ({
  value,
  onChange,
  connectorType,
  isViewOnlyForm,
}: APIKeySelectControlWrapperProps) => {
  const {
    isInitialLoading,
    isError,
    data: connectorAccounts,
  } = useListConnectionsByTypeQuery(connectorType);

  const { open: openConnectorAPIModal } = useConnectorAPIModal(onChange);

  if (isInitialLoading) {
    return (
      <Center py={16} style={{ gap: '10px' }}>
        <Loader size={32} variant="dots" />
        <Text variant="subTitle03">{`Fetching ${connectorTypeToResourceTypeMap[connectorType]} ...`}</Text>
      </Center>
    );
  }

  if (isError) {
    return (
      <Center py={16} style={{ gap: '10px' }}>
        <Alert color="red">{`Failed to fetch ${connectorTypeToResourceTypeMap[connectorType]} ...`}</Alert>
      </Center>
    );
  }

  if (!connectorType || !Object.values(ConnectorV2Type).includes(connectorType)) {
    return (
      <Alert color="red">
        Invalid connector type. Please check configuration or contact support
      </Alert>
    );
  }

  const handleResourceSelection = (selectedValue: 'add_new' | string) => {
    if (!selectedValue) {
      return;
    }

    if (selectedValue === 'add_new') {
      openConnectorAPIModal(connectorType);
      return;
    }

    onChange(selectedValue);
  };

  if (!connectorAccounts || connectorAccounts?.length === 0) {
    return (
      <ConnectorAPIModal connectorType={connectorType} onChange={onChange} isOpenInForm={true} />
    );
  }

  const resources = connectorAccounts.map(account => ({
    label: account.name,
    value: account.connectionId,
  }));

  return (
    <ResourceSelect
      resourceKey={AsyncDropDownResources.ConnectedAccounts}
      resources={[{ label: '+ Add New', value: 'add_new' }, ...resources]}
      value={value}
      placeholder="Select project"
      ariaLabel={' '} // FIX IT
      onChange={handleResourceSelection}
      size="lg"
      h={60}
      fz={30}
      style={{
        borderRadius: '8px',
        background: 'gray.9',
      }}
      readOnly={isViewOnlyForm}
    />
  );
};
