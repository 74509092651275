import { MantineTheme } from '@mantine/styles';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { interleaveComponent } from '@/shared/lib/util-react';
import { DIFF_TYPE } from '../../../../../../apps/main/components/common/constants';
import { CopyEditCitationText } from '../../../../../../apps/main/generated/api';
import { Box, Button, CSSObject, HTMLPreview, Horizontal, Popover, Text } from '../../../core';

export const ReferenceText = ({ value }: { value: string }) => (
  <span style={{ color: 'blue' }}>{value}</span>
);

const getBoxStyles = (highlightType: number, theme: MantineTheme): CSSObject => {
  const highlightBackgroundMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[0],
    [DIFF_TYPE.EQUAL]: '',
    [DIFF_TYPE.DELETE]: theme.colors.red[0],
  };
  return {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: highlightBackgroundMap[highlightType],
  };
};
interface ListReferenceProps {
  referenceObj: CopyEditCitationText;
  classes: Record<string, string>;
}

export const ReferenceSectionComponent = ({
  referenceObj,
  classes,
}: ListReferenceProps): JSX.Element => {
  const refSummary = referenceObj.citationHtml;

  if (refSummary) {
    return (
      <HTMLPreview p={2} className={classes.content}>
        {refSummary}
      </HTMLPreview>
    );
  }

  return <></>;
};

interface InlineReferenceProps {
  referenceObj: CopyEditCitationText;
  editCitation: (citationId: string) => void;
  removeCitation: () => void;
  classes: Record<string, string>;
}

export const InlineReferencePopoverComponent = ({
  referenceObj,
  editCitation,
  removeCitation,
  classes,
}: InlineReferenceProps): JSX.Element => {
  const refSummary = referenceObj.citationHtml;

  if (refSummary) {
    return (
      <Box w={360} p="xs">
        <HTMLPreview pb="sm" className={classes.content}>
          {refSummary}
        </HTMLPreview>
        <Horizontal position="apart">
          <Button
            leftIcon={<IconPencil />}
            variant="subtle"
            size="sm"
            color="gray.6"
            onClick={() => editCitation(referenceObj.citationId)}
          >
            Edit
          </Button>
          <Button
            leftIcon={<IconTrash />}
            variant="subtle"
            size="sm"
            color="gray.6"
            onClick={removeCitation}
          >
            Remove
          </Button>
        </Horizontal>
      </Box>
    );
  }

  return <></>;
};

interface MarkovReferenceItemProps {
  citation: CopyEditCitationText;
  fullCitation: CopyEditCitationText;
  highlightType: number;
  editCitation: (citationId: string) => void;
  removeCitation: () => void;
  classes: Record<string, string>;
}

const MarkovReferenceItem = ({
  citation,
  fullCitation,
  highlightType,
  editCitation,
  removeCitation,
  classes,
}: MarkovReferenceItemProps): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleOnClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <Box component="span" sx={theme => getBoxStyles(highlightType, theme)}>
      <Popover
        width={400}
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        radius="md"
        offset={4}
        styles={{
          dropdown: {
            maxWidth: '100%', // Ensure the dropdown doesn't exceed the parent width
            wordWrap: 'break-word', // Wrap words within the dropdown
            whiteSpace: 'normal', // Allow text to wrap to the next line
          },
        }}
      >
        <Popover.Target>
          <Text
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={handleOnClick}
            className={classes.content}
          >
            {citation.citationText}
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <InlineReferencePopoverComponent
            referenceObj={fullCitation}
            editCitation={editCitation}
            removeCitation={removeCitation}
            classes={classes}
          />
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

interface InlineCitationComponentProps {
  citations: CopyEditCitationText[];
  fullCitations: CopyEditCitationText[];
  highlightType: number;
  editCitation: (citationId: string) => void;
  removeCitation: () => void;
  classes: Record<string, string>;
}

export const InlineCitationComponent = ({
  citations,
  fullCitations,
  highlightType,
  editCitation,
  removeCitation,
  classes,
}: InlineCitationComponentProps): JSX.Element => {
  const delimiter = '';

  const citationJSX = citations.map(citation => (
    <MarkovReferenceItem
      key={citation.citationId}
      citation={citation}
      highlightType={highlightType}
      fullCitation={
        fullCitations.find(fullCitation => fullCitation.citationId === citation.citationId) ??
        citation
      }
      editCitation={editCitation}
      removeCitation={removeCitation}
      classes={classes}
    />
  ));

  return (
    <span>
      {citations &&
        interleaveComponent(citationJSX, () => <ReferenceText value={delimiter ?? ' '} />)}
    </span>
  );
};
