import { IconAtom, IconDeviceAnalytics, IconSparkles, IconWriting } from '@tabler/icons-react';
import some from 'lodash/some';
import { GetDAGResponse, WorkflowAppCategory, WorkflowAppPersona } from '@/main/generated/api';
import { useMarkovTheme, useMediaQuery } from '@/shared/design-system/v2';
import { topologicalSort } from '../workflow-utils';

export const HEADER_HEIGHT = 54;

export const OPERATOR_INPUT_FILE_ID = 'opmkvlibraryconnectorsource01';

export const OPERATOR_SAVE_FILE_ID = 'opsaveoutputdata01';

export const OPERATOR_VIEW_AS_TABLE_ID = 'opviewastable01';

export const OPERATOR_VIEW_AS_FULL_TEXT_ID = 'opviewasfulltext01';

export const OPERATOR_PASTE_DATA_ID = 'oppastebinsource01';

export const supportedSourceOperators = [OPERATOR_INPUT_FILE_ID, OPERATOR_PASTE_DATA_ID];

export const supportedSinkOperators = [OPERATOR_VIEW_AS_TABLE_ID, OPERATOR_VIEW_AS_FULL_TEXT_ID];

export const supportedAppBuilderOperators = [
  ...supportedSourceOperators,
  ...supportedSinkOperators,
];

export const EMPTY_APP_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/illustration-apps-empty.svg?updatedAt=1739531577451';

export const APP_DEPLOY_SUCCESS_ILLUSTRATION_URL =
  'https://ik.imagekit.io/markovml/workflows/miscellaneous/illustration-app-deploy-success.svg?updatedAt=1735212999880';

export const getSortedNodes = (dag: GetDAGResponse) => {
  const { nodes, edges } = dag;

  const nodeIds = topologicalSort(edges, nodes);

  return nodeIds.map(nodeId => nodes.find(node => node.id === nodeId)) as GetDAGResponse['nodes'];
};

export const resolveSteps = (dag: GetDAGResponse) => getSortedNodes(dag);

export const checkIfBranchingDAG = (dag: GetDAGResponse): boolean => {
  const outgoing: Record<string, number> = {};
  const incoming: Record<string, number> = {};

  dag.edges.forEach(edge => {
    const { source, target } = edge;

    outgoing[source] = (outgoing[source] || 0) + 1;

    incoming[target] = (incoming[target] || 0) + 1;
  });

  const hasBranchingOrMerging =
    Object.values(outgoing).some(count => count > 1) ||
    Object.values(incoming).some(count => count > 1);

  return hasBranchingOrMerging;
};

export const checkOperatorsInDAG = (dag: GetDAGResponse): boolean => {
  const hasSupportedSourceOperators = dag.nodes.some(node =>
    supportedSourceOperators.includes(node.data.id),
  );
  const hasSaveFileOperator = dag.nodes.some(node => supportedSinkOperators.includes(node.data.id));

  return hasSupportedSourceOperators && hasSaveFileOperator;
};

export const useGetColorsForApp = (appPersona?: WorkflowAppPersona) => {
  const theme = useMarkovTheme();

  switch (appPersona) {
    case WorkflowAppPersona.Marketing:
      return {
        iconColor: theme.colors.orange[0],
        iconBackground: theme.colors.orange[6],
        gradient: `linear-gradient(135deg, ${theme.colors.orange[0]} 0%, ${theme.colors.white[0]} 30%)`,
      };
    case WorkflowAppPersona.Sales:
      return {
        iconColor: theme.colors.skyBlue[0],
        iconBackground: theme.colors.skyBlue[6],
        gradient: `linear-gradient(135deg, ${theme.colors.skyBlue[0]} 0%, ${theme.colors.white[0]} 30%)`,
      };
    case WorkflowAppPersona.Others:
      return {
        iconColor: theme.colors.seaGreen[0],
        iconBackground: theme.colors.seaGreen[6],
        gradient: `linear-gradient(135deg, ${theme.colors.seaGreen[0]} 0%, ${theme.colors.white[0]} 30%)`,
      };
    default:
      return {
        iconColor: theme.colors.green[0],
        iconBackground: theme.colors.green[6],
        gradient: `linear-gradient(135deg, ${theme.colors.green[0]} 0%, ${theme.colors.white[0]} 30%)`,
      };
  }
};

export const useTotalDisplayedApps = () => {
  const theme = useMarkovTheme();

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isMd = useMediaQuery(`(max-width: 1200px`);

  if (isXs) {
    return 1;
  } else if (isSm) {
    return 2;
  } else if (isMd) {
    return 3;
  }

  return 4;
};

export const APP_IMAGE_SIZE = 184;

export const useGetCategoryDetails = (category: WorkflowAppCategory) => {
  const theme = useMarkovTheme();
  const iconColor = theme.colors.purple[3];
  const iconSize = 32;

  switch (category) {
    case WorkflowAppCategory.ContentGeneration:
      return {
        title: 'Content Generation',
        description:
          'Apps to aid your content creation process - plan content, get ideas for blogs, and more.',
        icon: <IconWriting size={iconSize} color={iconColor} />,
      };
    case WorkflowAppCategory.DataEnrichment:
      return {
        title: 'Data Enrichment',
        description:
          'Apps that enrich your data for further processing - lead enrichment, data cleanup, and more.',
        icon: <IconAtom size={iconSize} color={iconColor} />,
      };
    case WorkflowAppCategory.Analysis:
      return {
        title: 'Market Research',
        description:
          'Apps that extract insights for your research - call analysis, website scraping, and more.',
        icon: <IconDeviceAnalytics size={iconSize} color={iconColor} />,
      };
    case WorkflowAppCategory.Others:
    default:
      return {
        title: 'Miscellaneous',
        description: 'Apps for everything else',
        icon: <IconSparkles size={iconSize} color={iconColor} />,
      };
  }
};

export const hasEmptyField = (data: any): boolean =>
  some(data, value => typeof value === 'string' && value.trim() === '');
