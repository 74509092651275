import { Flex, Text } from '@/shared/design-system/v2';
import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { ResourceModel, StorageType } from '../../../generated/api';
import { storageConfigMap } from './config';

export const TypeRenderer = ({
  value: connectorType,
  data,
}: ICellRendererParams<ResourceModel, StorageType>): JSX.Element => {
  const config =
    connectorType !== undefined && connectorType in storageConfigMap
      ? storageConfigMap[connectorType]
      : undefined;

  const connectorName = data?.connectionName ?? '--';
  const icon = config?.connectorIcon ?? null;

  return (
    <Flex h="100%" justify="center" align="center" gap="4px">
      <Flex sx={{ flexShrink: 0 }}>{icon}</Flex>
      <Text variant="bodyShort03" span sx={{ flexGrow: 1 }} truncate>
        {connectorName}
      </Text>
    </Flex>
  );
};
