import first from 'lodash/first';
import get from 'lodash/get';

export const extractMkvFolderFilePreviewData = (data: any) => {
  const resourceId = get(data, 'ResourceID', '');
  const fileType = get(data, 'Type', '').toLowerCase();
  const filePath = first(get(data, 'Path', '').split('.')) as string;

  return { resourceId, fileType, filePath };
};

export const APP_BUILDER_PLACEHOLDER_TEXT =
  'No preview available. The data is generated after a successful app run.';
