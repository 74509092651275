import noop from 'lodash/noop';
import { CompleteParams } from '@/main/queries/data-upload';
import { Button, Text, Vertical } from '@/shared/design-system/v2';
import { FileUploader } from '../uploader/file-uploader/FileUploader';

interface FileUploadSelectViewProps {
  isEditable: boolean;
  onClickSelect: () => void;
  onUploadComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  onUploadQueued?: (uploadId?: string) => void;
  additionalData?: Record<string, any>;
  supportedFileTypes?: string[];
}

export const FileUploadSelectView = ({
  isEditable,
  onClickSelect,
  onUploadComplete,
  onUploadQueued = noop,
  additionalData,
  supportedFileTypes,
}: FileUploadSelectViewProps): JSX.Element => (
  <Vertical>
    <FileUploader
      showPreviewInModal
      onUploadQueued={onUploadQueued}
      onUploadComplete={onUploadComplete}
      additionalData={additionalData}
      supportedFileTypes={supportedFileTypes}
      isEditable={isEditable}
    />
    <Text variant="subTitle04" color="gray.7">
      Or pick a file previously uploaded to the Markov Library
    </Text>
    <Button
      variant="light"
      disabled={!isEditable}
      onClick={onClickSelect}
      sx={{ alignSelf: 'center' }}
    >
      Select from Library
    </Button>
  </Vertical>
);
