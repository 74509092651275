import { Box, Button, Horizontal, Text, Vertical } from '@/shared/design-system/v2';

interface SelectedFileViewProps {
  name: string;
  isEditable: boolean;
  onChangeFile: () => void;
}

export const SelectedFileView = ({
  name,
  isEditable,
  onChangeFile,
}: SelectedFileViewProps): JSX.Element => (
  <Vertical spacing="xs" pt="sm">
    <Box w="fit-content" bg="gray.1" py="sm" px="md" sx={{ borderRadius: '8px' }}>
      <Text variant="subTitle03" lineClamp={1} title={name} sx={{ wordBreak: 'break-all' }}>
        {name}
      </Text>
    </Box>
    <Horizontal noWrap>
      {isEditable && (
        <Button variant="subtle" px="xs" onClick={onChangeFile}>
          Change file
        </Button>
      )}
    </Horizontal>
  </Vertical>
);
