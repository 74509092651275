import { Button, Horizontal } from '@/shared/design-system/v2';
import { CopyToClipboardTooltip } from '../../../CopyToClipboardTooltip';

export interface AnswerViewFooterProps {
  answerToCopy: string;
  onAddToWidget?: () => void;
}

export const AnswerViewFooter = ({ answerToCopy, onAddToWidget }: AnswerViewFooterProps) => {
  if (!answerToCopy) {
    return null;
  }

  const handleAddToWidget = () => {
    onAddToWidget?.();
  };

  return (
    <Horizontal>
      <CopyToClipboardTooltip valueToCopy={answerToCopy} isMarkdown={true} />
      {onAddToWidget ? <Button onClick={handleAddToWidget}>Add to dashboard</Button> : null}
    </Horizontal>
  );
};
