import { createQueryParams } from '@/shared/lib/router';

export enum AppRoutes {
  BASE_ROUTE = 'BASE_ROUTE',
  SIGNUP = 'SIGNUP',
  LOGOUT = 'LOGOUT',
  INVITE = 'INVITE',
  ONBOARDING = 'ONBOARDING',
  ERROR_ROUTE = 'ERROR_ROUTE',
  ACCESS_DENIED = 'ACCESS_DENIED',
  GLOBAL_HOME = 'GLOBAL_HOME',
  USER_PROFILE = 'USER_PROFILE',
  SETTINGS = 'SETTINGS',
  IFRAME_HOME_PAGE = 'IFRAME_HOME_PAGE',

  PUBLIC_EDITOR = 'PUBLIC_EDITOR',

  COPY_EDIT = 'COPY_EDIT',
  COPY_EDIT_DETAIL = 'COPY_EDIT_DETAIL',
  COPY_EDIT_SETTINGS = 'COPY_EDIT_SETTINGS',
  COPY_EDIT_ANALYTICS = 'COPY_EDIT_ANALYTICS',
  COPY_EDIT_VERSION_HISTORY = 'COPY_EDIT_VERSION_HISTORY',
  COPY_EDIT_REVIEW_MODE = 'COPY_EDIT_REVIEW_MODE',

  APP_STORE_PROJECT = 'APP_STORE_PROJECT',
  APP_STORE_DOCUMENT_DETAILS = 'APP_STORE_DOCUMENT_DETAILS',
}

export const routeDetails = {
  [AppRoutes.BASE_ROUTE]: {
    path: '/',
  },
  [AppRoutes.LOGOUT]: {
    path: '/logout',
  },
  [AppRoutes.SIGNUP]: {
    path: '/signup',
  },
  [AppRoutes.ONBOARDING]: {
    path: 'onboarding',
  },
  [AppRoutes.ERROR_ROUTE]: {
    path: `/404`,
  },
  [AppRoutes.ACCESS_DENIED]: {
    path: `/access-denied`,
  },
  [AppRoutes.IFRAME_HOME_PAGE]: {
    path: 'iframe-home',
  },
  [AppRoutes.INVITE]: {
    path: '/invite/:inviteId',
  },
  [AppRoutes.GLOBAL_HOME]: {
    path: ':workspaceId',
  },
  [AppRoutes.COPY_EDIT]: {
    path: 'copy-edit',
  },
  [AppRoutes.COPY_EDIT_DETAIL]: {
    path: ':documentId',
  },
  [AppRoutes.COPY_EDIT_SETTINGS]: {
    path: 'app/settings',
  },
  [AppRoutes.COPY_EDIT_ANALYTICS]: {
    path: 'app/analytics',
  },
  [AppRoutes.COPY_EDIT_VERSION_HISTORY]: {
    path: 'history',
  },
  [AppRoutes.COPY_EDIT_REVIEW_MODE]: {
    path: 'review',
  },
  [AppRoutes.APP_STORE_DOCUMENT_DETAILS]: {
    path: '/document/:documentId',
  },
  [AppRoutes.APP_STORE_PROJECT]: {
    path: '/project/:projectId',
  },
  [AppRoutes.USER_PROFILE]: {
    path: '/user/:userId',
  },
  [AppRoutes.SETTINGS]: {
    path: 'settings',
  },
  [AppRoutes.PUBLIC_EDITOR]: {
    path: 'editor',
  },
};

// TODO: See if the following util functions can be moved to the shared folder
export const getRoute = (route: AppRoutes, queryParams = {}) => {
  const { path } = routeDetails[route];
  return `${path}${createQueryParams(queryParams)}`;
};

export const getDescendantRoute = (route: AppRoutes) => {
  const { path } = routeDetails[route];
  return `${path}/*`;
};

export const getUserProfilePath = (userId: string) => {
  const { path } = routeDetails[AppRoutes.USER_PROFILE];
  return `${path.replace(':userId', userId)}`;
};
