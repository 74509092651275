import { Group, GroupProps } from '@mantine/core';
import { forwardRef } from 'react';

export type HorizontalProps = GroupProps & React.RefAttributes<HTMLDivElement>;

export const Horizontal = forwardRef<HTMLDivElement, HorizontalProps>(
  (props: HorizontalProps, ref) => <Group ref={ref} {...props} />,
);

Horizontal.displayName = 'Horizontal';
