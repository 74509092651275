import { Skeleton, Vertical } from '@/shared/design-system/v2';
import { useLoaderStyles } from './AISkeletonLoader.styles';

/**
 * Skeleton loader to be used when AI is generating a response
 */
export const AISkeletonLoader = () => {
  const { classes: skeletonClasses } = useLoaderStyles();
  return (
    <Vertical h="100%" w="100%" spacing={12}>
      <Skeleton className={skeletonClasses.root} />
      <Skeleton className={skeletonClasses.root} />
      <Skeleton className={skeletonClasses.root} />
    </Vertical>
  );
};
