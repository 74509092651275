import { ConnectorV2Type } from '@/main/generated/api';
import { IconGongLogo, IconNotionLogo } from '@/shared/design-system';

export const OauthConnectors = new Set<ConnectorV2Type>([
  ConnectorV2Type.Notion,
  ConnectorV2Type.GoogleAnalytics,
  ConnectorV2Type.Gong,
  ConnectorV2Type.Slack,
  ConnectorV2Type.GoogleDrive,
  ConnectorV2Type.Apollo,
  ConnectorV2Type.Hubspot,
]);

export const APIKeyConnectors = new Set<ConnectorV2Type>([ConnectorV2Type.Readme]);

export const connectorTypeToResourceTypeMap: Partial<Record<ConnectorV2Type, string>> = {
  [ConnectorV2Type.Readme]: 'projects',
};

export const defaultAPIKeyConnector = ConnectorV2Type.Readme;

export const getConnectorTypeDisplayName = (connectorType?: ConnectorV2Type): string => {
  if (connectorType) {
    return connectorType
      .replace(/[_-]/g, ' ')
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  throw new Error('Connector type is required to get its display name.');
};
//will be removes=d when v1 connectors is fully deprecated
export const getConnectorTypeIcon = (connectorType: ConnectorV2Type, width = 24): JSX.Element => {
  switch (connectorType) {
    case ConnectorV2Type.Notion:
      return <IconNotionLogo width={width} />;
    case ConnectorV2Type.Gong:
      return <IconGongLogo width={width} />;
    default:
      return <></>;
  }
};
