import { LayoutProps, RankedTester, VerticalLayout, rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Box, Text, Vertical } from '@/shared/design-system/v2';

export const InfoTextLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
}: LayoutProps) => {
  if (!visible) {
    return null;
  }

  const infoTextLayout = uischema as VerticalLayout;
  const uiSchemaOptions = uischema.options;
  const layoutTitle = uiSchemaOptions?.title ?? '';
  const layoutDescription = uiSchemaOptions?.description ?? '';

  return (
    <Vertical spacing={uiSchemaOptions?.spacing ?? 24}>
      <Box>
        <Text variant="subTitle02" color="gray.9">
          {layoutTitle}
        </Text>
        <Text variant="small02" color="gray.7">
          {layoutDescription}
        </Text>
      </Box>
      {infoTextLayout.elements.map((element, idx) => (
        <JsonFormsDispatch
          key={`${path}_${idx}`}
          uischema={element}
          schema={schema}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      ))}
    </Vertical>
  );
};

export const InfoTextLayoutControl = withJsonFormsLayoutProps(InfoTextLayoutComponent);

export const infoTextControlTester: RankedTester = rankWith(1, uiTypeIs('InfoTextLayout'));
