import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useEffect, useMemo, useState } from 'react';
import { logger } from '@/shared/initializers/logging';
import {
  getPathParams,
  getReadUrl,
  useMediaUpload,
} from '../../../../../../apps/main/queries/snippets/media-upload';
import { Alert, Box, Center, Loader } from '../../../core/';
import { useRichTextEditorContext } from '../../../rich-text-editor/index';
import { ActionsMenu } from './ActionsMenu';
import { useResizableMediaStyles } from './ResizableMedia.style';
import { MediaTypeEnum, useResizeMedia } from './resizableMediaMenu.util';

const CDN_URL = import.meta.env.VITE_APP_CDN_URL;
const ALLOWED_ORIGINS = [CDN_URL, 'https://ik.imagekit.io'];

const isValidUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return ALLOWED_ORIGINS.includes(parsedUrl.origin);
  } catch {
    return false;
  }
};

const getSafeImageUrl = (src: string, resourcePath: string) => {
  if (isValidUrl(src)) {
    return src;
  }

  const path = getPathParams({
    resourcePath,
    fileName: src,
  });
  return getReadUrl(path);
};

export const ResizableMediaNodeView = ({
  node,
  updateAttributes,
  deleteNode,
  selected,
  extension,
}: NodeViewProps) => {
  const {
    'media-type': mediaType,
    file: serializedFile,
    src = '',
    alt,
    height,
    width,
    dataAlign,
  } = node.attrs ?? {};
  const { options } = extension;
  const { editor } = useRichTextEditorContext();

  // TODO: Allow editing for Base64 image
  const isBase64Image = src?.startsWith('data:');
  const editing = editor.isEditable && !isBase64Image;

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resizableMediaElement, setResizableMediaElement] = useState<
    HTMLImageElement | HTMLVideoElement | null
  >(null);

  const mediaConfig =
    resizableMediaElement && mediaType === 'img'
      ? {
          type: MediaTypeEnum.Image as const,
          element: resizableMediaElement as HTMLImageElement,
          getOriginalDimensions: (element: HTMLImageElement) => ({
            width: element.clientWidth,
            height: element.clientHeight,
          }),
        }
      : resizableMediaElement && mediaType === 'video'
      ? {
          type: MediaTypeEnum.Video as const,
          element: resizableMediaElement as HTMLVideoElement,
          getOriginalDimensions: (element: HTMLVideoElement) => ({
            width: element.videoWidth,
            height: element.videoHeight,
          }),
        }
      : null;
  const resizeHandlers = useResizeMedia(mediaConfig, updateAttributes);
  const { classes, cx } = useResizableMediaStyles({ align: dataAlign });

  const file = useMemo(() => {
    if (!serializedFile) {
      return null;
    }
    const { name, type, data } = JSON.parse(serializedFile);
    const byteCharacters = atob(data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const deserializedFile = new File([byteArray], name, { type });

    return deserializedFile;
  }, [serializedFile]);

  useMediaUpload({
    resourcePath: options.resourcePath,
    onSuccess: updateAttributes,
    file,
  });

  const canShowMedia = !isError && !isLoading && src && !file;

  const srcUrl = isBase64Image ? src : getSafeImageUrl(src, options.resourcePath);

  let content = null;
  if (isError) {
    logger.error(`Unable to load media with URL: ${srcUrl}`);
    content = (
      <Center h={height ?? 300} w={width ?? 400} bg="red.1">
        <Alert color="red">Error loading media</Alert>
      </Center>
    );
  } else if (isLoading) {
    content = (
      <Center h={height ?? 300} w={width ?? 400} bg="gray.2">
        <Loader variant="dots" />
      </Center>
    );
  } else if (canShowMedia) {
    if (mediaType === 'img') {
      content = (
        <img
          src={srcUrl}
          ref={setResizableMediaElement}
          alt={alt ?? src}
          width={width ?? '100%'}
          height={height ?? 'auto'}
        />
      );
    } else if (mediaType === 'video') {
      content = (
        <video
          ref={setResizableMediaElement}
          controls
          width={width ?? 650}
          height={height ?? 'auto'}
        >
          <source src={srcUrl} />
        </video>
      );
    }
  }

  useEffect(() => {
    if (src) {
      if (mediaType === 'img') {
        const img = new Image();
        img.src = srcUrl;

        img.onload = () => {
          setIsLoading(false);
        };

        img.onerror = () => {
          setIsError(true);
          setIsLoading(false);
        };
      } else if (mediaType === 'video') {
        setIsLoading(false);
      }
    }
  }, [mediaType, src, srcUrl]);

  return (
    <NodeViewWrapper as="article" className={classes.mediaNodeView}>
      <Box
        className={cx(classes.mediaContainer, {
          [classes.selected]: selected,
        })}
      >
        {content}

        {canShowMedia && editing && (
          <Box className={classes.resizeHandle} title="Resize" {...resizeHandlers} />
        )}

        {editing && (
          <ActionsMenu
            deleteNode={deleteNode}
            updateAttributes={updateAttributes}
            inline={options.inline}
          />
        )}
      </Box>
    </NodeViewWrapper>
  );
};
