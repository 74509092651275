import {
  ControlProps,
  EnumOption,
  JsonSchema4,
  JsonSchema7,
  OwnPropsOfEnum,
  RankedTester,
  isEnumControl,
  isOneOfEnumControl,
  rankWith,
} from '@jsonforms/core';
import {
  useJsonForms,
  withJsonFormsEnumProps,
  withJsonFormsOneOfEnumProps,
} from '@jsonforms/react';
import { forwardRef, useState } from 'react';
import { Select, Text, Vertical } from '@/shared/design-system/v2';
import { TagFormFieldSwitchWrapper } from '../TagFormFieldSwitch.wrapper';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description?: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <Vertical spacing={0} p="md" ref={ref} {...others}>
      <Text variant="subTitle03">{label}</Text>
      {description && (
        <Text variant="small03" color="gray.5">
          {description}
        </Text>
      )}
    </Vertical>
  ),
);

SelectItem.displayName = 'SelectItem';

export const SelectControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  enabled,
  visible,
  data,
  schema,
  options = [],
  errors,
  config,
}: ControlProps & OwnPropsOfEnum) => {
  const [created, setCreated] = useState<EnumOption[]>([]);

  const { showFieldSelector } = config;

  const ctx = useJsonForms();
  const formData = ctx.core?.data;

  if (!visible) {
    return null;
  }

  const optionsWithDescriptions = options.map(option => {
    if (!schema.oneOf) {
      return option;
    }

    const matchingSchema = (schema.oneOf as (JsonSchema4 | JsonSchema7)[]).find(
      item => item.const === option.value,
    );

    const { description } = matchingSchema ?? {};
    if (description) {
      return { ...option, description };
    }

    return option;
  });

  const hasDescriptions = optionsWithDescriptions.some(option => 'description' in option);

  const onChange = (value: string) => {
    handleChange(path, value);
  };

  const uiSchemaOptions = uischema.options;
  let dropdownOptions = uiSchemaOptions?.dropdownOptions ?? {};

  if (dropdownOptions.creatable) {
    const handleCreateInputChange = (query: string) => {
      const item = { value: query, label: query };
      setCreated(prev => [...prev, { value: query, label: query }]);

      return item;
    };

    dropdownOptions = {
      ...dropdownOptions,
      getCreateLabel: (query: string) => `+ Create ${query}`,
      onCreate: handleCreateInputChange,
    };
  }

  const isViewOnlyForm = config.viewOnly;

  return (
    <TagFormFieldSwitchWrapper
      path={path}
      onChange={handleChange}
      formData={formData}
      showFieldSelector={showFieldSelector}
    >
      <Select
        ariaLabel={label || uischema.scope}
        label={
          <Text span variant="subTitle02">
            {label}
          </Text>
        }
        description={
          <Text span variant="small02" color="gray.7" pb="sm">
            {description}
          </Text>
        }
        placeholder={uiSchemaOptions?.placeholder}
        options={[...optionsWithDescriptions, ...created]}
        onChange={onChange}
        value={data}
        defaultValue={schema.default}
        {...dropdownOptions}
        error={config.isFormDirty ? errors : undefined}
        withinPortal
        disabled={!isViewOnlyForm && !enabled}
        readOnly={isViewOnlyForm}
        withAsterisk={required}
        itemComponent={hasDescriptions ? SelectItem : undefined}
      />
    </TagFormFieldSwitchWrapper>
  );
};

export const selectOneOfControlTester: RankedTester = rankWith(5, isOneOfEnumControl);

export const selectEnumControlTester: RankedTester = rankWith(5, isEnumControl);

export const SelectOneOfControl = withJsonFormsOneOfEnumProps(SelectControlComponent);

export const SelectEnumControl = withJsonFormsEnumProps(SelectControlComponent);
