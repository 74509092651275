import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import {
  AppIdType,
  CreateWorkflowAppProjectRequest,
  UploadManualTextFileFromWorkflowsAppRequest,
} from '@/main/generated/api';
import { workflowCopycompassApi } from '@/shared/lib/api/fuji';

export const workflowDocumentKeys = {
  all: ['workflow-document'] as const,
  checkProjectAndDocument: (workspaceId: string, appId: string, runId: string) =>
    [...workflowDocumentKeys.all, 'check-project-document', workspaceId, appId, runId] as const,
};

export const useCreateGenAIProjectMutation = (workflowAppId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: CreateWorkflowAppProjectRequest) =>
    workflowCopycompassApi.createGenAIProjectFromWorkflowAppV1(workspaceId, workflowAppId, req),
  );
};

export const useUploadManualTextMutation = (workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(
    ({ projectId, ...req }: UploadManualTextFileFromWorkflowsAppRequest & { projectId: string }) =>
      workflowCopycompassApi.uploadManualTextFileFromWorkflowsRequestV1(
        workspaceId,
        projectId,
        workflowRunId,
        AppIdType.CopyEdit,
        req,
      ),
  );
};

export const useCheckProjectAndDocumentQuery = (workflowAppId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowDocumentKeys.checkProjectAndDocument(workspaceId, workflowAppId, workflowRunId),
    () =>
      workflowCopycompassApi.checkProjectAndDocumentV1(workspaceId, workflowAppId, workflowRunId),
    {
      select: data => data.data,
      enabled: !!workspaceId && !!workflowRunId && !!workflowAppId,
    },
  );
};
