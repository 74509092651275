import { Editor, Range } from '@tiptap/core';
import { DOMParser, DOMSerializer, Fragment } from 'prosemirror-model';
import { marked } from '@/shared/design-system/v2/utils';

/**
 * Tiptap enforce stricter HTML structures and don’t support <ul> or <ol> elements directly inside <li> tags.
 * This function ensures compatibility by flattening nested lists into a valid structure
 */
function flattenNestedLists(container: HTMLElement) {
  // Select all <li> elements within the container.
  const listItems = container.querySelectorAll('li');

  // Iterate over each <li> element to process any nested lists inside it.
  listItems.forEach((li: HTMLLIElement) => {
    // Find all <ul> or <ol> elements nested directly within the current <li>.
    const nestedLists = li.querySelectorAll(':scope > ul,  :scope > ol');

    nestedLists.forEach(nestedList => {
      // Ensure the parent node exists before proceeding.
      if (li.parentNode) {
        // Move the nested list to become a sibling of the current <li>.
        li.parentNode.insertBefore(nestedList, li.nextSibling);
      }
    });
  });

  return container;
}

export function processAndInsertParsedContent(
  htmlContent: string,
  editor: Editor,
  position: number | Range,
) {
  const parser = DOMParser.fromSchema(editor.schema);
  const container = document.createElement('div');
  container.innerHTML = htmlContent;

  const doc = parser.parse(container) as any;

  // Replace AI node with generated content
  // TODO: Find a better way to extract all the paragraphs from given HTML.
  const allParagraphs = doc.content.content[0].content.content;
  editor.commands.insertContentAt(position, Fragment.fromArray(allParagraphs).toJSON());
}

export async function parseAndSerializeMarkdown(markdownContent: string, editor: Editor) {
  const htmlContent = await marked.parse(markdownContent);
  const parser = DOMParser.fromSchema(editor.schema);
  const container = document.createElement('div');
  container.innerHTML = htmlContent;

  flattenNestedLists(container);

  const doc = parser.parse(container);

  const serializer = DOMSerializer.fromSchema(editor.schema);
  const serializedContainer = document.createElement('div');

  serializer.serializeFragment(
    doc.content,
    {
      document: serializedContainer.ownerDocument,
    },
    serializedContainer,
  );

  const serializedHTML = serializedContainer.children[0]?.innerHTML ?? '';
  return serializedHTML;
}

export const normalizeHTMLStructure = (html: string) => {
  const container = document.createElement('div');
  container.innerHTML = html;

  flattenNestedLists(container);

  return container.innerHTML.replace(/>\s+</g, '><').trim();
};

export const convertMarkdownToHTML = async (markdownContent: string) => {
  const htmlContent = await marked.parse(markdownContent);
  return normalizeHTMLStructure(htmlContent);
};
