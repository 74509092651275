import { createStyles, getStylesRef } from '@mantine/styles';

export const useResizableMediaStyles = createStyles<any, { align: 'start' | 'end' | 'center' }>(
  (theme, { align }) => ({
    mediaNodeView: {
      width: '100%',
      display: 'flex',
      justifyContent: align,
      paddingRight: theme.spacing.lg,

      '&:hover': {
        [`.${getStylesRef('mediaActions')}`]: {
          display: 'flex',
        },
        [`.${getStylesRef('resizeHandle')}`]: {
          display: 'block',
        },
        zIndex: 1,
      },
    },

    mediaContainer: {
      display: 'flex',
      position: 'relative',

      '> img': {
        borderRadius: theme.spacing.xs,
      },
      '> video': {
        borderRadius: theme.spacing.xs,
      },
    },

    selected: {
      borderRadius: theme.radius.xs,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: theme.radius.xs,
        border: `1px solid ${theme.colors.blue[6]}`,
        pointerEvents: 'none',
      },
    },

    resizeHandle: {
      ref: getStylesRef('resizeHandle'),
      position: 'absolute',
      display: 'none',
      right: -20,
      zIndex: 10,
      width: '9px',
      height: '6em',
      cursor: 'col-resize',
      top: `calc(50% - 3em)`,
      borderRadius: theme.radius.xs,
      border: `1px solid ${theme.colors.gray[5]}`,
      backgroundColor: theme.colors.dark[9],
      opacity: '0.5',
    },

    mediaActions: {
      ref: getStylesRef('mediaActions'),
      display: 'none',
      zIndex: 10,
      overflow: 'hidden',
      position: 'absolute',
      top: -30,
      right: theme.spacing.xs,
      backgroundColor: theme.white,
      border: `1px solid ${theme.colors.gray[7]}`,
      borderRadius: theme.radius.xs,
      boxShadow: theme.shadows.xl,
    },
  }),
);
