import { useState } from 'react';
import { Prism } from '@/shared/design-system/v2';

const formatXml = (xml: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'application/xml');
  return new XMLSerializer().serializeToString(xmlDoc);
};

interface XMLPreviewProps {
  file: File;
}

export const XMLPreview = ({ file }: XMLPreviewProps) => {
  const [xmlContent, setXmlContent] = useState('');

  const reader = new FileReader();
  reader.onload = e => {
    if (!e.target) {
      return;
    }

    const text = e.target.result;
    setXmlContent(formatXml(text as string));
  };
  reader.readAsText(file);

  return <Prism language="markup">{xmlContent}</Prism>;
};
