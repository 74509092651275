import noop from 'lodash/noop';
import { useState } from 'react';
import { ConnectorV2Type } from '@/main/generated/api';
import { Alert, Button, Text, Vertical, openModal } from '@/shared/design-system/v2';
import { ReadmeAPIModal } from './ReadmeAPIModal';
import {
  connectorTypeToResourceTypeMap,
  defaultAPIKeyConnector,
  getConnectorTypeDisplayName,
} from './utils';

interface ConnectorAPIModalProps {
  connectorType: ConnectorV2Type;
  onChange: (value: string) => void;
  isOpenInForm?: boolean;
}

export const ConnectorAPIModal = ({
  connectorType,
  onChange,
  isOpenInForm,
}: ConnectorAPIModalProps) => {
  const [error, setError] = useState('');

  if (connectorType === ConnectorV2Type.Readme) {
    return (
      <ReadmeAPIModal
        connectorType={connectorType}
        error={error}
        onChange={onChange}
        setError={setError}
        isOpenInForm={isOpenInForm}
      />
    );
  }
  // add new API Key connector modals here

  //This is a default implementation. If such an UI appears, you need to modify this code.
  return (
    <Vertical spacing="sm">
      {error ? (
        <Alert color="red">
          Seems like there was an issue authorizing your credentials. Please try again!
        </Alert>
      ) : null}
      <Text variant="bodyShort01">
        Connect your {getConnectorTypeDisplayName(defaultAPIKeyConnector)} $
        {connectorTypeToResourceTypeMap[defaultAPIKeyConnector]} to get started
      </Text>
      <Button onClick={noop} variant="primary" w="240px">
        Connect to {getConnectorTypeDisplayName(defaultAPIKeyConnector)}
      </Button>
    </Vertical>
  );
};

export const useConnectorAPIModal = (onChange: (value: string) => void) => {
  const open = (connectorType: ConnectorV2Type) =>
    openModal({
      title: (
        <>
          <Text variant="subTitle01">
            Add new {getConnectorTypeDisplayName(connectorType)} connection
          </Text>
          <Text variant="bodyShort03">
            Connect your {getConnectorTypeDisplayName(connectorType)} account to get started
          </Text>
        </>
      ),
      size: 'xl',
      shadow: 'xl',
      children: <ConnectorAPIModal connectorType={connectorType} onChange={onChange} />,
    });

  return {
    open,
  };
};
