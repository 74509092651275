import { color } from 'highcharts';
import memoize from 'lodash/memoize';

const tablue10 = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
];

const tablue20 = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
];

const opacity = 0.8;

export const getColors = memoize(
  (n: number, alpha?: number) => {
    const colorScheme = n <= 10 ? tablue10 : tablue20;

    if (!alpha) {
      return colorScheme;
    }

    return colorScheme.map(c =>
      color(c)
        .setOpacity(alpha ?? opacity)
        .get(),
    ) as string[];
  },
  (n: number, alpha = 0) => `${n}.${alpha}`,
);

export const getBaseColors = () => tablue10;

// This will generate a factor by which to repeated brighten a base color, in order to generate a
// palette of similar color variants (with colorsRequired total colors). The idea is first to
// determine how close to white the base color is, and then to use the remaining "color distance"
// to compute the step size. We don't want the generated colors to reach white though, so there is
// some buffer included as well.
const getBrightenFactor = (baseColor: string, colorsRequired: number): number => {
  if (colorsRequired < 2) {
    return 0.1;
  }
  const color = baseColor.startsWith('#') ? baseColor.slice(1) : baseColor;
  const num = parseInt(color, 16);
  const whiteNum = 16 ** 6 - 1;
  const brightnessRatio = num / whiteNum;
  // Don't take colors all the way to white, only up to 75% of the way
  const remaining = 0.85 * (1 - brightnessRatio);
  return remaining / colorsRequired;
};

export const getBrightenedColors = (baseColor: string, colorsRequired: number): string[] => {
  const colors = [];
  // Take no. of colors into consideration to determine the brighten factor
  const COLOR_BRIGHTEN_FACTOR = getBrightenFactor(baseColor, colorsRequired);

  for (let i = 0; i < colorsRequired; i++) {
    colors.push(
      color(baseColor)
        .brighten(i * COLOR_BRIGHTEN_FACTOR)
        .get(),
    );
  }

  return colors as string[];
};
