import { PostUrl, Urls } from '@/main/generated/api';
import { CompleteParams } from '@/main/queries/data-upload';

export const BATCH_SIZE = 10;

export enum UploadState {
  UPLOAD_QUEUED = 'Queued',
  UPLOAD_STARTED = 'Uploading',
  UPLOAD_ERRORED = 'Errored',
  UPLOAD_SUCCESS = 'Success',
}

export interface FileData {
  file: File;
  uploadParams: Urls | PostUrl;
  state?: UploadState;
  progress?: number;
  error?: string;
}

export interface DataUploadDetails {
  id: string;
  fileData: FileData[];
  state: UploadState;
  useUploadWindow?: boolean;
  additionalData?: Record<string, unknown>;
  onFileUploadComplete?: (completeParams: CompleteParams) => Promise<void>;
  options?: UploadOptions;
}

export interface UploadOptions {
  useUploadWindow?: boolean;
  additionalData?: Record<string, unknown>;
  onFileUploadComplete: (completeParams: CompleteParams) => Promise<void>;
  onSuccess: () => void;
  onError: (err: string) => void;
  fetchPresignedUrls: (files: File[]) => Promise<FileData[]>;
  uploadFunction?: (
    params: { file: File; urlResponse: Urls | PostUrl },
    onComplete: (completeParams: CompleteParams) => Promise<void>,
  ) => Promise<void>;
}

export interface FileUploadHook {
  uploads: DataUploadDetails[];
  addUpload: (id: string, files: File[], options: UploadOptions) => void;
  abortUpload: (id: string) => void;
  removeUpload: (id: string) => void;
}

export interface FileUploadContextProps {
  uploads: DataUploadDetails[];
  addUpload: (id: string, files: File[], options: UploadOptions) => void;
  abortUpload: (id: string) => void;
  removeUpload: (id: string) => void;
  windowUploads: DataUploadDetails[];
}

export interface UploadStatus {
  completedCount: number;
  totalCount: number;
  percentComplete: number;
  hasErrors: boolean;
  isInProgress: boolean;
}
