import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import highchartsBoost from 'highcharts/modules/boost';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import highchartsTreemap from 'highcharts/modules/treemap';
import highchartsWordCloud from 'highcharts/modules/wordcloud';
import '../chart.css';

more(Highcharts);
highchartsWordCloud(Highcharts);
highchartsHeatmap(Highcharts);
highchartsTreemap(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsBoost(Highcharts);

const maxFontSize = 60;

// Include this snippet after loading Highcharts and before Highcharts.chart is executed.
// For Wordcloud, we define word fonts scale from 14px to 60px, so that words of all
// ranges can be read appropriately.
(Highcharts as any).seriesTypes.wordcloud.prototype.deriveFontSize = function (
  relativeWeight: any,
) {
  return Math.floor(maxFontSize * relativeWeight + 8);
};

export { Highcharts, HighchartsReact };
