import get from 'lodash/get';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import { Switch, Vertical } from '@/shared/design-system/v2';
import { EXTRA_DATA_FIELD } from './util';

interface TagFormFieldSwitchWrapperProps {
  path: string;
  onChange: (path: string, value: any) => void;
  formData: any;
  showFieldSelector?: boolean;
}

export const TagFormFieldSwitchWrapper = ({
  path,
  onChange,
  formData,
  children,
  showFieldSelector = false,
}: PropsWithChildren<TagFormFieldSwitchWrapperProps>): JSX.Element => {
  const existingFields = get(formData, EXTRA_DATA_FIELD, []) as string[];
  const isEnabled = existingFields.includes(path);
  const [val, setVal] = useState<boolean>(isEnabled);

  const onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setVal(isChecked);

    if (isChecked) {
      onChange(EXTRA_DATA_FIELD, [...existingFields, path]);
      return;
    }

    onChange(
      EXTRA_DATA_FIELD,
      existingFields.filter(item => item !== path),
    );
  };

  if (!showFieldSelector) {
    return <>{children}</>;
  }

  return (
    <Vertical>
      {children}
      <Switch
        checked={val}
        onChange={onSwitchChange}
        label="Enable input for this field in the app"
      />
    </Vertical>
  );
};
