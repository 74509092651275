import { ConnectorV2Type } from '@/main/generated/api';
import { useGetConsentUrl } from '@/main/queries/connectors/connector-oauth-v2';
import { useInvalidateConnectionsList } from '@/main/queries/connectors/v2/connectors';
import { logger } from '@/shared/initializers/logging';
import { redirectToProviderConsent } from '@/shared/lib/oauth/oauth';
import { getConnectorTypeDisplayName } from '../connectors/modals/connectors-v2/utils';

export const useOAuthFlow = (
  redirectPath: string = window.location.pathname + window.location.search,
) => {
  const invalidateConnectorsList = useInvalidateConnectionsList();
  const { mutateAsync: getConsent, isLoading } = useGetConsentUrl(
    redirectPath || window.location.pathname + window.location.search,
  );
  const handleProviderConsent = async (connectorType: ConnectorV2Type) => {
    try {
      const url = await getConsent(connectorType);

      await redirectToProviderConsent(url);
      invalidateConnectorsList();
    } catch (e) {
      logger.error(`Failed to authorize ${getConnectorTypeDisplayName(connectorType)} account`);
    }
  };
  return {
    handleProviderConsent,
    isLoading,
  };
};
