import { useState } from 'react';
import { StorageType } from '@/main/generated/api';
import { useInvalidateConnectorsList } from '@/main/queries/connectors';
import { Alert, Button, Text, Vertical, closeAllModals } from '@/shared/design-system/v2';
import { redirectToProviderConsent } from '@/shared/lib/oauth/oauth';
import { useGetConsentUrl } from '../../../queries/connectors/connector-oauth';
import { getConnectorTypeDisplayName } from '../util';
import { GongOauthModal } from './GongOauthModal';
import { NotionOauthModal } from './NotionOauthModal';

interface ConnectorOauthModalProps {
  onClickBack?: any;
  onCreate?: (connectorId: string) => void;
  connectorType?: StorageType;
  onClose?: () => void;
  returnTo?: string;
}

export const ConnectorOauthModal = ({ connectorType }: ConnectorOauthModalProps) => {
  const [error, setError] = useState('');
  const invalidateConnectorsList = useInvalidateConnectorsList();
  const { mutateAsync: getConsent } = useGetConsentUrl(
    connectorType ?? StorageType.GoogleDrive,
    window.location.pathname + window.location.search,
  );

  const handleProviderConsent = async () => {
    try {
      if (!connectorType) {
        throw new Error('Connector type is required but not provided.');
      }
      const url = await getConsent();

      await redirectToProviderConsent(url);
      invalidateConnectorsList();
    } catch (e) {
      setError(`Failed to authorize ${getConnectorTypeDisplayName(connectorType)} account`);
    }

    closeAllModals();
  };

  if (connectorType === StorageType.Notion) {
    return (
      <NotionOauthModal
        handleConnect={handleProviderConsent}
        connectorType={connectorType}
        error={error}
      />
    );
  }
  if (connectorType === StorageType.Gong) {
    return (
      <GongOauthModal
        handleConnect={handleProviderConsent}
        connectorType={connectorType}
        error={error}
      />
    );
  }
  // add upcoming oauth connector modals here

  return (
    <Vertical>
      <Alert color="yellow">
        When you connect your Google account with Markov, everyone your workspace will be able to
        add any file or folder within your account as a source.
      </Alert>
      {error ? (
        <Alert color="red">
          Seems like there was an issue authorizing your account. Please try again!
        </Alert>
      ) : null}
      <Text variant="bodyShort01">
        Connect your {getConnectorTypeDisplayName(connectorType)} account to get started
      </Text>
      <Button onClick={handleProviderConsent} variant="primary" w="240px">
        Connect to {getConnectorTypeDisplayName(connectorType)}
      </Button>
    </Vertical>
  );
};
