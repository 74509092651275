import isObject from 'lodash/isObject';
import { CopyToClipboardTooltip } from '@/main/components/common/CopyToClipboardTooltip';
import {
  Horizontal,
  MarkdownPreview,
  Spoiler,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { CopyCompassButton } from './open-in-copy-compass/CopyCompassButton';

interface RowPreviewProps {
  data: any;
  schema: {
    name: string;
    type: string;
    placeholder?: boolean;
  }[];
  isRunsPreview?: boolean;
  placeholderText?: string;
  workflowId?: string;
  workflowRunId?: string;
  copyCompassColumn?: string;
}

export const RowPreview = ({
  data,
  schema,
  isRunsPreview = false,
  placeholderText = 'This data would be available in the runs preview',
  workflowId,
  workflowRunId,
  copyCompassColumn,
}: RowPreviewProps): JSX.Element => {
  const theme = useMarkovTheme();

  return (
    <Vertical spacing="sm" h="100%">
      {schema
        .filter(col => col.name in data)
        .map((col, index) => {
          const value = data[col.name] ?? '-';
          const displayValue = isObject(value) ? JSON.stringify(value) : value.toString();

          // Only show "Save to CopyCompass" button if fields include "content" and both IDs are present
          const showSaveToCopyCompassCta =
            copyCompassColumn &&
            col.name.includes(copyCompassColumn) &&
            workflowRunId &&
            workflowId;

          return (
            <Vertical
              key={index}
              mb="xl"
              sx={{
                borderBottom:
                  index !== schema.filter(col => col.name in data).length - 1
                    ? `1px solid ${theme.colors.gray[2]}`
                    : 'none',
              }}
            >
              <Horizontal noWrap>
                <Text variant="overline" color="gray.9" tt="uppercase">
                  {col.name}
                </Text>
                <CopyToClipboardTooltip valueToCopy={displayValue} />

                {showSaveToCopyCompassCta && (
                  <CopyCompassButton
                    content={displayValue}
                    workflowId={workflowId}
                    workflowRunId={workflowRunId}
                  />
                )}
              </Horizontal>
              <Spoiler maxHeight={500} showLabel="Show more" hideLabel="Hide">
                {col.placeholder && !isRunsPreview ? (
                  <Text variant="bodyLong03" color="gray.9">
                    {placeholderText}
                  </Text>
                ) : (
                  <MarkdownPreview>{displayValue}</MarkdownPreview>
                )}
              </Spoiler>
            </Vertical>
          );
        })}
    </Vertical>
  );
};
