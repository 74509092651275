import noop from 'lodash/noop';
import { ChatConversationViewModel } from '@/main/components/common/chat-with-data/chat/conversation/utils';
import {
  ChartType,
  DataSourceConversationModel,
  QuestionTypes,
  WidgetType,
} from '@/main/generated/api';
import { useEditGeneratedSqlMutation } from '@/main/queries/data-analytics';
import { logger } from '@/shared/initializers/logging';
import { AnswerView } from './AnswerView';

interface AnswerViewContainerProps {
  resourceId: string;
  conversation: ChatConversationViewModel;
  onExecuteSql?: (sqlQuery: string) => void;
  onAddVizToDashboard?: (
    answer: DataSourceConversationModel,
    vizType?: WidgetType,
    chartType?: ChartType,
  ) => void;
  isExecuteDisabled?: boolean;
  isLatestConversation: boolean;
  loadingVizInsert?: boolean;
  onAddToWidget?: (val: string) => void;
}

export const AnswerViewContainer = ({
  resourceId,
  conversation,
  onExecuteSql = noop,
  onAddVizToDashboard,
  isExecuteDisabled,
  isLatestConversation,
  loadingVizInsert,
  onAddToWidget,
}: AnswerViewContainerProps) => {
  const { isLoading: isAnswerUpdateInProgress, mutateAsync: editSql } = useEditGeneratedSqlMutation(
    resourceId,
    conversation.threadId,
    conversation.conversationId,
  );

  const handleAnswerEdit = async (editedAnswer: string) => {
    if (conversation.questionType !== QuestionTypes.Text2sql) {
      const error_message = `Edit answer functionality is not present for question type: ${conversation.questionType}`;
      logger.error(error_message);
      return;
    }
    await editSql(editedAnswer);
  };

  return (
    <AnswerView
      resourceId={resourceId}
      conversation={conversation}
      onExecuteSql={onExecuteSql}
      onAddVizToDashboard={onAddVizToDashboard}
      isExecuteDisabled={isExecuteDisabled}
      isLatestConversation={isLatestConversation}
      handleAnswerEdit={handleAnswerEdit}
      isAnswerUpdateInProgress={isAnswerUpdateInProgress}
      loadingVizInsert={loadingVizInsert}
      onAddToWidget={onAddToWidget}
    />
  );
};
