import { ControlProps, RankedTester, and, optionIs, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box, Horizontal, Text } from '@/shared/design-system/v2';
import { APIKeySelectControlWrapper } from './APIKeySelectControl.wrapper';

export const APIKeySelect = ({
  label,
  description,
  handleChange,
  path,
  required,
  schema,
  uischema,
  visible,
  enabled,
  data,
  config,
  errors,
}: ControlProps) => {
  const isViewOnlyForm = config.viewOnly;

  const uiSchemaOptions = uischema.options;
  const connectorType = uiSchemaOptions?.secretFormOptions.connectionType;

  if (!visible) {
    return null;
  }

  const handleChangeConnectionSelector = (value: string) => {
    handleChange(path, value);
  };

  if (isViewOnlyForm) {
    // implement
  }

  return (
    <Box pb={10}>
      <Horizontal>
        <Text span variant="subTitle02" color="gray.9" pb={2}>
          {label}
        </Text>
        {required && (
          <Text span variant="subTitle02" color="red.7">
            *
          </Text>
        )}
      </Horizontal>
      {description && (
        <Text span variant="small01" color="gray.7" pb={30}>
          {description}
        </Text>
      )}
      <APIKeySelectControlWrapper
        connectorType={connectorType}
        value={data}
        onChange={handleChangeConnectionSelector}
        isViewOnlyForm={isViewOnlyForm}
      />
    </Box>
  );
};

export const apiKeySelectControlTester: RankedTester = rankWith(
  8,
  and(uiTypeIs('Control'), optionIs('secretFormSelection', true)),
);

export const APIKeySelectControl = withJsonFormsControlProps(APIKeySelect);
