import { IconArrowLeft } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import { ReactNode } from 'react';
import { Alert, Box, Button, Center, Loader, Vertical } from '@/shared/design-system/v2';
import { toCommaSeparatedList } from '@/shared/lib/ui';
import {
  ResourceKey,
  ResourceRequest,
  ResourceResponse,
  StorageType,
} from '../../../generated/api';
import { useGetConnectorResourcesQuery } from '../../../queries/connectors';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { ResourceActionsParams } from '../connector-details/actions/ResourceActions';
import { ResourceItem } from './ResourceItem';
import { defaultSupportedFormats, isFileSupported } from './util';

const getTooltipText = (
  resource: ResourceResponse,
  connectorType: StorageType,
  leafSelectable: boolean,
) => {
  if (resource.key !== ResourceKey.S3File || connectorType !== StorageType.S3) {
    return '';
  }
  const isUnsupportedFileType = !isFileSupported(resource.value);
  if (isUnsupportedFileType) {
    return 'These file types are not supported to be added as a source';
  }
  if (!leafSelectable) {
    return 'Individual files cannot be added for the selected input type. Please select parent folder';
  }
  return '';
};

interface ConnectorResourcesContainerProps {
  connectorId: string;
  connectorType: StorageType;
  resourcesHierarchy: ResourceRequest[];
  onBacktrack: () => void;
  onExploreResource: (filter: ResourceRequest) => void;
  onSaveResource?: (resourceId: string) => void;
  getResourceActions?: (params: ResourceActionsParams) => ReactNode;
  leafSelectable?: boolean;
  supportedFiletypes?: string[];
}

export const ConnectorResourcesContainer = ({
  connectorId,
  connectorType,
  resourcesHierarchy,
  onBacktrack,
  onExploreResource,
  onSaveResource,
  getResourceActions,
  leafSelectable = true,
  supportedFiletypes = defaultSupportedFormats,
}: ConnectorResourcesContainerProps): JSX.Element => {
  const resourcesQuery = useGetConnectorResourcesQuery(connectorId, resourcesHierarchy);

  if (resourcesQuery.isLoading || resourcesQuery.isFetching) {
    return (
      <Center h={200}>
        <Loader />
      </Center>
    );
  }

  if (resourcesQuery.isError) {
    return (
      <Box h={300}>
        <ErrorState title="Error retrieving resources" />
      </Box>
    );
  }

  const resources = resourcesQuery.data;

  if (isEmpty(resources)) {
    return (
      <Center h={180}>
        <EmptyState
          title="No resources found"
          button={
            // Hide backtrack button for top-level of connector
            isEmpty(resourcesHierarchy) ? undefined : (
              <Button onClick={onBacktrack} leftIcon={<IconArrowLeft />}>
                Go back
              </Button>
            )
          }
        />
      </Center>
    );
  }

  let hasSomeUnsupportedFiles = false;
  if (leafSelectable && connectorType === StorageType.S3) {
    hasSomeUnsupportedFiles = resources.some(
      value =>
        value.key === ResourceKey.S3File && !isFileSupported(value.value, supportedFiletypes),
    );
  }

  const supportedFiletypesText = toCommaSeparatedList(
    supportedFiletypes.map(type => type.toUpperCase()),
  );

  return (
    <Vertical spacing="xs">
      {hasSomeUnsupportedFiles ? (
        <Alert color="yellow" radius="xs" mb="sm">
          Filetypes supported: {supportedFiletypesText}
        </Alert>
      ) : null}
      {resources.map(resource => {
        const actions = getResourceActions?.({
          connectorId,
          connectorType,
          resource,
          resourcesHierarchy,
          onResourceAdd: onSaveResource,
        });
        const tooltipText = connectorType
          ? getTooltipText(resource, connectorType, leafSelectable)
          : '';
        return (
          <ResourceItem
            key={resource.value}
            resource={resource}
            onExploreResource={onExploreResource}
            actions={actions}
            tooltipText={tooltipText}
          />
        );
      })}
    </Vertical>
  );
};
