import { IconDownload } from '@tabler/icons-react';
import { useGetS3PresignedUrlForFileQuery } from '@/main/queries/workflows/s3-preview';
import { Alert, Button, Center, Loader, Text, Vertical } from '@/shared/design-system/v2';

interface FallbackFilePreviewProps {
  url: string;
  workflowId: string;
}

export const FallbackFilePreview = ({ url, workflowId }: FallbackFilePreviewProps) => {
  const { isLoading, isError, data: blob } = useGetS3PresignedUrlForFileQuery(workflowId, url);

  if (isLoading) {
    return (
      <Center w="100%" h={400}>
        <Loader />
      </Center>
    );
  }

  if (isError || !blob) {
    return (
      <Center w="100%" h={400}>
        <Alert color="red">Preview is not available.</Alert>
      </Center>
    );
  }

  const handleDownload = () => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = url.split('/').pop() || 'document';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  return (
    <Vertical h={400} align="center" justify="center">
      <Text variant="subTitle02">
        Preview is not available. You can download the file to view it.
      </Text>
      <Button variant="light" onClick={handleDownload} leftIcon={<IconDownload size={16} />}>
        Download Document
      </Button>
    </Vertical>
  );
};
