import { IconAlignCenter, IconAlignLeft, IconAlignRight, IconTrash } from '@tabler/icons-react';
import { NodeViewProps } from '@tiptap/react';
import { ActionIcon, Box } from '../../../core/';
import { useRichTextEditorContext } from '../../../rich-text-editor/index';
import { TextAlignment } from '../paragraph/HTMLParagraph';
import { useResizableMediaStyles } from './ResizableMedia.style';

interface ActionsMenuProps {
  updateAttributes: NodeViewProps['updateAttributes'];
  deleteNode: NodeViewProps['deleteNode'];
  inline: boolean;
}

export const ActionsMenu = ({ updateAttributes, deleteNode, inline }: ActionsMenuProps) => {
  const { editor } = useRichTextEditorContext();
  const { classes } = useResizableMediaStyles({ align: 'start' });

  const alignItem = (align: 'start' | 'center' | 'end') => () => {
    updateAttributes({
      dataAlign: align,
    });

    // When element is inline, align its containing paragraph
    if (inline && editor.commands.setParagraphAlign) {
      const alignment =
        align === 'end'
          ? TextAlignment.right
          : align === 'center'
          ? TextAlignment.center
          : TextAlignment.left;
      editor.commands.setParagraphAlign(alignment);
    }
  };

  return (
    <Box className={classes.mediaActions}>
      <ActionIcon title="Align Left" onClick={alignItem('start')}>
        <IconAlignLeft />
      </ActionIcon>
      <ActionIcon title="Align Center" onClick={alignItem('center')}>
        <IconAlignCenter />
      </ActionIcon>
      <ActionIcon title="Align Right" onClick={alignItem('end')}>
        <IconAlignRight />
      </ActionIcon>
      <ActionIcon title="Delete" onClick={deleteNode}>
        <IconTrash />
      </ActionIcon>
    </Box>
  );
};
