/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { useMutationWithCache } from '@/shared/hooks/useMutationWithCache';
import { HTTPError } from '@/shared/lib/api/api';
import { chatterApi, contentAddOnApi } from '@/shared/lib/api/oslo';
import { appStoreApi, publicAppStoreApi } from '@/shared/lib/api/reno';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AcceptOrDismissCitationSuggestionRequest,
  AddStyleGuideRuleRequest,
  AddUpdateStyleGuideRuleSetRequest,
  AddWordsToCustomDictionaryRequest,
  AnalysePageStatusResponse,
  AppIdType,
  BodyParseAndAddBulkCitationTextV1,
  BodyParseCitationTextV1,
  ChatterRequestModel,
  CheckContentPlagiarismRequestModel,
  CheckContentPlagiarismResponse,
  CitationPositionType,
  CitationStyleGuideType,
  CopyEditCitation,
  CreateOrUpdateCitationsResponse,
  CreateOrUpdateCustomDictionaryRequest,
  DataSourceConversationModel,
  DeleteCitationsResponse,
  DeleteWordsFromCustomDictionaryRequest,
  DocumentStatusType,
  FetchPageSuggestionsResponse,
  GetAllCitationsTextResponse,
  GetCitationsResponse,
  GetDocumentMetadataResponse,
  InternetModel,
  LLMTypes,
  PageSuggestion,
  PageTextResources,
  ParagraphTextSuggestions,
  PlagiarismCheckStatusType,
  PlagiarismCheckerType,
  PlagiarismToCitationLibraryRequest,
  SaveContentRequestBody,
  ScanDocumentPageForCitationsRequest,
  ScanDocumentPageForCitationsStatusType,
  Suggestion,
  SuggestionAnalysisStatus,
  SuggestionState,
  SuggestionStatusType,
  TextToSpeechRequestModel,
  UpdateAnalyseAddInRequest,
  UpdateCitationsRequest,
  UpdateCitationsResponse,
  UpdateDocumentMetadataRequest,
  UpdatePageSuggestions,
  UploadManualTextFileRequest,
} from '../../generated/api';
import {
  ChatResourceTypes,
  onErrorChatWithData,
  onMutateChatWithData,
  onSuccessChatWithData,
} from '../chat-with-data/chat';
import { appStoreKeys, copyEditKeys } from '../queryConstants';
import { getNextPageParamHandler } from '../util';
import { documentAIKeys } from './document';

const APP_CUSTOM_DICTIONARY_PAGE_SIZE = 10;
const STYLE_GUIDE_PAGE_SIZE = 37;

export interface ParagraphSuggestion extends PageSuggestion {
  paragraphId: string;
  suggestion: Suggestion;
}

export const useGetDocumentMetadataQuery = (documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetDocumentMetadataResponse>,
    AxiosError,
    GetDocumentMetadataResponse
  >(
    copyEditKeys.getDocumentMetadata(workspaceId, documentId),
    () => appStoreApi.getDocumentMetadataV1(workspaceId, documentId),
    {
      enabled: Boolean(workspaceId && documentId),
      select: data => data.data,
      refetchInterval: data => {
        if (data?.status !== DocumentStatusType.Finished) {
          return 2 * 1000;
        }
        return false;
      },
    },
  );
};

export const useGetDocumentPageDataQuery = (documentId: string, pageIndex: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getDocumentPageData(workspaceId, documentId, pageIndex),
    () => appStoreApi.getParsedSegmentsV1(workspaceId, documentId, pageIndex),
    {
      enabled: Boolean(workspaceId && documentId && pageIndex !== undefined),
      select: data => data.data,
      cacheTime: 0,
    },
  );
};

export const useInvalidatePageContentQuery = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return (pageIndex: number) => {
    queryClient.invalidateQueries(
      copyEditKeys.getDocumentPageData(workspaceId, documentId, pageIndex),
    );
  };
};

export const useInvalidatePageVersionsQuery = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return (pageIndex?: number) => {
    queryClient.invalidateQueries(
      copyEditKeys.getDocumentPageData(workspaceId, documentId, pageIndex),
    );
    queryClient.invalidateQueries(copyEditKeys.getVersionsList(workspaceId, documentId, pageIndex));
    queryClient.invalidateQueries(
      copyEditKeys.getVersionContent(workspaceId, documentId, pageIndex),
    );
  };
};

/**
 * Updates the state(Pending, Accepted, Declined) of suggestions and
 * returns the updated suggestions array
 */
const getUpdatedSuggestions = (
  suggestions: ParagraphTextSuggestions[],
  latestPageSuggestionState: SuggestionState[],
) => {
  // Create a map for quick lookup of suggestionId to status
  const suggestionStatusMap = new Map<string, SuggestionStatusType>();
  latestPageSuggestionState.forEach(({ suggestionId, status }) => {
    suggestionStatusMap.set(suggestionId, status);
  });

  const newSuggestions = suggestions.map(allSuggestion => ({
    ...allSuggestion,
    pageSuggestions: {
      suggestions: (allSuggestion.pageSuggestions.suggestions ?? []).map(pageSuggestion => {
        const currentSuggestionId = pageSuggestion?.suggestion?.suggestionId;
        const updatedStatus = currentSuggestionId && suggestionStatusMap.get(currentSuggestionId);

        if (!pageSuggestion.suggestion) {
          return pageSuggestion;
        }

        return {
          ...pageSuggestion,
          suggestion: {
            ...pageSuggestion.suggestion,
            ...(updatedStatus ? { status: updatedStatus } : {}),
          },
        };
      }),
    },
  }));
  return newSuggestions;
};

export const useSavePageContentMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    ({ pageId, ...req }: SaveContentRequestBody & { pageId: number }) =>
      appStoreApi.saveContentForSessionV1(workspaceId, documentId, pageId, req),
    {
      onMutate: async (req: SaveContentRequestBody & { pageId: number }) => {
        if (req.latestPageSuggestionState && req.latestPageSuggestionState.length > 0) {
          const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, req.pageId);
          // Cancel any ongoing re-fetches for this query to prevent conflicts
          await queryClient.cancelQueries(queryKey);
          const oldData =
            queryClient.getQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey);

          if (oldData) {
            const suggestions = oldData.data.suggestions;

            const newSuggestions = getUpdatedSuggestions(
              suggestions,
              req.latestPageSuggestionState,
            );

            queryClient.setQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey, {
              ...oldData,
              data: {
                ...oldData.data,
                suggestions: newSuggestions, // Adjusted to match the structure of FetchPageSuggestionsResponse
              },
            });
          }
        }
      },
    },
  );
};

// A new wrapper hook is created for writer,
// because we want to track the state of the save page content mutation in the header
// The save indicator component is a part of the header now
// The existing hook is not updated to prevent regressions in copy-edit in the main app
// When we remove copy edit from the main app, we can remove the useSavePageContentMutation hook
export const useSavePageContentMutationV2 = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutationWithCache({
    mutationKey: copyEditKeys.savePageContent(workspaceId, documentId),
    mutationFn: ({ pageId, ...req }: SaveContentRequestBody & { pageId: number }) =>
      appStoreApi.saveContentForSessionV1(workspaceId, documentId, pageId, req),
    onMutate: async (req: SaveContentRequestBody & { pageId: number }) => {
      if (req.latestPageSuggestionState && req.latestPageSuggestionState.length > 0) {
        const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, req.pageId);
        // Cancel any ongoing re-fetches for this query to prevent conflicts
        await queryClient.cancelQueries(queryKey);
        const oldData =
          queryClient.getQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey);

        if (oldData) {
          const suggestions = oldData.data.suggestions;

          const newSuggestions = getUpdatedSuggestions(suggestions, req.latestPageSuggestionState);

          queryClient.setQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey, {
            ...oldData,
            data: {
              ...oldData.data,
              suggestions: newSuggestions, // Adjusted to match the structure of FetchPageSuggestionsResponse
            },
          });
        }
      }
    },
  });
};

export const useUpdateSuggestionStateMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdatePageSuggestions & { pageId?: number }) =>
      appStoreApi.updateSuggestionStatusV1(workspaceId, documentId, payload),
    onSuccess: async (_, { latestPageSuggestionState, pageId }) => {
      if (latestPageSuggestionState && latestPageSuggestionState.length > 0) {
        const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, pageId ?? 0);
        // Cancel any ongoing re-fetches for this query to prevent conflicts
        await queryClient.cancelQueries(queryKey);
        const oldData =
          queryClient.getQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey);

        if (oldData) {
          const suggestions = oldData.data.suggestions;

          const newSuggestions = getUpdatedSuggestions(suggestions, latestPageSuggestionState);

          queryClient.setQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey, {
            ...oldData,
            data: {
              ...oldData.data,
              suggestions: newSuggestions, // Adjusted to match the structure of FetchPageSuggestionsResponse
            },
          });
        }
      }
    },
  });
};

export const useFetchPageStatsMutation = (appId: AppIdType) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((pageText: string) =>
    appStoreApi.getDocumentStatsForTextSelectionV1(appId, workspaceId, { text: pageText }),
  );
};

export const usePublicFetchTextStatsQuery = (text: string) =>
  useQuery({
    queryKey: copyEditKeys.getPublicTextStats(text),
    queryFn: () => publicAppStoreApi.getStatsForTextPublicV1({ text }),
    enabled: !!text,
  });

export const useFetchCustomRulesListQuery = (ruleSetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getCustomRulesList(workspaceId, ruleSetId),
    () => appStoreApi.fetchStyleGuideRulesV1(workspaceId, ruleSetId),
    {
      select: data => data.data.styleGuideRules,
    },
  );
};

export const useFetchRulesSetListQuery = (variant: AppIdType = AppIdType.CopyEdit) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getRulesSetsList(workspaceId),
    () => appStoreApi.fetchStyleGuideRuleSetsV1(workspaceId),
    {
      select: data => data.data.ruleSetList,
      enabled: [AppIdType.CopyEdit, AppIdType.Summarization].includes(variant),
    },
  );
};

export const useDeleteStyleGuideRulesetMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (ruleSetId: string) => appStoreApi.deleteStyleGuideRuleSetV1(workspaceId, ruleSetId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getRulesSetsList(workspaceId));
      },
    },
  );
};

export const useAddEditStyleGuideRulesetMutation = (ruleSetId?: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (addRulesetReq: AddUpdateStyleGuideRuleSetRequest) =>
      appStoreApi.addUpdateStyleGuideRuleSetV1(workspaceId, {
        ...addRulesetReq,
        recordId: ruleSetId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getRulesSetsList(workspaceId));
      },
    },
  );
};

export const useDeleteStyleGuideCustomRuleMutation = (ruleSetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (ruleId: string) => appStoreApi.deleteStyleGuideRuleV1(workspaceId, ruleSetId, ruleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getCustomRulesList(workspaceId, ruleSetId));
        // Invalidating ruleset list as well to make sure count is updated
        queryClient.invalidateQueries(copyEditKeys.getRulesSetsList(workspaceId));
      },
    },
  );
};

export const useManualTextUploadMutation = (appId: AppIdType, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UploadManualTextFileRequest) =>
      appStoreApi.uploadManualTextFileRequestV1(workspaceId, projectId, appId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(appStoreKeys.list(workspaceId, appId));
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
        );
      },
    },
  );
};

export const useAddEditStyleGuideCustomRuleMutation = (ruleSetId: string, ruleId?: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (addCustomRuleReq: AddStyleGuideRuleRequest) =>
      appStoreApi.addUpdateStyleGuideRuleV1(workspaceId, ruleSetId, {
        ...addCustomRuleReq,
        recordId: ruleId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getCustomRulesList(workspaceId, ruleSetId));
        // Invalidating ruleset list as well to make sure count is updated
        queryClient.invalidateQueries(copyEditKeys.getRulesSetsList(workspaceId));
      },
    },
  );
};

export const useGetTextToSpeechMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: TextToSpeechRequestModel) =>
    contentAddOnApi.textToSpeechV1(workspaceId, req),
  );
};

export const useFetchVersionsListQuery = (documentId: string, pageIndex?: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getVersionsList(workspaceId, documentId, pageIndex),
    () => appStoreApi.fetchNumOfVersionsOfPageV1(workspaceId, documentId, pageIndex!),
    {
      select: data => data.data.numAvailableVersions ?? 0,
      enabled: Boolean(workspaceId && documentId && pageIndex !== undefined),
      cacheTime: 0,
    },
  );
};

export const useFetchVersionContentQuery = (
  documentId: string,
  pageIndex?: number,
  versionIndex?: number,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getVersionContent(workspaceId, documentId, pageIndex, versionIndex),
    () =>
      appStoreApi.fetchPageVersionContentV1(workspaceId, documentId, pageIndex!, versionIndex ?? 0),
    {
      select: data => data.data.pageContent,
      enabled: Boolean(
        workspaceId && documentId && versionIndex !== undefined && pageIndex !== undefined,
      ),
      cacheTime: 0,
    },
  );
};

export const useRestoreVersionMutation = (documentId: string, pageIndex?: number) => {
  const { workspaceId } = useAppMetadata();
  const invalidatePageVersions = useInvalidatePageVersionsQuery(documentId);

  return useMutation(
    (versionId: number) => {
      if (pageIndex === undefined) {
        return Promise.reject(new Error('Invalid pageIndex'));
      }
      return appStoreApi.restoreVersionForDocV1(workspaceId, documentId, pageIndex, versionId);
    },
    {
      onSuccess: () => {
        invalidatePageVersions(pageIndex);
      },
    },
  );
};

export const useGetContentPlagiarismResultQuery = (documentId: string, pageIndex: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<CheckContentPlagiarismResponse>,
    AxiosError<{ detail: string }>,
    CheckContentPlagiarismResponse
  >(
    copyEditKeys.getContentPlagiarismResult(workspaceId, documentId, pageIndex),
    () => appStoreApi.getContentPlagiarismResultV1(workspaceId, documentId, pageIndex),
    {
      select: res => res.data,
      refetchInterval: data => {
        if (data?.status === PlagiarismCheckStatusType.Pending) {
          return 5 * 1000;
        }
        return false;
      },
    },
  );
};

export const useCheckContentPlagiarismMutation = (documentId: string, pageIndex: number) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CheckContentPlagiarismRequestModel) =>
      appStoreApi.checkContentPlagiarismV1(workspaceId, documentId, pageIndex, {
        ...req,
        checkerType: req.checkerType ?? PlagiarismCheckerType.Copyleaks,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          copyEditKeys.getContentPlagiarismResult(workspaceId, documentId, pageIndex),
        );
      },
    },
  );
};

export const useGetAnalyticsCopyEditQuery = (appVariant: AppIdType, days: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.analytics(workspaceId, appVariant, days),
    () => appStoreApi.analyticsCopyEditV1(workspaceId, appVariant, { days }),
    {
      select: res => res.data,
    },
  );
};

export const useAddBulkRuleUploadMutation = (ruleSetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation((file: any) => appStoreApi.bulkUploadRulesV1(workspaceId, ruleSetId, file), {
    onSuccess: () => {
      queryClient.invalidateQueries(copyEditKeys.getCustomRulesList(workspaceId, ruleSetId));
      queryClient.invalidateQueries(copyEditKeys.getRulesSetsList(workspaceId));
    },
  });
};

export const useGetPageSuggestionsQuery = (documentId: string, pageIndex?: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getPageSuggestions(workspaceId, documentId, pageIndex),
    () => appStoreApi.fetchPageSuggestionsV1(workspaceId, pageIndex ?? 0, documentId),
    {
      enabled: Boolean(workspaceId && documentId && pageIndex !== undefined),
      select: data => data.data,
      refetchInterval: data => {
        if (data?.status === SuggestionAnalysisStatus.Success) {
          return false; // Stop polling
        }
        return 5 * 1000; // Poll every 5 secs
      },
    },
  );
};

export const useAnalysePageContentMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    ({ pageIndex, ...req }: PageTextResources & { pageIndex: number }) =>
      appStoreApi.analyzeTextsV1(workspaceId, pageIndex, documentId, req),
    {
      onMutate: async ({ pageIndex }) => {
        const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, pageIndex);
        // Cancel any ongoing re-fetches for this query to prevent conflicts
        await queryClient.cancelQueries(queryKey);

        const previousData =
          queryClient.getQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey);

        if (previousData) {
          queryClient.setQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey, {
            ...previousData,
            data: {
              ...previousData.data,
              status: SuggestionAnalysisStatus.Processing,
            },
          });
        }
      },
      onSuccess: (_, { pageIndex }) => {
        const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, pageIndex);
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
};

export const useUpdateAndAnalyseMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: UpdateAnalyseAddInRequest & { pageIndex?: number }) =>
      appStoreApi.updateAndAnalyseV1(workspaceId, req),
    onMutate: async ({ pageIndex = 0, documentId }) => {
      const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, pageIndex);
      // Cancel any ongoing re-fetches for this query to prevent conflicts
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey);

      if (previousData) {
        queryClient.setQueryData<AxiosResponse<FetchPageSuggestionsResponse>>(queryKey, {
          ...previousData,
          data: {
            ...previousData.data,
            status: SuggestionAnalysisStatus.Processing,
          },
        });
      }
    },
    onSuccess: (_, { pageIndex = 0, documentId }) => {
      const queryKey = copyEditKeys.getPageSuggestions(workspaceId, documentId, pageIndex);
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useGetPageSuggestionsFastMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation(
    copyEditKeys.getPageSuggestionsFast(workspaceId),
    ({ pageIndex, pageText }: { pageIndex: number; pageText: string }) =>
      appStoreApi.fetchPageSuggestionsFastV1(workspaceId, pageIndex, { page_text: pageText }),
  );
};

export const useCopyEditChatWithDataMutation = (
  documentId: string,
  threadId: string,
  llmType?: LLMTypes,
) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  const resourceType = ChatResourceTypes.COPY_EDIT;

  return useMutation<
    AxiosResponse<DataSourceConversationModel, unknown>,
    AxiosError<HTTPError>,
    ChatterRequestModel
  >(
    (chatWithDataRequestModel: ChatterRequestModel) =>
      chatterApi.chatWithCopyEditV1(workspaceId, documentId, chatWithDataRequestModel, llmType),
    {
      onMutate: onMutateChatWithData(queryClient, workspaceId, documentId, threadId, resourceType),
      onSuccess: onSuccessChatWithData(
        queryClient,
        workspaceId,
        documentId,
        threadId,
        resourceType,
      ),
      onError: onErrorChatWithData(queryClient, workspaceId, documentId, threadId, resourceType),
    },
  );
};

export const useParseCitationMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation<
    AxiosResponse<CopyEditCitation, unknown>,
    AxiosError<HTTPError>,
    BodyParseCitationTextV1
  >((req: BodyParseCitationTextV1) => appStoreApi.parseCitationTextV1(workspaceId, req));
};

export const useGetAllCitationsQuery = (
  documentId: string,
  searchFilter?: string,
  citationIds?: string[],
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetCitationsResponse>, AxiosError, GetCitationsResponse>(
    copyEditKeys.getDocumentCitations(workspaceId, documentId, citationIds, searchFilter),
    () => appStoreApi.getAllCitationsV1(workspaceId, documentId, citationIds, searchFilter),
    {
      enabled: Boolean(workspaceId && documentId),
      select: data => data.data,
    },
  );
};

export const useGetAllCitationsTextQuery = (
  documentId: string,
  citationFormat: CitationPositionType,
  citationStyle: CitationStyleGuideType,
  citationIds?: string[],
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetAllCitationsTextResponse>,
    AxiosError,
    GetAllCitationsTextResponse
  >(
    copyEditKeys.getDocumentCitationsText(
      workspaceId,
      documentId,
      citationFormat,
      citationStyle,
      citationIds,
    ),
    () =>
      appStoreApi.getAllCitationsTextV1(
        workspaceId,
        documentId,
        citationFormat,
        citationStyle,
        citationIds,
      ),
    {
      enabled: Boolean(workspaceId && documentId),
      select: data => data.data,
    },
  );
};

export const useUpdateCitationsMutation = (documentId: string, pageId: number) => {
  const { workspaceId } = useAppMetadata();

  return useMutation<
    AxiosResponse<UpdateCitationsResponse, unknown>,
    AxiosError<HTTPError>,
    UpdateCitationsRequest
  >((req: UpdateCitationsRequest) =>
    appStoreApi.updateCitationsV1(workspaceId, documentId, pageId, req),
  );
};

export const useCreateOrUpdateCitationsMutation = (documentId: string, pageIndex?: number) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<CreateOrUpdateCitationsResponse, unknown>,
    AxiosError<HTTPError>,
    CopyEditCitation[]
  >(
    (req: CopyEditCitation[]) => appStoreApi.createDocumentCitationV1(workspaceId, documentId, req),
    {
      onSuccess: () => {
        if (pageIndex !== undefined) {
          queryClient.invalidateQueries(
            copyEditKeys.getContentPlagiarismResult(workspaceId, documentId, pageIndex),
          );
        }
        queryClient.invalidateQueries(copyEditKeys.getDocumentCitations(workspaceId, documentId));
        queryClient.invalidateQueries(
          copyEditKeys.getDocumentCitationsText(workspaceId, documentId),
        );
      },
    },
  );
};

export const useDeleteCitationsMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<DeleteCitationsResponse, unknown>,
    AxiosError<HTTPError>,
    string[]
  >(
    (citationIds: string[]) => appStoreApi.deleteCitationsV1(workspaceId, documentId, citationIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getDocumentCitations(workspaceId, documentId));
        queryClient.invalidateQueries(
          copyEditKeys.getDocumentCitationsText(workspaceId, documentId),
        );
        queryClient.invalidateQueries(
          copyEditKeys.getContentPlagiarismResult(workspaceId, documentId),
        );
      },
    },
  );
};

export const useAddPlagiarismSourceToCitationLibraryMutation = (
  documentId: string,
  pageIndex: number,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PlagiarismToCitationLibraryRequest) =>
      appStoreApi.plagiarismToCitationLibraryV1(workspaceId, documentId, req),
    onMutate: async (req: PlagiarismToCitationLibraryRequest) => {
      const oldData = queryClient.getQueryData<
        AxiosResponse<CheckContentPlagiarismResponse, unknown>
      >(copyEditKeys.getContentPlagiarismResult(workspaceId, documentId, pageIndex));

      if (oldData) {
        const plagiarismResponse = oldData.data.plagiarismResponse;

        if (plagiarismResponse) {
          const plagiarismResults = plagiarismResponse.results.internet;

          const updatedResults = plagiarismResults.map((result: InternetModel) => {
            if (result.url === req.plagiarismSourceUrl) {
              return {
                ...result,
                existsInDb: true,
              };
            }
            return result;
          });

          const updatedPlagiarismResponse = {
            ...plagiarismResponse,
            results: {
              ...plagiarismResponse?.results,
              internet: updatedResults,
            },
          };

          //Update the plagiarism results in the cache
          queryClient.setQueryData<AxiosResponse<CheckContentPlagiarismResponse, unknown>>(
            copyEditKeys.getContentPlagiarismResult(workspaceId, documentId, pageIndex),
            {
              ...oldData,
              data: {
                ...oldData.data,
                plagiarismResponse: updatedPlagiarismResponse,
              },
            },
          );
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(copyEditKeys.getDocumentCitations(workspaceId, documentId));
      queryClient.invalidateQueries(copyEditKeys.getDocumentCitationsText(workspaceId, documentId));
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        copyEditKeys.getContentPlagiarismResult(workspaceId, documentId, pageIndex),
      );
    },
  });
};

export const useGetCustomDictionaryListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getDictionaryList(workspaceId),
    () => appStoreApi.getAllDictionaryListV1(workspaceId),
    {
      select: data => data.data.customDictionaries,
      enabled: Boolean(workspaceId),
    },
  );
};

export const useGetCustomDictionaryPaginatedListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    copyEditKeys.getDictionaryListPaginated(workspaceId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * APP_CUSTOM_DICTIONARY_PAGE_SIZE,
        end = start + APP_CUSTOM_DICTIONARY_PAGE_SIZE;
      return appStoreApi.getAllDictionaryListV2(workspaceId, start, end, lastTimestamp);
    },
    {
      getNextPageParam: getNextPageParamHandler(APP_CUSTOM_DICTIONARY_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data),
      }),
      enabled: Boolean(workspaceId),
    },
  );
};

export const useGetStyleGuideRulesPaginatedListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    copyEditKeys.getStyleGuideConfigListPaginated(workspaceId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * STYLE_GUIDE_PAGE_SIZE,
        end = start + STYLE_GUIDE_PAGE_SIZE;
      return appStoreApi.getPaginatedStyleGuideConfigV1(workspaceId, start, end, lastTimestamp);
    },
    {
      getNextPageParam: getNextPageParamHandler(STYLE_GUIDE_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data),
      }),
      enabled: Boolean(workspaceId),
    },
  );
};

export const useAddEditCustomDictionaryMutation = (dictionaryId?: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (req: CreateOrUpdateCustomDictionaryRequest) =>
      appStoreApi.createOrUpdateCustomDictionaryV1(workspaceId, {
        ...req,
        dictionaryId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getDictionaryList(workspaceId));
        queryClient.invalidateQueries(copyEditKeys.getDictionaryListPaginated(workspaceId));
      },
    },
  );
};

export const useDeleteCustomDictionaryMutation = (dictionaryId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(() => appStoreApi.deleteCustomDictionaryV1(workspaceId, dictionaryId), {
    onSuccess: () => {
      queryClient.invalidateQueries(copyEditKeys.getDictionaryList(workspaceId));
      queryClient.invalidateQueries(copyEditKeys.getDictionaryListPaginated(workspaceId));
    },
  });
};

export const useGetCustomDictionaryWordsListQuery = (dictionaryId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getDictionaryWordList(workspaceId, dictionaryId),
    () => appStoreApi.getDictionaryWordsListV1(workspaceId, dictionaryId),
    {
      select: data => data.data,
      enabled: Boolean(workspaceId && dictionaryId),
    },
  );
};

export const useAddCustomDictionaryWordMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: AddWordsToCustomDictionaryRequest) =>
      appStoreApi.addWordsToCustomDictionaryV1(workspaceId, req),
    {
      onSuccess: (_, { dictionaryId }) => {
        queryClient.invalidateQueries(
          copyEditKeys.getDictionaryWordList(workspaceId, dictionaryId),
        );
        queryClient.invalidateQueries(copyEditKeys.getDictionaryList(workspaceId));
        queryClient.invalidateQueries(copyEditKeys.getDictionaryListPaginated(workspaceId));
      },
    },
  );
};

export const useDeleteCustomDictionaryWordMutation = (dictionaryId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: DeleteWordsFromCustomDictionaryRequest) =>
      appStoreApi.deleteCustomDictionaryWordV1(workspaceId, dictionaryId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          copyEditKeys.getDictionaryWordList(workspaceId, dictionaryId),
        );
        queryClient.invalidateQueries(copyEditKeys.getDictionaryList(workspaceId));
        queryClient.invalidateQueries(copyEditKeys.getDictionaryListPaginated(workspaceId));
      },
    },
  );
};

export const useGetPageAnalyseStatusQuery = (documentId: string, pageIdx: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    copyEditKeys.getPageAnalyseStatus(workspaceId, documentId, pageIdx),
    () => appStoreApi.getAnalyseStatusV1(workspaceId, documentId, pageIdx),
    {
      select: data => data.data,
      enabled: Boolean(workspaceId && documentId),
      cacheTime: 0,
    },
  );
};

export const useUpdatePageAnalyseStatusMutation = (documentId: string, pageIdx: number) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: AnalysePageStatusResponse) =>
      appStoreApi.updateAnalyseStatusV1(workspaceId, documentId, pageIdx, req),
    {
      onSuccess: () => {
        const queryKey = copyEditKeys.getPageAnalyseStatus(workspaceId, documentId, pageIdx);
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
};

export interface UseUpdateAppMetadataMutationProps {
  appId: AppIdType;
  projectId?: string;
  documentId: string;
}

export const useUpdateAppMetadataMutation = ({
  appId,
  projectId,
  documentId,
}: UseUpdateAppMetadataMutationProps) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateDocumentMetadataRequest) =>
      appStoreApi.updateDocumentMetadataV1(workspaceId, documentId, req),
    {
      onMutate: (req: UpdateDocumentMetadataRequest) => {
        // Updating citationStyle optimistically
        queryClient.setQueryData<AxiosPromise<GetDocumentMetadataResponse>>(
          copyEditKeys.getDocumentMetadata(workspaceId, documentId),
          (response: any) => ({
            ...response,
            data: {
              ...response.data,
              ...req,
            },
          }),
        );
      },
      onSuccess: () => {
        if (projectId) {
          queryClient.invalidateQueries(
            documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
          );
        }
        queryClient.invalidateQueries(copyEditKeys.getDocumentMetadata(workspaceId, documentId));
        queryClient.invalidateQueries(copyEditKeys.docSuggestions(workspaceId, documentId));
      },
    },
  );
};

export const useAddBulkCitationsTextMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: BodyParseAndAddBulkCitationTextV1) =>
      appStoreApi.parseAndAddBulkCitationTextV1(workspaceId, documentId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getDocumentCitations(workspaceId, documentId));
        queryClient.invalidateQueries(
          copyEditKeys.getDocumentCitationsText(workspaceId, documentId),
        );
      },
    },
  );
};

export const useInvalidateSuggestionsMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (suggestionId: string) => appStoreApi.invalidateSuggestionsV1(workspaceId, suggestionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(copyEditKeys.getPageSuggestions(workspaceId, documentId));
      },
    },
  );
};

export const useGenerateRegexForRuleSetMutation = () =>
  useMutation((queryText: string) => appStoreApi.generateRegexForRuleSetV1(queryText));

export const useGetCitationsForTextQuery = (documentId: string, selectedText: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: copyEditKeys.getCitationsForSelectedText(workspaceId, documentId, selectedText),
    queryFn: () => appStoreApi.findCitationsV1(workspaceId, documentId, selectedText),
    enabled: Boolean(workspaceId && documentId && selectedText),
    select: data => data.data,
  });
};

export const useGetProjectFromDocumentQuery = (documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: copyEditKeys.getProjectFromDocument(workspaceId, documentId),
    queryFn: () => appStoreApi.getProjectFromDocumentV1(workspaceId, documentId),
    enabled: Boolean(workspaceId && documentId),
    select: data => data.data,
  });
};

export const useReTriggerAnalyseMutation = (documentId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(() => appStoreApi.triggerReAnalyzeTextsV1(workspaceId, documentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(copyEditKeys.getPageAnalyseStatus(workspaceId, documentId));
    },
  });
};

export const useScanDocumentPageForCitationsMutation = (documentId: string, pageIndex: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (req: ScanDocumentPageForCitationsRequest) =>
      appStoreApi.scanDocumentPageForCitationsV1(workspaceId, documentId, pageIndex, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          copyEditKeys.getScannedCitationsForDocumentPageStatus(workspaceId, documentId, pageIndex),
        );
        queryClient.invalidateQueries(
          copyEditKeys.getScannedCitationsResultForDocumentPage(workspaceId, documentId, pageIndex),
        );
      },
    },
  );
};

export const useScanDocumentPageForCitationsStatusQuery = (
  documentId: string,
  pageIndex: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: copyEditKeys.getScannedCitationsForDocumentPageStatus(
      workspaceId,
      documentId,
      pageIndex,
    ),
    queryFn: () =>
      appStoreApi.scanDocumentPageForCitationsStatusV1(workspaceId, documentId, pageIndex),
    enabled: Boolean(workspaceId && documentId && pageIndex),
    select: res => res.data,
    refetchInterval: data =>
      data?.scanStatus === ScanDocumentPageForCitationsStatusType.Processing ? 2000 : false,
  });
};

export const useGetScanDocumentPageForCitationsResultQuery = (
  documentId: string,
  pageIndex: string,
) => {
  const { workspaceId } = useAppMetadata();

  const { data } = useScanDocumentPageForCitationsStatusQuery(documentId, pageIndex);
  const resultsAreReady = data?.scanStatus === ScanDocumentPageForCitationsStatusType.Completed;

  return useQuery({
    queryKey: copyEditKeys.getScannedCitationsResultForDocumentPage(
      workspaceId,
      documentId,
      pageIndex,
    ),
    queryFn: () => appStoreApi.getScanResultForDocumentPageV1(workspaceId, documentId, pageIndex),
    enabled: Boolean(workspaceId && documentId && pageIndex && resultsAreReady),
    select: res => res.data,
    staleTime: 0,
  });
};

export const useUpdateCitationSuggestionStatusMutation = (
  documentId: string,
  pageIndex: string,
) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (req: AcceptOrDismissCitationSuggestionRequest) =>
      appStoreApi.acceptOrDismissCitationSuggestionV1(workspaceId, documentId, pageIndex, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          copyEditKeys.getScannedCitationsResultForDocumentPage(workspaceId, documentId, pageIndex),
        );
      },
    },
  );
};
