import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { CDN_URL } from '@/main/queries/snippets/media-upload';
import { ResizableMediaNodeView } from './ResizableMediaNodeView';
import { insertFileInEditor } from './resizableMediaMenu.util';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    resizableMedia: {
      /**
       * Set media
       */
      setMedia: (options: {
        'media-type': 'img' | 'video';
        src?: string;
        alt?: string;
        title?: string;
        width?: string;
        height?: string;
        file?: File;
      }) => ReturnType;
    };
  }
}

export interface MediaOptions {
  resourcePath: string;
  inline?: boolean;
  allowBase64Img?: boolean;
  allowVideo?: boolean;
  allowResourceDrop?: boolean;
  allowResourcePaste?: boolean;
  HTMLAttributes?: Record<string, string>;
}

export const ResizableMedia = Node.create<MediaOptions>({
  name: 'resizableMedia',

  draggable: true,

  selectable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      resourcePath: `markov-common/media`,
      inline: false,
      allowBase64Img: false,
      allowVideo: false,
      allowResourceDrop: true,
      allowResourcePaste: true,
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? 'inline' : 'block';
  },

  addAttributes() {
    return {
      file: {
        default: null,
      },
      src: {
        default: null,
      },
      'media-type': {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      dataAlign: {
        default: 'left', // 'start' | 'center' | 'end'
      },
    };
  },

  parseHTML() {
    const imgAttrs = {
      tag: this.options.allowBase64Img ? 'img[src]' : 'img[src]:not([src^="data:"])',
      getAttrs: (el: HTMLElement) => ({
        src: (el as HTMLImageElement).getAttribute('src'),
        'media-type': 'img',
      }),
    };

    if (!this.options.allowVideo) {
      return [imgAttrs];
    }

    return [
      imgAttrs,
      {
        tag: 'video',
        getAttrs: el => ({
          src: (el as HTMLVideoElement).getAttribute('src'),
          'media-type': 'video',
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { 'media-type': mediaType } = HTMLAttributes;

    if (mediaType === 'img') {
      return ['img', mergeAttributes(this.options.HTMLAttributes ?? {}, HTMLAttributes)];
    }
    if (this.options.allowVideo && mediaType === 'video') {
      return [
        'video',
        { controls: 'true', style: 'width: 100%', ...HTMLAttributes },
        ['source', HTMLAttributes],
      ];
    }

    return ['img', mergeAttributes(this.options.HTMLAttributes ?? {}, HTMLAttributes)];
  },

  addCommands() {
    return {
      setMedia:
        options =>
        ({ commands }) => {
          const { 'media-type': mediaType } = options;

          if (mediaType === 'img') {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          }
          if (mediaType === 'video') {
            if (!this.options.allowVideo) {
              return false;
            }

            return commands.insertContent({
              type: this.name,
              attrs: {
                ...options,
                controls: 'true',
              },
            });
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResizableMediaNodeView);
  },

  addProseMirrorPlugins() {
    const { allowResourceDrop, allowResourcePaste, allowBase64Img } = this.options;
    return [
      new Plugin({
        key: new PluginKey('resizableMediaDropPaste'),
        props: {
          handleDrop(view, event, _slice, moved) {
            if (!allowResourceDrop) {
              return false;
            }
            if (!moved && event?.dataTransfer?.files?.[0]) {
              const file = event.dataTransfer.files[0];
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
              const position = coordinates?.pos ?? 0;
              insertFileInEditor(file, view, position);
              return true;
            }
            return false;
          },
          handlePaste(view, event) {
            if (!allowResourcePaste) {
              return false;
            }
            const files = Array.from(event.clipboardData?.files || []);
            for (const file of files) {
              insertFileInEditor(file, view);
            }
            return files.length > 0;
          },
          transformPastedHTML(html) {
            return html.replace(/<img.*?src="(.*?)".*?>/g, function (match, imgSrc) {
              if (allowBase64Img && imgSrc.startsWith('data:')) {
                return match;
              }
              if (imgSrc.indexOf(CDN_URL) === 0) {
                return match; // keep the img
              }

              // Remove images from non-CDN sources for security
              return '';
            });
          },
        },
      }),
    ];
  },
});
