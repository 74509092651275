import { MantineTheme } from '@mantine/core';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useMemo } from 'react';
import { DIFF_TYPE } from '../../../../../../apps/main/components/common/constants';
import { CitationPositionType } from '../../../../../../apps/main/generated/api';
import { Box, CSSObject, Skeleton } from '../../../core';
import { InlineCitationComponent, ReferenceSectionComponent } from './MarkovReferenceItem';
import { tryParseJSONObject } from './util';

export function getCitationsIdsArray(stringifiedCitations: string) {
  if (tryParseJSONObject(stringifiedCitations)) {
    return JSON.parse(stringifiedCitations);
  }
}

const getStyles = (highlightType: number, selected: boolean, theme: MantineTheme): CSSObject => {
  const highlightBorderMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[3],
    [DIFF_TYPE.EQUAL]: theme.colors.gray[3],
    [DIFF_TYPE.DELETE]: theme.colors.red[3],
  };

  const highlightBackgroundMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[0],
    [DIFF_TYPE.EQUAL]: '',
    [DIFF_TYPE.DELETE]: theme.colors.red[0],
  };

  return {
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: '4px',
    display: 'inline-block',
    borderStyle: 'dashed',
    borderColor: highlightBorderMap[highlightType],
    backgroundColor: selected
      ? theme.fn.themeColor('blue.1')
      : highlightBackgroundMap[highlightType],
    ...theme.fn.hover({
      backgroundColor: selected ? theme.fn.themeColor('blue.1') : theme.fn.themeColor('gray.1'),
    }),
  };
};

enum OLD_CITATION_FORMAT {
  IN_TEXT = 'INLINE',
  BIBLIOGRAPHY = 'BIBLIOGRAPHY',
}

export const MarkovReferenceComponentContainer: React.FC<NodeViewProps> = ({
  node,
  extension,
  getPos,
  selected,
}) => {
  /**
   * node.attrs is set when doing addCitationToEditor. Please check implementation there
   * Currently node.attrs = {
   *  documentId: string;
   *  referenceIds: string[]
   * }
   */
  const { editor } = useRichTextEditorContext();
  const citationIds: string[] = useMemo(
    () => getCitationsIdsArray(node.attrs.referenceIds) || [],
    [node.attrs.referenceIds],
  );

  // Backward compatibility for citationFormat
  const citationFormat =
    node.attrs.format === OLD_CITATION_FORMAT.IN_TEXT
      ? CitationPositionType.InText
      : node.attrs.format === OLD_CITATION_FORMAT.BIBLIOGRAPHY
      ? CitationPositionType.Bibliography
      : node.attrs.format;

  const isInlineCitation = citationFormat === CitationPositionType.InText;

  const documentReferences = editor.storage.mkvReference.documentInTextReferences;
  const documentFullReferences = editor.storage.mkvReference.documentFullTextReferences;

  const citations = useMemo(
    () =>
      documentReferences?.filter(({ citationId = '' }) => citationIds.includes(citationId)) ?? [],
    [documentReferences, citationIds],
  );

  const fullTextCitations = useMemo(
    () =>
      documentFullReferences?.filter(({ citationId = '' }) => citationIds.includes(citationId)) ??
      [],
    [documentFullReferences, citationIds],
  );

  const highlightType = node.attrs.highlightType;

  const handleCitationClick = () => {
    const position = getPos();
    editor.commands.setNodeSelection(position);
    extension.options.onClick();
  };

  const handleRemoveCitation = () => {
    const position = getPos();
    editor
      .chain()
      .focus(position)
      .deleteRange({ from: position, to: position + node.nodeSize })
      .run();
  };

  const handleEditCitationClick = (citationId: string) => {
    const position = getPos();
    editor.commands.focus(position);
    extension.options.onEditClick(citationId);
  };

  if (!citations.length || !fullTextCitations.length) {
    return (
      <NodeViewWrapper as="span">
        <Skeleton
          h={10}
          w={isInlineCitation ? 60 : 200}
          my={isInlineCitation ? 0 : 'xs'}
          sx={isInlineCitation ? { display: 'inline-block' } : {}}
        />
      </NodeViewWrapper>
    );
  }

  if (!isInlineCitation && citations && citations.length > 0) {
    return (
      <NodeViewWrapper as="span" draggable>
        <Box
          citation-id={citations[0].citationId}
          onClick={handleCitationClick}
          px="md"
          py="xs"
          sx={theme => getStyles(highlightType, selected, theme)}
        >
          <ReferenceSectionComponent
            referenceObj={fullTextCitations[0]}
            classes={extension.options.classes}
          />
        </Box>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper as="span" draggable>
      <InlineCitationComponent
        citations={citations}
        fullCitations={fullTextCitations}
        highlightType={highlightType}
        editCitation={handleEditCitationClick}
        removeCitation={handleRemoveCitation}
        classes={extension.options.classes}
      />
    </NodeViewWrapper>
  );
};
