import { forwardRef } from 'react';
import { Box, BoxProps } from '@/shared/design-system/v2';
import { MAX_ROW_HEIGHT, MIN_ROW_HEIGHT } from './util';

type GridItemProps = BoxProps;

export const GridItem = forwardRef<HTMLDivElement, GridItemProps>((props, ref) => (
  <Box
    ref={ref}
    {...props}
    data-grid={JSON.stringify({
      minW: 8,
      maxW: 24,
      minH: MIN_ROW_HEIGHT,
      maxH: MAX_ROW_HEIGHT,
      static: false,
      isDraggable: true,
    })}
    className={props.className}
  />
));

GridItem.displayName = 'GridItem';
