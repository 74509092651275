import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { DocumentStatusType } from '@/main/generated/api';
import { useTitleSuggester } from '@/main/queries/ai-utils/use-title-suggester';
import {
  useGetDocumentMetadataQuery,
  useSavePageContentMutationV2,
} from '@/main/queries/document-ai/copyEdit';
import {
  useCheckProjectAndDocumentQuery,
  useCreateGenAIProjectMutation,
  useUploadManualTextMutation,
  workflowDocumentKeys,
} from '@/main/queries/document-ai/workflow';
import { useGetWorkflowAppDetailsFromWorkflowIdQuery } from '@/main/queries/workflows/apps/apps';
import { notifications } from '@/shared/design-system/v2';
import { convertMarkdownToHTML } from '@/shared/design-system/v2/rich-text-editor/utils/content-processor';
import { useAbsoluteRoutes } from '@/writer/router/hooks';

// Status for the save process
export enum SaveStatus {
  IDLE = 'IDLE',
  SEARCHING_PROJECT = 'SEARCHING_PROJECT',
  CREATING_PROJECT = 'CREATING_PROJECT',
  GENERATING_DOCUMENT_NAME = 'GENERATING_DOCUMENT_NAME',
  CREATING_DOCUMENT = 'CREATING_DOCUMENT',
  WAITING_FOR_PARSING = 'WAITING_FOR_PARSING',
  SAVING_CONTENT = 'SAVING_CONTENT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface SaveToCopyCompassProps {
  workflowId: string;
  workflowRunId: string;
  openInNewTab?: boolean;
}

const COPY_COMPASS_HOST = import.meta.env['VITE_APP_COPY_COMPASS_HOST'];

/**
 * Main hook to save markdown content to CopyCompass
 * This combines all the existing mutations
 */
export const useCopyCompassWorkflowContent = ({
  workflowId,
  workflowRunId,
  openInNewTab = true,
}: SaveToCopyCompassProps) => {
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.IDLE);

  // We need to maintain this state since document saving is an async process
  // The content can only be saved after the document is fully parsed
  // Once content is saved successfully, this state will be reset to empty strings
  const [pendingDocumentToSave, setPendingDocumentToSave] = useState({
    documentId: '',
    markdownContent: '',
  });

  const queryClient = useQueryClient();
  const { getCopyEditDetailRoute } = useAbsoluteRoutes();

  const { suggestTitle } = useTitleSuggester();

  const { data: workflowAppDetails, isLoading: isWorkflowAppDetailsLoading } =
    useGetWorkflowAppDetailsFromWorkflowIdQuery(workflowId);
  const { data: projectAndDocumentData, isLoading: isProjectAndDocumentLoading } =
    useCheckProjectAndDocumentQuery(workflowAppDetails?.workflowAppId ?? '', workflowRunId);
  const { data: documentMetadata } = useGetDocumentMetadataQuery(pendingDocumentToSave.documentId);

  const { mutateAsync: createProject } = useCreateGenAIProjectMutation(
    workflowAppDetails?.workflowAppId ?? '',
  );
  const { mutateAsync: createDocument } = useUploadManualTextMutation(workflowRunId);
  const { mutateAsync: saveDocumentContent } = useSavePageContentMutationV2(
    pendingDocumentToSave.documentId,
  );

  // Reset status after some time when we reach a terminal state
  const resetStatusAfterDelay = useCallback(() => {
    setTimeout(() => {
      setSaveStatus(SaveStatus.IDLE);
    }, 3000);
  }, []);

  // Helper to open document in new tab
  const openDocumentInNewTab = useCallback(
    (documentId: string) => {
      const url = getCopyEditDetailRoute(documentId);
      window.open(`//${COPY_COMPASS_HOST}${url}`, '_blank');
    },
    [getCopyEditDetailRoute],
  );

  const saveContent = useCallback(
    async ({ documentId, markdownContent }: { documentId: string; markdownContent: string }) => {
      // Save content to document
      setSaveStatus(SaveStatus.SAVING_CONTENT);
      const htmlContent = await convertMarkdownToHTML(markdownContent);

      await saveDocumentContent(
        {
          pageId: 0,
          pageContent: `<div>${htmlContent}</div>`,
        },
        {
          onError: () => {
            notifications.error('Failed to save content to document');
            setSaveStatus(SaveStatus.ERROR);
            resetStatusAfterDelay();
          },
          onSuccess: () => {
            notifications.success('Content successfully saved to CopyCompass');
            setSaveStatus(SaveStatus.SUCCESS);
            queryClient.invalidateQueries(workflowDocumentKeys.all);
          },
        },
      );

      // Reset the document state
      setPendingDocumentToSave({
        documentId: '',
        markdownContent: '',
      });

      // Open the document in a new tab if requested
      if (openInNewTab) {
        openDocumentInNewTab(documentId);
      }

      resetStatusAfterDelay();
    },
    [saveDocumentContent, openInNewTab, resetStatusAfterDelay, queryClient, openDocumentInNewTab],
  );

  useEffect(() => {
    if (
      pendingDocumentToSave.documentId &&
      pendingDocumentToSave.markdownContent &&
      documentMetadata?.status === DocumentStatusType.Finished
    ) {
      saveContent({
        documentId: pendingDocumentToSave.documentId,
        markdownContent: pendingDocumentToSave.markdownContent,
      });
    }
  }, [documentMetadata, pendingDocumentToSave.documentId, pendingDocumentToSave.markdownContent]);

  const saveToCopyCompass = async ({
    projectName,
    documentName,
    markdownContent,
  }: {
    markdownContent: string;
    projectName?: string;
    documentName?: string;
  }) => {
    if (
      projectAndDocumentData?.documentId ||
      saveStatus !== SaveStatus.IDLE ||
      !workflowAppDetails
    ) {
      return;
    }

    const { workflowAppName, workflowAppDescription, workflowAppId } = workflowAppDetails;

    setSaveStatus(SaveStatus.SEARCHING_PROJECT);

    let projectId = projectAndDocumentData?.projectId;

    // Create project if not found
    if (!projectId) {
      setSaveStatus(SaveStatus.CREATING_PROJECT);
      const result = await createProject(
        {
          projectName: projectName ?? workflowAppName,
          projectDescription: workflowAppDescription,
          workflowAppId,
        },
        {
          onError: () => {
            notifications.error('Failed to create project');
            setSaveStatus(SaveStatus.ERROR);
            resetStatusAfterDelay();
          },
        },
      );

      projectId = result.data.projectId;
    }

    if (!projectId) {
      return false;
    }

    // Create document
    if (!documentName) {
      setSaveStatus(SaveStatus.GENERATING_DOCUMENT_NAME);
      documentName = await suggestTitle(markdownContent);
    }

    setSaveStatus(SaveStatus.CREATING_DOCUMENT);
    const createdDocument = await createDocument(
      {
        documentName,
        text: '',
        projectId,
        workflowRunId,
      },
      {
        onError: () => {
          notifications.error('Failed to create document in CopyCompass');
          setSaveStatus(SaveStatus.ERROR);
          resetStatusAfterDelay();
        },
      },
    );

    if (!createdDocument.data.documentId) {
      return false;
    }

    // Set the document state
    setSaveStatus(SaveStatus.WAITING_FOR_PARSING);
    setPendingDocumentToSave({
      documentId: createdDocument.data.documentId,
      markdownContent,
    });
  };

  return {
    isLoading: isProjectAndDocumentLoading || isWorkflowAppDetailsLoading,
    saveToCopyCompass,
    saveStatus,
    openDocumentInNewTab: () => openDocumentInNewTab(projectAndDocumentData.documentId),
    isDocumentExists: !!projectAndDocumentData?.documentId,
  };
};
