import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import first from 'lodash/first';
import get from 'lodash/get';
import { AudioPreview } from '@/main/components/common/previews/AudioPreview';
import { useFilePreview } from '@/main/components/common/previews/use-file-preview';
import { useJsonPreview } from '@/main/components/common/previews/use-json-preview';
import { OPERATOR_VIEW_AS_FULL_TEXT_ID } from '@/main/components/workflows/apps/util';
import { Box, Button, Text } from '@/shared/design-system/v2';
import { Table, TableThemes } from '@/shared/design-system/v2/core/data-display/table';
import { useFolderFilePreview } from '../../../../../../data-sources/modals/use-folder-file-preview';
import { AUDIO_URL_REGEX } from '../../../../utils';
import { DefaultCell } from './DefaultCell';
import { LongTextPreview } from './LongTextPreview';
import { HeaderWithCheckbox } from './NodeSchemaPreview';
import { PreviewCell } from './PreviewCell';
import { extractMkvFolderFilePreviewData } from './util';

interface DataPreviewProps {
  parsedData: string[][];
  schema: {
    name: string;
    type: string;
  }[];
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  modifiedColumns?: string[];
  shadingColor?: string;
  operatorId: string;
  workflowId: string;
  placeholderText?: string;
  workflowRunId?: string;
}

export const DataPreview = ({
  parsedData,
  schema,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  modifiedColumns,
  shadingColor,
  operatorId,
  workflowId,
  placeholderText,
  workflowRunId,
}: DataPreviewProps) => {
  const openFilePreview = useFilePreview();
  const openJsonPreview = useJsonPreview();
  const openFolderFilePreview = useFolderFilePreview();

  const validSelectedColumns =
    selectedColumns?.filter(col => first(parsedData)?.includes(col)) ?? [];

  const toggleColumnSelection = (colId: string) => {
    if (setSelectedColumns && selectedColumns) {
      const index = validSelectedColumns.indexOf(colId);
      if (index > -1) {
        setSelectedColumns(validSelectedColumns.filter((id: string) => id !== colId));
      } else {
        setSelectedColumns([...validSelectedColumns, colId]);
      }
    }
  };

  const columns: ColDef[] =
    first(parsedData)?.map(datum => {
      const type = schema.find(schemaItem => schemaItem.name === datum)?.type;

      if (type === 'S3_FILE' || type === 'MKV_FILE') {
        return {
          colId: datum,
          field: datum,
          headerName: datum,
          headerComponent: allowColumnSelection
            ? () => (
                <HeaderWithCheckbox
                  column={{ name: datum, type: datum }}
                  toggleColumnSelection={toggleColumnSelection}
                  isSelected={(selectedColumns ?? []).includes(datum)}
                />
              )
            : () => (
                <Text variant="subTitle04" color="gray.9">
                  {datum}
                </Text>
              ),
          filter: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellRenderer: ({ value, data, colDef }: ICellRendererParams) => {
            const cellValue = (value ?? data[colDef?.colId ?? ''] ?? '') as string;
            const isAudioFileUrl = AUDIO_URL_REGEX.test(cellValue);

            const handlePreview = () => {
              openFilePreview(workflowId, value);
            };

            const handleMkvFolderFilePreview = () => {
              const fileName = get(data, 'Name', '');
              const { resourceId, fileType, filePath } = extractMkvFolderFilePreviewData(data);
              openFolderFilePreview(resourceId, fileName, fileType, filePath);
            };

            if (isAudioFileUrl) {
              return (
                <Box h="100%">
                  <AudioPreview srcUrl={cellValue} workflowId={workflowId} />
                </Box>
              );
            }

            return (
              <Button onClick={type === 'S3_FILE' ? handlePreview : handleMkvFolderFilePreview}>
                Preview
              </Button>
            );
          },
        };
      }

      if (type === 'JSON') {
        return {
          colId: datum,
          field: datum,
          headerName: datum,
          headerComponent: allowColumnSelection
            ? () => (
                <HeaderWithCheckbox
                  column={{ name: datum, type: datum }}
                  toggleColumnSelection={toggleColumnSelection}
                  isSelected={(selectedColumns ?? []).includes(datum)}
                />
              )
            : () => (
                <Box>
                  <Text variant="subTitle04" color="gray.9">
                    {datum}
                  </Text>
                  <Text variant="small03" color="gray.6">
                    Json
                  </Text>
                </Box>
              ),
          filter: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellRenderer: ({ value }: ICellRendererParams) => {
            const handlePreview = () => {
              openJsonPreview(value);
            };
            return (
              <Box>
                <Button onClick={handlePreview}>Preview</Button>
              </Box>
            );
          },
        };
      }

      return {
        colId: datum,
        field: datum,
        headerName: datum,
        cellStyle: modifiedColumns?.includes(datum)
          ? { backgroundColor: shadingColor ?? 'none' }
          : { backgroundColor: 'none' },
        headerComponent: allowColumnSelection
          ? () => (
              <HeaderWithCheckbox
                column={{ name: datum, type: datum }}
                toggleColumnSelection={toggleColumnSelection}
                isSelected={(selectedColumns ?? []).includes(datum)}
              />
            )
          : () => (
              <Text variant="subTitle04" color="gray.9">
                {datum}
              </Text>
            ),
        filter: false,
        sortable: false,
        minWidth: 100,
        flex: 1,
        // This should not be needed, but for some reason, values are coming undefined
        // FIXIT:: remove cell renderer
        cellRenderer: ({ value, data, colDef }: ICellRendererParams) => {
          // TODO: Check why empty string is used to index data
          const cellValue = (value ?? data[colDef?.colId ?? ''] ?? '') as string;

          return <DefaultCell value={cellValue} />;
        },
      };
    }) ?? [];

  if (columns.length > 0) {
    columns.push({
      colId: 'preview',
      headerName: '',
      width: 50,
      pinned: 'right' as const,
      cellRenderer: ({ data }: ICellRendererParams) => (
        <PreviewCell data={data} schema={schema} isRunsPreview placeholderText={placeholderText} />
      ),
    });
  }

  const rowData = parsedData?.slice(1).map(parsedDataRow =>
    parsedDataRow.reduce((prev, curr, idx) => {
      const colId = columns[idx] ? columns[idx].colId : null;
      if (!colId) {
        return prev;
      }

      return {
        ...prev,
        [colId]: curr,
      };
    }, {}),
  );

  if (operatorId === OPERATOR_VIEW_AS_FULL_TEXT_ID) {
    return (
      <LongTextPreview
        rowData={rowData}
        schema={schema}
        placeholderText={placeholderText}
        isRunsPreview
        workflowId={workflowId}
        workflowRunId={workflowRunId}
      />
    );
  }

  return <Table rowHeight={44} theme={TableThemes.THEME_V2} rowData={rowData} columns={columns} />;
};
