import { IconRefresh, IconTransform } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { IconOrangeWarning } from '@/shared/design-system';
import {
  ActionIcon,
  Alert,
  Box,
  Center,
  Flex,
  Loader,
  Tabs,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import {
  DataProperties,
  IOSchema,
  OperatorCategory,
  WorkflowRunOperatorStatus,
  WorkflowRunStatus,
} from '../../../../../../../generated/api';
import { useDebugRunStatusQuery } from '../../../../../../../queries/workflows/debug';
import {
  useGetWorkflowDagNodesSchemaV2Query,
  useRefetchDataPreview,
} from '../../../../../../../queries/workflows/operators';
import { DagNodeSchemaError } from './DagNodeSchemaError';
import { DataPreviewContainer } from './DataPreview.container';
import { WorkflowDagNodeSchema } from './WorkflowDagNodeSchema';
import { APP_BUILDER_PLACEHOLDER_TEXT } from './util';

interface WorkflowDagNodeSchemaContainerProps {
  workflowId: string;
  nodeId: string;
  operatorId: string;
  runId?: string;
  appId?: string;
  operatorCategory?: OperatorCategory;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  isFormDirty?: boolean;
  isFormSaving?: boolean;
  isAppBuilder?: boolean;
}

const renderSchemaContent = (
  schemas: IOSchema[] | IOSchema[][],
  renderContent: (schema: any, index?: number) => JSX.Element,
  type: 'Input' | 'Output',
  operatorCategory?: OperatorCategory,
  hideSection?: boolean,
) => {
  if (hideSection) {
    return <></>;
  }

  if (schemas.length > 1) {
    return (
      <Box h="100%">
        <Tabs defaultValue={`${type}1`} h="100%">
          <Tabs.List mb="sm">
            {schemas.map((_, index) => (
              <Tabs.Tab value={`${type}${index + 1}`} key={`${type}${index + 1}`}>
                <Text variant="overline" tt="uppercase" color="gray.7">
                  {`${type} Preview ${index + 1}`}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {schemas.map((schema, index) => (
            <Tabs.Panel
              value={`${type}${index + 1}`}
              key={`${type}Panel${index + 1}`}
              h="calc(100% - 46px)"
            >
              {renderContent(schema, index)}
            </Tabs.Panel>
          ))}
        </Tabs>
      </Box>
    );
  }

  return (
    <Vertical h="100%">
      <Flex w="100%" justify="space-between">
        <Text variant="overline" tt="uppercase" color="gray.7">
          {`${operatorCategory !== OperatorCategory.Process ? 'Data' : type} Preview`}
        </Text>
      </Flex>
      {schemas.map((schema, idx) => (
        <Box key={idx} h="100%">
          {renderContent(schema)}
        </Box>
      ))}
    </Vertical>
  );
};

export const WorkflowDagNodeSchemaContainer = ({
  workflowId,
  nodeId,
  runId,
  appId,
  operatorId,
  operatorCategory,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  isFormDirty,
  isFormSaving,
  isAppBuilder,
}: WorkflowDagNodeSchemaContainerProps) => {
  const theme = useMarkovTheme();

  const { isLoading, isFetching, isError, data } = useGetWorkflowDagNodesSchemaV2Query(
    workflowId,
    nodeId,
    runId || undefined,
    appId || undefined,
  );
  const refetchData = useRefetchDataPreview(
    workflowId,
    runId ?? '',
    nodeId,
    operatorCategory ?? OperatorCategory.Process,
  );
  const {
    isLoading: runStatusLoading,
    isError: runStatusError,
    data: runStatus,
  } = useDebugRunStatusQuery(workflowId, runId);

  if (isFormDirty && operatorCategory === OperatorCategory.Source) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Alert icon={<IconTransform size={16} color={theme.colors.gray[9]} />}>
          <Text variant="bodyLong02" color="gray.9">
            You&apos;ve made changes. Please apply them to see the preview.
          </Text>
        </Alert>
      </Vertical>
    );
  }

  if (isFormSaving || isLoading || isFetching) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Loader variant="dots" size="xl" />
        <Text variant="subTitle03">Loading data for the preview</Text>
      </Vertical>
    );
  }

  const ioSchema = data?.nodeSchemas;
  const operatorStatus = runStatus?.operatorsStatus?.find(opStatus => opStatus.nodeId === nodeId);
  const nodeStatus = operatorStatus?.statusDetails.status;

  if (!ioSchema?.[nodeId] || isError) {
    return <DagNodeSchemaError workflowId={workflowId} />;
  }

  const nodeSchema = ioSchema[nodeId];
  const inputNodeIds = Object.keys(nodeSchema?.inputs ?? {});
  const inputSchemas = Object.values(nodeSchema?.inputs ?? {});
  const outputSchemas = (nodeSchema?.outputs ?? []).filter(
    (output: IOSchema) => output.type !== 'MODEL',
  );
  const showDataPreview =
    (data.dagHash === runStatus?.dagHash &&
      nodeStatus === WorkflowRunOperatorStatus.Success &&
      !!runId) ||
    runStatus?.wflRunStatus === WorkflowRunStatus.Success;

  const renderInputContent = (inputSchema: IOSchema[], nodeIndex = 0) => (
    <Vertical h="100%">
      {showDataPreview ? (
        <DataPreviewContainer
          operatorCategory={operatorCategory}
          operatorId={operatorId}
          workflowId={workflowId}
          runId={runId ?? ''}
          nodeId={inputNodeIds[nodeIndex] ?? ''}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          allowColumnSelection={allowColumnSelection}
          schema={(inputSchema[0]?.properties as DataProperties)?.columnSchema ?? []}
          modifiedColumns={(inputSchema[0]?.properties as DataProperties)?.schemaChanges?.removed}
          shadingColor={theme.colors.red[0]}
          placeholderText={
            !isEmpty(appId) || isAppBuilder ? APP_BUILDER_PLACEHOLDER_TEXT : undefined
          }
        />
      ) : (
        <WorkflowDagNodeSchema
          workflowId={workflowId}
          operatorId={operatorId}
          schema={(inputSchema[0]?.properties as DataProperties)?.columnSchema ?? []}
          data={(inputSchema[0]?.properties as DataProperties)?.sampleData ?? []}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          allowColumnSelection={allowColumnSelection}
          modifiedColumns={(inputSchema[0]?.properties as DataProperties)?.schemaChanges?.removed}
          shadingColor={theme.colors.red[0]}
          type="Input"
          placeholderText={
            !isEmpty(appId) || isAppBuilder ? APP_BUILDER_PLACEHOLDER_TEXT : undefined
          }
        />
      )}
    </Vertical>
  );

  const renderOutputContent = (outputSchema: IOSchema) => (
    <Vertical h="100%">
      {showDataPreview ? (
        <DataPreviewContainer
          operatorCategory={operatorCategory}
          operatorId={operatorId}
          workflowId={workflowId}
          runId={runId ?? ''}
          nodeId={nodeId}
          selectedColumns={[]}
          setSelectedColumns={noop}
          allowColumnSelection={false}
          schema={(outputSchema.properties as DataProperties)?.columnSchema ?? []}
          modifiedColumns={
            operatorCategory === OperatorCategory.Source
              ? []
              : (outputSchema.properties as DataProperties)?.schemaChanges?.added
          }
          shadingColor={theme.colors.green[0]}
          placeholderText={!isEmpty(appId) ? APP_BUILDER_PLACEHOLDER_TEXT : undefined}
        />
      ) : (
        <WorkflowDagNodeSchema
          workflowId={workflowId}
          operatorId={operatorId}
          schema={(outputSchema.properties as DataProperties)?.columnSchema ?? []}
          data={(outputSchema.properties as DataProperties)?.sampleData ?? []}
          selectedColumns={[]}
          setSelectedColumns={noop}
          allowColumnSelection={false}
          modifiedColumns={
            operatorCategory === OperatorCategory.Source
              ? []
              : (outputSchema.properties as DataProperties)?.schemaChanges?.added
          }
          shadingColor={theme.colors.green[0]}
          type="Output"
          placeholderText={!isEmpty(appId) ? APP_BUILDER_PLACEHOLDER_TEXT : undefined}
        />
      )}
    </Vertical>
  );

  if (inputSchemas.length === 0 && operatorCategory !== OperatorCategory.Source) {
    return (
      <Center w="100%" h="100%">
        <Vertical align="center" spacing={0}>
          <IconOrangeWarning width={32} height={32} />
          <Text variant="subTitle02" color="gray.7">
            Input data missing
          </Text>
          <Text variant="small01" color="gray.6" align="center">
            This operation hasn&apos;t received valid input yet. Please connect it to a node that
            provides the right input!
          </Text>
        </Vertical>
      </Center>
    );
  }

  const handleRefresh = () => {
    refetchData();
  };

  return (
    <Vertical h="100%">
      <Flex justify="right">
        <ActionIcon onClick={handleRefresh}>
          <IconRefresh />
        </ActionIcon>
      </Flex>
      {renderSchemaContent(
        inputSchemas,
        renderInputContent,
        'Input',
        operatorCategory,
        operatorCategory === OperatorCategory.Source,
      )}
      {renderSchemaContent(
        outputSchemas,
        renderOutputContent,
        'Output',
        operatorCategory,
        operatorCategory === OperatorCategory.Sink,
      )}
    </Vertical>
  );
};
