import { AxiosResponse } from 'axios';
import last from 'lodash/last';
import { AnalysisStateNames, SegmentType } from '../generated/api';
import { API_PAGE_SIZE } from './constants';

export interface MarkovGenericResponse<T> {
  response: T;
  workspaceId?: string;
}

export interface AnalysesResponse<T> extends Partial<MarkovGenericResponse<T>> {
  analysisState: AnalysisStateNames;
}

export const VALID_ANALYSIS_LOAD_STATES = [
  AnalysisStateNames.Completed,
  AnalysisStateNames.ResultsAvailable,
];

export type AnalysesResponseSelector<T> =
  | (T & {
      isResultAvailable: true;
      analysisState: AnalysisStateNames;
    })
  | (Partial<T> & {
      isResultAvailable: false;
      analysisState: AnalysisStateNames;
    });

export const responseSelector = <T>(data: AxiosResponse<MarkovGenericResponse<T>>) =>
  data.data.response;

export const analysesResponseSelector = <T>(
  data: AxiosResponse<AnalysesResponse<T>>,
): AnalysesResponseSelector<T> => {
  const analysisState = data.data.analysisState;
  const isResultAvailable =
    Boolean(VALID_ANALYSIS_LOAD_STATES.includes(analysisState)) && Boolean(data.data.response);

  if (isResultAvailable) {
    return {
      ...(data.data.response as T),
      isResultAvailable,
      analysisState: data.data.analysisState,
    };
  }
  return {
    ...(data.data.response as Partial<T>),
    isResultAvailable,
    analysisState: data.data.analysisState,
  };
};

interface MarkovResource {
  createDate: string;
}

interface PaginatedResponse {
  response: MarkovResource[];
  numRecords: number;
}

type InfiniteQueryPage = AxiosResponse<PaginatedResponse>;

export const getNextPageParamHandler =
  (pageSize = API_PAGE_SIZE) =>
  (lastPage: InfiniteQueryPage | undefined, allPages: InfiniteQueryPage[]) => {
    if (!lastPage) {
      return undefined;
    }
    const { response, numRecords } = lastPage.data;

    return numRecords > pageSize
      ? {
          pageNumber: allPages.length,
          lastTimestamp: last(response)?.createDate,
        }
      : undefined;
  };

// Creates mapping object for segment types
export const segmentOrder: { [key in SegmentType]: number } = {
  [SegmentType.Train]: 0,
  [SegmentType.Test]: 1,
  [SegmentType.Validate]: 2,
  [SegmentType.All]: 0,
  [SegmentType.Unknown]: 0,
};
