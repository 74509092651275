import { IconCircleCheckFilled } from '@tabler/icons-react';
import {
  Box,
  Center,
  Image,
  SimpleGrid,
  Text,
  UnstyledButton,
  closeAllModals,
  openModal,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ChartTypes } from '../../../../../../charts';

const CHART_OPTION_IMAGE_SIZE = 80;

export const CHART_OPTIONS = [
  {
    value: ChartTypes.COLUMN,
    name: 'Column chart',
    imgSrc:
      'https://ik.imagekit.io/markovml/data-analytics/charts/column-chart_A37TQxr8E.svg?updatedAt=1721852051199',
  },
  {
    value: ChartTypes.BAR,
    name: 'Bar chart',
    imgSrc:
      'https://ik.imagekit.io/markovml/data-analytics/charts/bar-chart_m69FFos7z.svg?updatedAt=1721852050935',
  },
  {
    value: ChartTypes.PACKED_BUBBLE,
    name: 'Bubble chart',
    imgSrc:
      'https://ik.imagekit.io/markovml/data-analytics/charts/bubble-chart_TQ-3pmbwyo.svg?updatedAt=1721852050911',
  },
  // TODO: Add Donut chart once we support it
  // {
  //   value: ChartTypes.DONUT,
  //   name: 'Donut chart',
  //   imgSrc:
  //     'https://ik.imagekit.io/markovml/data-analytics/charts/donut-chart_UXQ3OR9LTl.svg?updatedAt=1721852051090',
  // },
  {
    value: ChartTypes.PIE,
    name: 'Pie chart',
    imgSrc:
      'https://ik.imagekit.io/markovml/data-analytics/charts/pie-chart_ZriilVsQa.svg?updatedAt=1721852050821',
  },
];

interface ChartSelectionModalProps {
  activeValue: ChartTypes;
  onSelect: (chartType: ChartTypes) => void;
  allowedCharts?: ChartTypes[];
}

const ChartSelectionModal = ({
  activeValue,
  onSelect,
  allowedCharts,
}: ChartSelectionModalProps) => {
  const theme = useMarkovTheme();

  return (
    <SimpleGrid cols={3}>
      {CHART_OPTIONS.map(({ value, name, imgSrc }) => {
        const handleSelect: React.MouseEventHandler<HTMLButtonElement> = e => {
          onSelect(value);
          closeAllModals();
        };
        const isActive = activeValue === value;
        const isAllowed = allowedCharts ? allowedCharts.includes(value) : true;

        if (!isAllowed) {
          return null;
        }

        return (
          <UnstyledButton
            key={value}
            p="sm"
            pos="relative"
            onClick={handleSelect}
            sx={theme => ({
              border: `1px solid ${isActive ? theme.colors.green[6] : theme.colors.gray[3]}`,
              borderRadius: theme.radius.sm,
              '&:hover': {
                backgroundColor: theme.colors.blue[0],
              },
            })}
          >
            {isActive && (
              <Box pos="absolute" top={10} right={10}>
                <IconCircleCheckFilled color={theme.colors.green[6]} />
              </Box>
            )}
            <Center p="lg" pb="md">
              <Image
                height={CHART_OPTION_IMAGE_SIZE}
                width={CHART_OPTION_IMAGE_SIZE}
                src={imgSrc}
              />
            </Center>
            <Text variant="subTitle03" color="gray.7" ta="center">
              {name}
            </Text>
          </UnstyledButton>
        );
      })}
    </SimpleGrid>
  );
};

export const useChartSelectionModal = ({
  activeValue,
  onSelect,
  allowedCharts,
}: ChartSelectionModalProps) => {
  const open = () => {
    openModal({
      title: <Text variant="subTitle01">Select chart type</Text>,
      size: 'lg',
      children: (
        <ChartSelectionModal
          activeValue={activeValue}
          onSelect={onSelect}
          allowedCharts={allowedCharts}
        />
      ),
    });
  };

  return { open };
};
