import { IconExternalLink } from '@tabler/icons-react';
import { Button, Skeleton } from '@/shared/design-system/v2';
import { SaveStatus, useCopyCompassWorkflowContent } from './useCopyCompassWorkflowContent';

interface CopyCompassButtonProps {
  content: string;
  workflowId: string;
  workflowRunId: string;
  openInNewTab?: boolean;
}

// Helper to determine status text
const getStatusText = (status: SaveStatus): string => {
  switch (status) {
    case SaveStatus.SEARCHING_PROJECT:
      return 'Searching project...';
    case SaveStatus.CREATING_PROJECT:
      return 'Creating project...';
    case SaveStatus.GENERATING_DOCUMENT_NAME:
      return 'Generating document name...';
    case SaveStatus.CREATING_DOCUMENT:
      return 'Creating document...';
    case SaveStatus.WAITING_FOR_PARSING:
      return 'Waiting for document to be parsed...';
    case SaveStatus.SAVING_CONTENT:
      return 'Saving content...';
    case SaveStatus.SUCCESS:
      return 'Successfully saved to CopyCompass';
    case SaveStatus.ERROR:
      return 'Failed to save. Please try again.';
    default:
      return 'Save to CopyCompass';
  }
};

const getStatusColor = (status: SaveStatus): string => {
  switch (status) {
    case SaveStatus.ERROR:
      return 'red';
    default:
      return 'green';
  }
};

export const CopyCompassButton = ({
  content,
  workflowId,
  workflowRunId,
  openInNewTab = true,
}: CopyCompassButtonProps): JSX.Element => {
  const {
    saveToCopyCompass,
    saveStatus,
    isLoading: initialChecking,
    isDocumentExists,
    openDocumentInNewTab,
  } = useCopyCompassWorkflowContent({
    openInNewTab,
    workflowId,
    workflowRunId,
  });

  const handleClick = () => {
    if (initialChecking) {
      return;
    }

    if (isDocumentExists) {
      openDocumentInNewTab();
    } else {
      saveToCopyCompass({ markdownContent: content });
    }
  };

  if (initialChecking) {
    return <Skeleton width={180} height={36} radius="sm" />;
  }

  const buttonText = isDocumentExists ? 'Open in CopyCompass' : getStatusText(saveStatus);
  const buttonColor = isDocumentExists ? 'blue' : getStatusColor(saveStatus);
  const buttonLoading = ![SaveStatus.IDLE, SaveStatus.SUCCESS].includes(saveStatus);

  return (
    <Button
      variant="light"
      loading={buttonLoading}
      color={buttonColor}
      rightIcon={!buttonLoading && <IconExternalLink size={16} />}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};
